


































import { Component, Vue, Watch } from 'vue-property-decorator';
import snackModule from '@/store/Snack';

@Component({
  components: {},
})
export default class MySnackBar extends Vue {
  showErrorSnackbar = false;

  get snackbarText() {
    return snackModule.snackText;
  }

  get snackbarErrors() {
    return snackModule.errors;
  }

  get snackbarType() {
    return snackModule.snackType;
  }

  get snackbarIcon() {
    switch (snackModule.snackType) {
      case 'error':
        return 'mdi-alert-circle';
      case 'warning':
        return 'mdi-alert-circle-outline';
      case 'success':
        return 'mdi-check-circle';
      default:
        return '';
    }
  }

  @Watch('snackbarErrors')
  snackMessageChanged() {
    this.showErrorSnackbar = true;
  }
}
