







































import { Prop, Component, Vue } from 'vue-property-decorator';
import { SurveyProperty, SurveySite } from '@/api';

import propertyModule from '@/store/Property';
import snackModule from '@/store/Snack';

import Stepper from '@/components/common/Stepper.vue';
import StepperItem from '@/components/common/StepperItem.vue';
import SiteStep1 from '@/components/site/SiteStep1.vue';
import SiteStep2 from '@/components/site/SiteStep2.vue';

import { NavigationGuardNext, Route } from 'vue-router';
import confirmDialog from '@/confirm-dialog';

@Component({
  components: {
    Stepper,
    StepperItem,
    SiteStep1,
    SiteStep2,
  },
})
export default class SiteStepper extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  surveySite: SurveySite | null = null;

  get siteId() {
    return this.$route.params.siteId;
  }

  get createMode() {
    return this.$route.name === 'property-sites-create';
  }

  get step() {
    return parseInt((this.$route.query.step as string) || '1', 10);
  }

  set step(s: number) {
    this.$router
      .replace({
        query: { step: s.toString() },
      })
      .catch(() => {
        // alll g
      });
  }

  async navGuard(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      from.name === 'property-sites-create' &&
      this.$refs.stepComponent &&
      (this.$refs.stepComponent as SiteStep1).unsavedChanges
    ) {
      const confirm = await confirmDialog({
        title: 'Unsaved changes will be lost',
        description:
          'You have unsaved changes. Are you sure you would like to continue?',
        buttons: [
          {
            key: 'confirm',
            title: 'Discard Changes',
            color: 'grey',
            text: true,
          },
          {
            key: 'cancel',
            title: 'Continue Editing',
            color: 'primary',
            outlined: false,
          },
        ],
      });
      if (confirm !== 'confirm') {
        return false;
      }
    }
    next();
    return true;
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    return this.navGuard(to, from, next);
  }

  async onCreate(site: SurveySite) {
    await propertyModule.getProperty(this.property.id as string);
    this.surveySite = site;
    this.$router.replace({
      name: 'property-sites-edit',
      params: { siteId: this.surveySite.id as string },
      query: { step: '2' },
    });
  }

  async mounted() {
    if (this.siteId) {
      try {
        this.surveySite = (await SurveySite.find(this.siteId)).data;
        this.step = 2;
      } catch (e) {
        snackModule.setError({
          text: 'Could not find item',
          errors: (e as ErrorResponse).response.errors,
        });
      }
    }
  }
}
