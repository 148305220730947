

















































import v8n from 'v8n';
import { Component, Vue } from 'vue-property-decorator';
import { PasswordReset } from '@/api';

import SidebarInfo from '@/components/common/SidebarInfo.vue';

@Component({ components: { SidebarInfo } })
export default class PasswordResetForm extends Vue {
  email = '';

  loading = false;

  valid = false;

  complete = false;

  error = '';

  emailRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.emailRequired'),

    (v: string) =>
      v8n()
        .pattern(/\S+@\S+\.\S+/)
        .test(v) || this.$t('users.validation.emailValid'),
  ];

  get title() {
    return this.$route.name === 'forgot-password'
      ? 'Forgotten your password?'
      : 'Welcome to WildTracker';
  }

  async submit() {
    this.loading = true;
    try {
      const result = await PasswordReset.resetPassword(this.email);
      if (!result) {
        this.error = this.$t('passwordReset.alerts.sentError') as string;
      } else {
        this.error = '';
        this.complete = true;
      }
    } catch (e) {
      this.error = e.response.errors[0].detail;
    } finally {
      this.loading = false;
    }
  }

  back() {
    this.$router.back();
  }
}
