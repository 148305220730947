/* eslint-disable no-underscore-dangle */
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';

import { FocalLandscape, FaunaTag, FaunaTagGroup, Project } from '@/api';

import store from '@/store';
import Vue from 'vue';

@Module({ dynamic: true, namespaced: true, name: 'cache', store })
class CacheModule extends VuexModule {
  ready = false;

  _faunaTags: FaunaTag[] = [];

  _mappedFaunaTags: { [key: string]: FaunaTag } = {};

  _faunaTagGroups: FaunaTagGroup[] = [];

  _focalLandscapes: FocalLandscape[] = [];

  _faunaTagsReady = false;

  _faunaTagGroupsReady = false;

  _focalLandscapeReady = false;

  get project() {
    return new Project({ id: 1 });
  }

  get tagsReady() {
    return this._faunaTagsReady && this._faunaTagGroupsReady;
  }

  get faunaTags() {
    return this._faunaTags;
  }

  get faunaTagGroups() {
    return this._faunaTagGroups;
  }

  get faunaTagsById() {
    return (id: string) => this._mappedFaunaTags[id];
  }

  get faunaTagsByGroupId() {
    return (groupId: string) =>
      this._faunaTags.filter(tag => tag.faunaTagGroup.id === groupId);
  }

  get faunaTagFilterItems() {
    return this.faunaTags.map(faunaTag => ({
      label: faunaTag.name,
      value: faunaTag.id,
    }));
  }

  get faunaTagGroupFilterItems() {
    return this.faunaTagGroups.map(group => ({
      label: group.name,
      value: group.id,
    }));
  }

  get focalLandscapes() {
    return this._focalLandscapes;
  }

  get focalLandscapeFilterItems() {
    return this.focalLandscapes.map(fl => ({
      label: fl.name,
      value: fl.id,
    }));
  }

  // mutations

  @Mutation
  setFaunaTags(faunaTags: FaunaTag[]) {
    this._faunaTags = faunaTags;
    faunaTags.forEach(tag => {
      Vue.set(this._mappedFaunaTags, tag.id as string, tag);
    });
    this._faunaTagsReady = true;
  }

  @Mutation
  setFaunaTagGroups(faunaTagGroups: FaunaTagGroup[]) {
    this._faunaTagGroups = faunaTagGroups;
    this._faunaTagGroupsReady = true;
  }

  @Mutation
  setFocalLandscapes(focalLandscapes: FocalLandscape[]) {
    this._focalLandscapes = focalLandscapes;
    this._focalLandscapeReady = true;
  }

  // actions

  @Action({ rawError: true })
  init() {
    this.getFocalLandscapes();
    this.getFaunaTagsAndGroups();
  }

  @Action({ rawError: true })
  async getFaunaTagsAndGroups() {
    if (this.tagsReady) return;
    const faunaTags = (await FaunaTag.all()).data;
    const faunaTagGroup = (await FaunaTagGroup.all()).data;
    this.setFaunaTags(faunaTags);
    this.setFaunaTagGroups(faunaTagGroup);
  }

  @Action({ rawError: true })
  async getFocalLandscapes() {
    if (this._focalLandscapeReady) return;
    const focalLandscapes = (await FocalLandscape.scopeFactory().all()).data;
    this.setFocalLandscapes(focalLandscapes);
  }
}

export default getModule(CacheModule, store);
