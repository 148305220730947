

































































import { Component, Vue, Prop } from 'vue-property-decorator';
import LineIndicator from '@/components/common/LineIndicator.vue';
import FaunaTagPreview from '@/components/common/FaunaTagPreview.vue';

import faunaTagDialog from '@/fauna-tag-dialog';

import { FaunaSurvey, SurveyProperty, SurveySite } from '@/api';
import cacheModule from '@/store/Cache';
import propertyModule from '@/store/Property';

import { numberFormat } from '@/util';

@Component({ components: { FaunaTagPreview, LineIndicator } })
export default class FaunaStatsLines extends Vue {
  @Prop({ default: null }) readonly property: SurveyProperty;

  @Prop({ default: null }) readonly site: SurveySite;

  @Prop({ default: null }) readonly survey: FaunaSurvey;

  fullList = false;

  get numberFormat() {
    return numberFormat;
  }

  get faunaStats() {
    if (this.survey) {
      return propertyModule.surveyStats(this.survey.id as string, 'fauna_tag');
    }
    if (this.site) {
      return propertyModule.siteStats(this.site.id as string, 'fauna_tag');
    }
    return propertyModule.propertyStats(
      this.property.id as string,
      'fauna_tag',
    );
  }

  get tags() {
    if (!this.faunaStats) {
      return [];
    }
    return this.faunaStats
      .filter(fs => fs.faunaTag && fs.count)
      .map(fs => {
        const id = (fs.faunaTag as number).toString();
        const tag = cacheModule.faunaTagsById(id);
        return tag
          ? {
              name: tag.name,
              picture: tag.avatarUrl,
              value: fs.count,
              tag,
            }
          : {
              name: 'Unknown',
              picture: '',
              value: fs.count,
            };
      });
  }

  get shownTags() {
    return this.fullList ? this.tags : this.tags.slice(0, 3);
  }

  get max() {
    return this.tags.reduce((sum, item) => sum + item.value, 0);
  }

  showDialog(faunaTagId?: string) {
    faunaTagDialog(faunaTagId);
  }

  async mounted() {
    if (this.survey) {
      propertyModule.getSurveyTagStats(this.survey.id as string);
    } else if (this.site) {
      propertyModule.getSiteTagStats(this.site.id as string);
    } else {
      propertyModule.getPropertyTagStats(this.property.id as string);
    }
  }
}
