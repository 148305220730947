import { Model, Attr, BelongsTo, HasOne } from 'spraypaint';
import { Point } from '@turf/turf';
import {
  SurveyProperty,
  HabitatType,
  VegetationStructureType,
  RecruitmentType,
  ZoneType,
} from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class SurveySite extends ApplicationRecord {
  static jsonapiType = 'survey-sites';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Property',
        value: 'surveyProperty.name',
      },
      {
        text: 'Vegetation Structure Type',
        value: 'vegetationStructureType.name',
      },
      {
        text: 'Recruitment Type',
        value: 'recruitmentType.name',
      },
      {
        text: 'Habitat Type',
        value: 'habitatType.name',
      },
      {
        text: 'Zone Type',
        value: 'zoneType.name',
      },
    ];
  }

  static scopeFactory() {
    return SurveySite.includes([
      'surveyProperty',
      'vegetationStructureType',
      'recruitmentType',
      'habitatType',
      'zoneType',
    ]).select([
      'id',
      'name',
      'code',
      'surveyProperty',
      'vegetationStructureType',
      'recruitmentType',
      'habitatType',
      'zoneType',
    ]);
  }

  @Attr() name: string;

  @Attr() description: string;

  @Attr() location: Point;

  @Attr() createdAt: string;

  @Attr() faunaSurveyCount: number;

  @Attr() code: string;

  @BelongsTo() surveyProperty: SurveyProperty;

  @HasOne() vegetationStructureType: VegetationStructureType;

  @HasOne() recruitmentType: RecruitmentType;

  @HasOne() habitatType: HabitatType;

  @HasOne() zoneType: ZoneType | null;
}
