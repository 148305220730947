

































import { Component, Vue, Watch } from 'vue-property-decorator';
import { FaunaTag } from '@/api';
import PageHeader from '@/components/common/PageHeader.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import FilterItem from '@/components/common/FilterItem.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';
import cacheModule from '@/store/Cache';

import { isEqual } from 'lodash';

@Component({
  components: { PageHeader, AbstractTable, TableMenuButton, FilterItem },
})
export default class AdminFaunaTagsList extends Vue {
  get headers() {
    return FaunaTag.listHeaders();
  }

  get scopeFactory() {
    return () => FaunaTag.scopeFactory().where(this.whereClause);
  }

  get filterItems() {
    return [
      {
        label: 'Fauna Tag Group',
        relationship: 'faunaTagGroup',
        paramName: 'fauna-tag-group',
        items: cacheModule.faunaTagGroupFilterItems,
      },
    ];
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems.forEach(filterItem => {
      clause[filterItem.relationship] =
        this.$route.query[filterItem.paramName] || undefined;
    });
    return clause;
  }

  click(item: FaunaTag) {
    this.$router.push({
      name: 'admin-fauna-tags-edit',
      params: { itemId: item.id as string },
    });
  }

  mounted() {
    cacheModule.getFaunaTagsAndGroups();
  }

  @Watch('whereClause')
  whereClauseChanged(current: object, prev: object) {
    if (!isEqual(current, prev)) {
      (this.$refs.table as AbstractTable).update();
    }
  }
}
