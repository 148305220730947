import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class HabitatType extends ApplicationRecord {
  static jsonapiType = 'habitat-types';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return HabitatType.includes([]);
  }

  @Attr() createdAt: string;

  @Attr() name: string;

  @Attr() description: string;

  @Attr() code: string;
}
