




import mapboxgl from 'mapbox-gl';

import { Component, Prop, Vue } from 'vue-property-decorator';
import { SurveyProperty, SurveySite } from '@/api';

import { bbox, center, feature } from '@turf/turf';
import { mapboxToken } from '@/config';

@Component({ components: {} })
export default class SiteMapPreview extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly site: SurveySite;

  map: mapboxgl.Map | null = null;

  markers: mapboxgl.Marker[] = [];

  createMapElement() {
    const propertyCenter = center(this.property.geometry);
    const propertyBbox = bbox(this.property.geometry);
    this.map = new mapboxgl.Map({
      accessToken: mapboxToken,
      container: this.$refs.mapEl as HTMLElement,
      style: 'mapbox://styles/mapbox/light-v10',
      center: propertyCenter.geometry.coordinates as mapboxgl.LngLatLike,
      zoom: 15,
    });
    this.map.fitBounds(propertyBbox as mapboxgl.LngLatBoundsLike, {
      padding: 20,
    });
  }

  outlineMap() {
    if (!this.property || !this.map) return;
    (this.map as mapboxgl.Map).on('load', () => {
      // Add a data source containing GeoJSON data.
      (this.map as mapboxgl.Map).addSource('property', {
        type: 'geojson',
        data: feature(this.property.geometry),
      });

      // Add a new layer to visualize the polygon.
      (this.map as mapboxgl.Map).addLayer({
        id: 'fill',
        type: 'fill',
        source: 'property',
        layout: {},
        paint: {
          'fill-color': '#000000',
          'fill-opacity': 0.1,
        },
      });

      // Add a black outline around the polygon.
      (this.map as mapboxgl.Map).addLayer({
        id: 'outline',
        type: 'line',
        source: 'property',
        layout: {},
        paint: {
          'line-color': '#000',
          'line-dasharray': [2, 2],
          'line-width': 1,
        },
      });
    });
  }

  setSites() {
    if (!this.property || !this.map) return;
    const { map } = this;
    this.property.surveySites.forEach(site => {
      // Marker
      const markerEl = document.createElement('div');
      markerEl.className = 'marker';
      const marker = new mapboxgl.Marker(markerEl)
        .setLngLat(site.location.coordinates as mapboxgl.LngLatLike)
        .addTo(map);
      this.markers.push(marker);

      if (site.id === this.site.id) {
        this.setActiveMarker(markerEl);
      }
    });
  }

  setActiveMarker(markerEl?: HTMLElement) {
    this.markers.forEach(marker => {
      const el = marker.getElement();
      if (el === markerEl) {
        // if (this.map) {
        //   this.map.panTo(marker.getLngLat());
        // }
        el.classList.add('marker--selected');
      } else {
        el.classList.remove('marker--selected');
      }
    });
  }

  mounted() {
    this.createMapElement();
    this.outlineMap();
    this.setSites();
  }

  beforeDestroy() {
    if (this.map) this.map.remove();
  }
}
