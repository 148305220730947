

























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { FaunaMedia, UserFavourite } from '@/api';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

@Component({ components: {} })
export default class ShareDialog extends Vue {
  @Prop({ required: true })
  readonly faunaMedia: FaunaMedia;

  @Prop({ required: true })
  readonly userFavourite: UserFavourite | null;

  value = true;

  newFavourite: UserFavourite | null = null;

  get isPublic() {
    return authModule.user && authModule.user.isPublic;
  }

  get favourite() {
    return this.newFavourite || this.userFavourite;
  }

  get shareUrl() {
    return this.favourite && this.favourite.isShared
      ? new URL(`/share/${this.favourite.id}`, window.location.href).href
      : '';
  }

  async toggleSharing() {
    if (!this.favourite) {
      return;
    }

    try {
      await this.favourite.save();
      snackModule.setSuccess(
        this.favourite.isShared ? 'Sharing enabled' : 'Sharing disabled',
      );
    } catch (e) {
      snackModule.setError({
        text: 'Could not set sharing',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  async copyUrl() {
    await navigator.clipboard.writeText(this.shareUrl);
    snackModule.setSuccess('Link copied to clipboard');
  }

  async createUserFavourite() {
    if (this.favourite) {
      return;
    }
    try {
      const userFav = new UserFavourite({
        user: authModule.user,
        faunaMedia: this.faunaMedia,
      });
      await userFav.save({ with: ['user.id', 'faunaMedia.id'] });
      await authModule.getUserFavourites();
      this.newFavourite = userFav;
      snackModule.setSuccess('Favourite added');
    } catch (e) {
      snackModule.setError({
        text: 'Could not create favourite',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  get show() {
    return this.value;
  }

  set show(v) {
    this.$emit('close');
    this.value = v;
  }
}
