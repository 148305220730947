import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class Program extends ApplicationRecord {
  static jsonapiType = 'programs';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Users',
        value: 'users.length',
      },
    ];
  }

  static scopeFactory() {
    return Program.includes([]);
  }

  @Attr name: string;
}
