













































import { Component, Vue } from 'vue-property-decorator';
import LineIndicator from '@/components/common/LineIndicator.vue';
import { PublicUser } from '@/api';

import snackModule from '@/store/Snack';

import { numberFormat } from '@/util';

@Component({ components: { LineIndicator } })
export default class UserStatsLines extends Vue {
  fullList = false;

  limit = 10;

  publicUsers: PublicUser[] = [];

  get title() {
    return 'Top taggers';
  }

  get numberFormat() {
    return numberFormat;
  }

  get shownUsers() {
    return this.fullList
      ? this.publicUsers
      : this.publicUsers.slice(0, this.limit);
  }

  get max() {
    return this.publicUsers.length ? this.publicUsers[0].faunaMediaTagCount : 0;
  }

  async mounted() {
    try {
      this.publicUsers = (
        await PublicUser.order({ faunaMediaTagCount: 'desc' }).all()
      ).data;
    } catch (e) {
      snackModule.setError({
        text: 'Failed to fetch public users',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }
}
