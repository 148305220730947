import { Editor } from 'tiptap';
import {
  History,
  HardBreak,
  Heading,
  ListItem,
  BulletList,
  OrderedList,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
} from 'tiptap-extensions';

export default function(content = '') {
  return new Editor({
    extensions: [
      new History(),
      new HardBreak(),
      new Heading({ levels: [2, 3, 4] }),
      new ListItem(),
      new BulletList(),
      new OrderedList(),
      new Bold(),
      new Italic(),
      new Strike(),
      new Underline(),
      new Link(),
    ],
    content,
  });
}
