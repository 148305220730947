




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ApplicationRecord } from '@/api';
import { Scope } from 'spraypaint';

import LazyImg from '@/components/common/LazyImg.vue';
import ProgressDialog from '@/components/common/ProgressDialog.vue';

import snackModule from '@/store/Snack';
import confirmDialog from '@/confirm-dialog';

@Component({
  components: {
    LazyImg,
    ProgressDialog,
  },
})
export default class extends Vue {
  @Prop({ required: true }) readonly scopeFactory: () => Scope<
    ApplicationRecord
  >;

  page = 1;

  total = 0;

  itemsPerPage = 48;

  loading = false;

  items: ApplicationRecord[] = [];

  selected: ApplicationRecord[] = [];

  deleteProgress = 100;

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  async getItems() {
    try {
      this.loading = true;

      const response = await this.scopeFactory()
        .per(this.itemsPerPage)
        .page(this.page)
        .all();
      this.selected = [];
      this.items = response.data;
      this.total = response.meta.pagination.count;
      this.$emit('updateCount', this.total);
    } finally {
      this.loading = false;
    }
  }

  async deleteSelected() {
    const confirm = await confirmDialog();
    if (confirm !== 'confirm') {
      return;
    }

    const max = this.selected.length;
    let count = 0;

    const promises = this.selected.map(async item => {
      try {
        await item.destroy();
        return Promise.resolve();
      } catch (e) {
        snackModule.setError({
          text: 'Error deleting file',
          errors: (e as ErrorResponse).response.errors,
        });
        return Promise.resolve();
      } finally {
        count += 1;
        this.deleteProgress = (100 * count) / max;
      }
    });

    await Promise.all(promises);
    this.getItems();
  }

  mounted() {
    this.getItems();
  }

  @Watch('page')
  pageChanged() {
    this.getItems();
  }
}
