
























import { Component, Vue, Prop } from 'vue-property-decorator';

import LazyImg from '@/components/common/LazyImg.vue';

import cacheModule from '@/store/Cache';

@Component({ components: { LazyImg } })
export default class FaunaTagDialog extends Vue {
  @Prop({ default: null }) readonly faunaTagId: string | null;

  value = true;

  index = 0;

  get show() {
    return this.value;
  }

  set show(v) {
    this.$emit('close');
    this.value = v;
  }

  get item() {
    return cacheModule.faunaTags[this.index];
  }

  get src() {
    return this.item.coverPhotoUrl;
  }

  get title() {
    return this.item.name;
  }

  get description() {
    return this.item.description;
  }

  prev() {
    this.index =
      this.index === 0 ? cacheModule.faunaTags.length - 1 : this.index - 1;
  }

  next() {
    this.index = (this.index + 1) % cacheModule.faunaTags.length;
  }

  mounted() {
    if (this.faunaTagId) {
      const index = cacheModule.faunaTags.findIndex(
        ft => ft.id === this.faunaTagId,
      );
      if (index !== -1) {
        this.index = index;
      }
    }
  }
}
