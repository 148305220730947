import { Model, Attr, BelongsTo } from 'spraypaint';
import { FocalLandscape } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class FocalLandscapeMetadataSurvey extends ApplicationRecord {
  static jsonapiType = 'focal-landscape-metadata-surveys';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return FocalLandscapeMetadataSurvey.includes([]);
  }

  @Attr() createdAt: string;

  @Attr() extent: number;

  @BelongsTo() focalLandscape: FocalLandscape;
}
