var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"text--caps mt-8 mb-6"},[_vm._v("Property Boundary")]),_c('property-map',{attrs:{"property":_vm.property}}),_c('div',{staticClass:"sidebar-layout"},[_c('div',{staticClass:"sidebar-layout__content"},[_c('h2',{staticClass:"text--caps mt-10 mb-6"},[_vm._v("Property Summary")]),(!_vm.property.surveySites.length)?_c('get-started-site'):_vm._e(),_c('div',{staticClass:"property-overview-stats"},[(_vm.property.surveySites.length)?_c('stat-tile',{attrs:{"title":"Sites","color":"brown","value":_vm.property.surveySites.length,"to":{
            name: 'property-sites',
            params: { propertyId: _vm.property.id },
          }}}):_vm._e(),(_vm.property.surveySites.length)?_c('stat-tile',{attrs:{"title":"Additional Users","color":"purple","value":_vm.property.propertyUsers.length,"to":{
            name: 'property-users',
            params: { propertyId: _vm.property.id },
          }}}):_vm._e(),(_vm.surveyCount)?_c('stat-tile',{attrs:{"title":"Surveys","color":"blue","value":_vm.surveyCount,"to":{
            name: 'property-surveys',
            params: { propertyId: _vm.property.id },
          }}}):_vm._e(),(_vm.surveyCount)?_c('stat-tile',{attrs:{"title":"Favourites","color":"red","value":_vm.favouriteCount,"to":{
            name: 'property-favourites',
            params: { propertyId: _vm.property.id },
          }}}):_vm._e(),(_vm.surveyCount)?_c('stat-tile',{staticClass:"tags-tile",attrs:{"title":"Tags","color":"teal","value":_vm.nativeCount,"max":_vm.totalFaunaCount,"showMax":true,"to":{
            name: 'property-tags',
            params: { propertyId: _vm.property.id },
          }}},[_vm._v(" "+_vm._s(_vm.nativeText)+" ")]):_vm._e(),(_vm.property.surveySites.length && !_vm.surveyCount)?_c('get-started-survey'):_vm._e()],1),_c('user-stats-lines',{staticClass:"mt-12",attrs:{"property":_vm.property}}),_c('fauna-stats-lines',{staticClass:"mt-12",attrs:{"property":_vm.property}}),_c('site-stats-lines',{staticClass:"mt-12",attrs:{"property":_vm.property}})],1),_c('div',{staticClass:"sidebar-layout__sidebar"},[_c('h2',{staticClass:"text--caps mt-10 mb-6"},[_vm._v("Tasks")]),_c('sidebar-tasks',{attrs:{"property":_vm.property}}),_c('sidebar-tiles')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }