














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class HeroBanner extends Vue {
  @Prop({ default: null }) readonly img: string;

  get style() {
    return this.img ? `background-image:url(${this.img});` : '';
  }
}
