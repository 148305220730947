







































































































import { Component, Vue } from 'vue-property-decorator';
import HeroBanner from '@/components/common/HeroBanner.vue';
import SidebarInfo from '@/components/common/SidebarInfo.vue';
import LeaderboardStatsLines from '@/components/property/LeaderboardStatsLines.vue';
import FaunaLeaderboardStatsLines from '@/components/property/FaunaLeaderboardStatsLines.vue';

@Component({
  components: {
    HeroBanner,
    SidebarInfo,
    LeaderboardStatsLines,
    FaunaLeaderboardStatsLines,
  },
})
export default class Home extends Vue {}
