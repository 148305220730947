












import { Component, Vue, Prop } from 'vue-property-decorator';

import LazyImg from '@/components/common/LazyImg.vue';

import { FaunaTag } from '@/api';

@Component({
  components: { LazyImg },
})
export default class FaunaTagPreview extends Vue {
  @Prop({ required: true }) readonly tag: FaunaTag;
}
