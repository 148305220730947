













import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import PropertyEditor from '@/components/admin/users/PropertyEditor.vue';

import { SurveyProperty, User } from '@/api';

@Component({ components: { PropertyEditor } })
export default class UserPropertiesSingle extends Vue {
  @Prop({ required: true }) readonly user: User;

  property: SurveyProperty | null = null;

  notFound = false;

  get propertyId() {
    return this.$route.params.propertyId;
  }

  get isNewItem() {
    return this.propertyId === undefined;
  }

  @Watch('propertyId', { immediate: true })
  async itemIdChanged() {
    if (this.isNewItem) {
      this.property = new SurveyProperty({ owner: this.user.dup() });
    } else {
      try {
        this.property = (
          await SurveyProperty.where({ owner: this.user.id }).find(
            this.propertyId,
          )
        ).data;
      } catch (e) {
        this.notFound = true;
      }
    }
  }
}
