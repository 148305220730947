




































































































import { Prop, Component, Vue } from 'vue-property-decorator';
import {
  FaunaSurvey,
  FaunaSurveyStatus,
  SurveyProperty,
  FaunaMedia,
} from '@/api';

import SiteThumb from '@/components/common/SiteThumb.vue';
import SiteMapPreview from '@/components/property/SiteMapPreview.vue';
import FaunaMediaThumb from '@/components/common/FaunaMediaThumb.vue';

import snackModule from '@/store/Snack';

@Component({
  components: {
    SiteThumb,
    SiteMapPreview,
    FaunaMediaThumb,
  },
})
export default class SurveyStep3 extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly survey: FaunaSurvey;

  first: FaunaMedia | null = null;

  last: FaunaMedia | null = null;

  outOfRange = 0;

  get activeSite() {
    return (
      this.property.surveySites.find(
        site => this.survey.surveySite && this.survey.surveySite.id === site.id,
      ) || null
    );
  }

  get published() {
    return this.survey.status !== FaunaSurveyStatus.draft;
  }

  get disablePublish() {
    return this.published || !this.activeSite || !this.first || !this.last;
  }

  back() {
    this.$emit('back');
  }

  async getFirst() {
    this.first = (
      await FaunaMedia.includes([
        'media',
        'faunaSurvey',
        'faunaMediaTags',
        'userFavourites',
      ])
        .where({ faunaSurvey: this.survey.id })
        .order({ timestamp: 'asc', createdAt: 'desc' })
        .first()
    ).data;
  }

  async getLast() {
    this.last = (
      await FaunaMedia.includes([
        'media',
        'faunaSurvey',
        'faunaMediaTags',
        'userFavourites',
      ])
        .where({ faunaSurvey: this.survey.id })
        .order({ timestamp: 'desc', createdAt: 'asc' })
        .first()
    ).data;
  }

  async getOutOfRange() {
    const dataset = await FaunaMedia.where({
      faunaSurvey: this.survey.id,
      out_of_range: true,
    }).first();
    this.outOfRange = dataset.meta.pagination.count;
  }

  async next() {
    try {
      this.survey.status = FaunaSurveyStatus.published;
      await this.survey.save();
      snackModule.setSuccess('Survey published');
      this.$router.push({ name: 'property-surveys' });
    } catch (e) {
      snackModule.setError({
        text: this.$t('faunaTagGroups.alerts.notSaved') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  mounted() {
    this.getFirst();
    this.getLast();
    this.getOutOfRange();
  }
}
