


















import { Component, Vue } from 'vue-property-decorator';
import HeroBanner from '@/components/common/HeroBanner.vue';
import SidebarInfo from '@/components/common/SidebarInfo.vue';
import TermsOfUse from '@/components/common/TermsOfUse.vue';

@Component({
  components: {
    HeroBanner,
    SidebarInfo,
    TermsOfUse,
  },
})
export default class TermsPage extends Vue {}
