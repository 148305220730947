




import { Component, Vue, Prop } from 'vue-property-decorator';
import AbstractTable from '@/components/common/AbstractTable.vue';

import MapboxDraw from '@mapbox/mapbox-gl-draw';

import mapboxgl from 'mapbox-gl';
import { mapboxToken } from '@/config';
import {
  truncate,
  polygon,
  bbox,
  Polygon,
  multiPolygon,
  FeatureCollection,
  MultiPolygon,
  Geometry,
  GeometryCollection,
} from '@turf/turf';

import ShapeImportControl from '@/kml-import/kml-import';
import FeatureLocator from '@/kml-import/feature-locator';

@Component({ components: { AbstractTable } })
export default class UserPropertiesSingle extends Vue {
  @Prop({ required: true }) readonly geometry: MultiPolygon;

  map: mapboxgl.Map | null = null;

  draw: MapboxDraw | null = null;

  featureLocator: FeatureLocator | null = null;

  setupDraw() {
    if (!this.map) {
      return;
    }
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    });
    this.featureLocator = new FeatureLocator(this.draw);
    this.map.addControl(this.draw);
    this.map.addControl(new ShapeImportControl());
    this.map.addControl(this.featureLocator);
    this.map.on('kml', this.onKmlUpload);
  }

  addFeatures() {
    if (!this.geometry) {
      return;
    }
    if (!this.draw) {
      return;
    }
    const { draw } = this;
    this.geometry.coordinates.forEach(polyCoords => {
      if (polyCoords.length) {
        const feature = polygon(polyCoords);
        draw.add(feature);
      }
    });
    const collection = this.draw.getAll();
    if (collection.features.length) {
      const bounds = bbox(this.draw.getAll());
      if (this.map) {
        this.map.fitBounds(bounds as mapboxgl.LngLatBoundsLike, {
          padding: 20,
          animate: false,
        });
      }
    }
  }

  // get new multi poly from the map
  getMultiPoly() {
    if (!this.draw) {
      return null;
    }
    const { draw } = this;
    const collection = draw.getAll();
    const coords = collection.features
      .filter(feature => feature.geometry.type === 'Polygon')
      .map(feature => {
        return (feature.geometry as Polygon).coordinates;
      });
    if (coords.length === 0) {
      return null;
    }
    return truncate(multiPolygon(coords), { coordinates: 2 });
  }

  addGeometry(geometry: Geometry | GeometryCollection) {
    if (!this.draw) {
      return;
    }
    const { draw } = this;

    if ((geometry as Geometry).type === 'Polygon') {
      draw.add(geometry as Polygon);
    } else if (geometry.type === 'MultiPolygon') {
      (geometry as MultiPolygon).coordinates.forEach(polyCoords => {
        const poly = polygon(polyCoords);
        draw.add(poly);
      });
    } else if (geometry.type === 'GeometryCollection') {
      (geometry as GeometryCollection).geometries.forEach(g =>
        this.addGeometry(g),
      );
    } else {
      console.log('Could not process', geometry);
    }
  }

  onKmlUpload({ featureCollection }: { featureCollection: FeatureCollection }) {
    featureCollection.features.forEach(feature => {
      this.addGeometry(feature.geometry);
    });
    if (this.featureLocator) {
      this.featureLocator.fitBounds();
    }
  }

  createMapElement() {
    this.map = new mapboxgl.Map({
      accessToken: mapboxToken,
      container: this.$refs.mapEl as HTMLElement,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [146.6, -41.7],
      zoom: 6,
    });
  }

  async mounted() {
    this.createMapElement();
    this.setupDraw();
    this.addFeatures();
  }

  beforeDestroy() {
    if (this.map) this.map.remove();
  }
}
