import { Model, Attr, HasOne, BelongsTo } from 'spraypaint';
import { FloraSurvey, User } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

export enum ConditionReference {
  absent = '0',
  // eslint-disable-next-line @typescript-eslint/camelcase
  very_low = '1',
  low = '2',
  medium = '3',
  high = '4',
  // eslint-disable-next-line @typescript-eslint/camelcase
  very_high = '5',
}

export const SurveyDataMapping = [
  { key: 'canopyCover', name: 'Canopy Cover', isBoolean: false },
  { key: 'midstoryCover', name: 'Midstory Cover', isBoolean: false },
  { key: 'lichenCover', name: 'Moss/Lichen Cover', isBoolean: false },
  { key: 'shrubCover', name: 'Shrub Cover', isBoolean: false },
  { key: 'grassCover', name: 'Grass Cover', isBoolean: false },
  { key: 'herbCover', name: 'Herb Cover', isBoolean: false },
  { key: 'fernCover', name: 'Fern Cover', isBoolean: false },
  { key: 'weedCover', name: 'Weed Cover', isBoolean: false },
  { key: 'oldGrowthPresent', name: 'Old Growth Present', isBoolean: true },
  { key: 'matureTreePresent', name: 'Mature Plants Present', isBoolean: true },
  { key: 'saplingPresent', name: 'Saplings Present', isBoolean: true },
  { key: 'seedlingPresent', name: 'Seedlings Present', isBoolean: true },
  { key: 'fallenLogPresent', name: 'Fallen Logs Present', isBoolean: true },
];

export const SurveyEcondMapping = [
  { key: 'vegetationStructure', name: 'Vegetation Structure' },
  { key: 'recruitment', name: 'Recruitment' },
  { key: 'weedCover', name: 'Weed Cover' },
  { key: 'canopyCover', name: 'Canopy Cover' },
  { key: 'midstoryCover', name: 'Midstory Cover' },
  { key: 'lichenCover', name: 'Moss/Lichen Cover' },
  { key: 'shrubCover', name: 'Shrub Cover' },
  { key: 'grassCover', name: 'Grass Cover' },
  { key: 'herbCover', name: 'Herb Cover' },
  { key: 'fernCover', name: 'Fern Cover' },
];

@Model()
export default class FloraSurveyData extends ApplicationRecord {
  static jsonapiType = 'flora-survey-data';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return FloraSurveyData.includes([]);
  }

  @Attr() createdAt: string;

  @Attr() canopyCover: ConditionReference;

  @Attr() midstoryCover: ConditionReference;

  @Attr() lichenCover: ConditionReference;

  @Attr() shrubCover: ConditionReference;

  @Attr() grassCover: ConditionReference;

  @Attr() herbCover: ConditionReference;

  @Attr() fernCover: ConditionReference;

  @Attr() weedCover: ConditionReference;

  @Attr() oldGrowthPresent: boolean;

  @Attr() matureTreePresent: boolean;

  @Attr() saplingPresent: boolean;

  @Attr() seedlingPresent: boolean;

  @Attr() fallenLogPresent: boolean;

  @BelongsTo() floraSurvey: FloraSurvey;

  @HasOne() createdBy: User;
}
