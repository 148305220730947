


































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import NotFound from '@/components/common/NotFound.vue';
import AvatarUpload from '@/components/common/AvatarUpload.vue';
import snackModule from '@/store/Snack';
import { UserRole, User, Program } from '@/api';
import v8n from 'v8n';

@Component({ components: { PageHeader, NotFound, AvatarUpload } })
export default class UserBasicInfo extends Vue {
  /* Attributes */
  title: string;

  item: User | null = null;

  notFound = false;

  valid = false;

  showPassword = false;

  loading = false;

  userRoles = UserRole;

  programs: Program[] = [];

  /* Form validations */
  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  emailRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.emailRequired'),

    (v: string) =>
      v8n()
        .pattern(/\S+@\S+\.\S+/)
        .test(v) || this.$t('users.validation.emailValid'),
  ];

  passwordRules = [
    (v: string) =>
      v8n()
        .optional(
          v8n()
            .string()
            .minLength(8),
          true,
        )
        .test(v) || this.$t('users.validation.passwordLength'),
    (v: string) =>
      v8n()
        .optional(
          v8n()
            .not.numeric()
            .minLength(8),
          true,
        )
        .test(v) || this.$t('users.validation.passwordNonNumeric'),
  ];

  /* Getter & setter */
  get itemId() {
    return this.$route.params.itemId;
  }

  get isNewItem() {
    return this.itemId === undefined;
  }

  /* Functions */
  async save() {
    if (!this.item) return;
    try {
      this.loading = true;

      await this.item.save({ with: ['programs.id'] });
      // need to do this for the hack to work properly ?!
      this.item = this.item.dup();

      this.$router
        .replace({
          name: 'admin-users-edit',
          params: { itemId: this.item.id as string },
        })
        .catch(() => {
          // already there
        });
      snackModule.setSuccess(this.$t('users.alerts.updated') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('users.alerts.notUpdated') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    this.programs = (await Program.all()).data;
  }

  @Watch('itemId', { immediate: true })
  async itemIdChanged() {
    if (this.isNewItem) {
      this.title = 'New User';
      this.item = new User({ isPublic: false, roles: [] });
    } else {
      this.title = 'Edit User';
      try {
        this.item = (await User.find(this.itemId)).data || null;
      } catch (e) {
        this.notFound = true;
      }
    }
  }
}
