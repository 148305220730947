



















































































































import { Component, Vue } from 'vue-property-decorator';

import authModule from '@/store/Auth';
import snackModule from '@/store/Snack';

import PageHeader from '@/components/common/PageHeader.vue';
import AvatarUpload from '@/components/common/AvatarUpload.vue';

import SidebarInfo from '@/components/common/SidebarInfo.vue';
import SidebarTiles from '@/components/common/SidebarTiles.vue';

import LeaderboardStatsLines from '@/components/property/LeaderboardStatsLines.vue';
import FaunaLeaderboardStatsLines from '@/components/property/FaunaLeaderboardStatsLines.vue';

import PropertyList from '@/components/property/PropertyList.vue';
import confirmDialog from '@/confirm-dialog';

import Cookies from 'js-cookie';

@Component({
  components: {
    PageHeader,
    AvatarUpload,
    LeaderboardStatsLines,
    FaunaLeaderboardStatsLines,
    SidebarInfo,
    SidebarTiles,
    PropertyList,
  },
})
export default class Dashboard extends Vue {
  get user() {
    return authModule.user;
  }

  get roles() {
    return this.user
      ? this.user.roles.map(role => role.substr(0, role.length - 1)).join(' / ')
      : '';
  }

  async changePrivacy() {
    authModule.setShowWelcomeDialog(true);
  }

  starterContentCookie = Cookies.get('hide_starter_content');

  showStarterContent = true;

  async mounted() {
    if (this.starterContentCookie) {
      this.showStarterContent = false;
    }
  }

  setContentCookie() {
    Cookies.set('hide_starter_content', 'true', { expires: 365 });
    this.showStarterContent = false;
  }
}
