import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { bbox } from '@turf/turf';

const leftDataUri =
  'data:image/svg+xml;base64,PCEtLSBSZXBsYWNlIHRoZSBjb250ZW50cyBvZiB0aGlzIGVkaXRvciB3aXRoIHlvdXIgU1ZHIGNvZGUgLS0+Cgo8c3ZnIHJvbGU9ImltZyIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTUgMTEuNUMxMy42MiAxMS41IDEyLjUgMTAuMzggMTIuNSA5UzEzLjYyIDYuNSAxNSA2LjUgMTcuNSA3LjYyIDE3LjUgOSAxNi4zOCAxMS41IDE1IDExLjVNOCA5QzggMTQuMjUgMTUgMjIgMTUgMjJTMjIgMTQuMjUgMjIgOUMyMiA1LjEzIDE4Ljg3IDIgMTUgMlM4IDUuMTMgOCA5TTYgN0wxIDEyTDYgMTdWN1oiIC8+Cjwvc3ZnPg==';

const rightDataUri =
  'data:image/svg+xml;base64,PCEtLSBSZXBsYWNlIHRoZSBjb250ZW50cyBvZiB0aGlzIGVkaXRvciB3aXRoIHlvdXIgU1ZHIGNvZGUgLS0+Cgo8c3ZnIHJvbGU9ImltZyIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik05IDExLjVDNy42MiAxMS41IDYuNSAxMC4zOCA2LjUgOVM3LjYyIDYuNSA5IDYuNSAxMS41IDcuNjIgMTEuNSA5IDEwLjM4IDExLjUgOSAxMS41TTkgMkM1LjEzIDIgMiA1LjEzIDIgOUMyIDE0LjI1IDkgMjIgOSAyMlMxNiAxNC4yNSAxNiA5QzE2IDUuMTMgMTIuODcgMiA5IDJNMTggMTdMMjMgMTJMMTggN1YxN1oiIC8+Cjwvc3ZnPg==';

const zoomDataUri =
  'data:image/svg+xml;base64,PCEtLSBSZXBsYWNlIHRoZSBjb250ZW50cyBvZiB0aGlzIGVkaXRvciB3aXRoIHlvdXIgU1ZHIGNvZGUgLS0+Cgo8c3ZnIHJvbGU9ImltZyIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTIsMjBDNy41OSwyMCA0LDE2LjQxIDQsMTJDNCw3LjU5IDcuNTksNCAxMiw0QzE2LjQxLDQgMjAsNy41OSAyMCwxMkMyMCwxNi40MSAxNi40MSwyMCAxMiwyME0xMiwyQTEwLDEwIDAgMCwwIDIsMTJBMTAsMTAgMCAwLDAgMTIsMjJIMjBBMiwyIDAgMCwwIDIyLDIwVjEyQTEwLDEwIDAgMCwwIDEyLDJNMTMsN0gxMVYxMUg3VjEzSDExVjE3SDEzVjEzSDE3VjExSDEzVjdaIiAvPgo8L3N2Zz4=';

export default class FeatureLocator implements mapboxgl.IControl {
  container: HTMLElement;

  map: mapboxgl.Map;

  draw: MapboxDraw;

  i = 0;

  constructor(draw: MapboxDraw) {
    this.draw = draw;
  }

  onAdd(map: mapboxgl.Map) {
    this.map = map;

    const zoom = document.createElement('button');
    zoom.classList.add('mapboxgl-ctrl-shape-import__label');
    zoom.style.backgroundImage = `url('${zoomDataUri}')`;
    zoom.title = 'Fit Bounds';
    zoom.addEventListener('click', e => {
      e.preventDefault();
      this.fitBounds();
    });

    const left = document.createElement('button');
    left.classList.add('mapboxgl-ctrl-shape-import__label');
    left.style.backgroundImage = `url('${leftDataUri}')`;
    left.title = 'Previous Feature';
    left.addEventListener('click', e => {
      e.preventDefault();
      this.prevFeature();
    });

    const right = document.createElement('button');
    right.classList.add('mapboxgl-ctrl-shape-import__label');
    right.style.backgroundImage = `url('${rightDataUri}')`;
    right.title = 'Next Feature';
    right.addEventListener('click', e => {
      e.preventDefault();
      this.nextFeature();
    });

    this.container = document.createElement('div');
    this.container.classList.add('mapboxgl-ctrl-group', 'mapboxgl-ctrl');
    this.container.appendChild(zoom);
    this.container.appendChild(right);
    this.container.appendChild(left);
    return this.container;
  }

  onRemove() {
    const parent = this.container.parentNode;
    if (parent) {
      parent.removeChild(this.container);
    }
  }

  fitBounds() {
    const collection = this.draw.getAll();
    const bounds = bbox(collection);
    this.map.fitBounds(bounds as LngLatBoundsLike, { padding: 20, speed: 2 });
  }

  prevFeature() {
    const collection = this.draw.getAll();
    this.i = this.i === 0 ? collection.features.length - 1 : this.i - 1;
    const bounds = bbox(collection.features[this.i]);
    this.map.fitBounds(bounds as LngLatBoundsLike, { padding: 20, speed: 2 });
  }

  nextFeature() {
    const collection = this.draw.getAll();
    this.i = (this.i + 1) % collection.features.length;
    const bounds = bbox(collection.features[this.i]);
    this.map.fitBounds(bounds as LngLatBoundsLike, { padding: 20, speed: 2 });
  }
}
