













































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import EmptyItem from '@/components/common/EmptyItem.vue';
import v8n from 'v8n';
import { SurveySite, SurveyProperty } from '@/api/';
import snackModule from '@/store/Snack';

import MapboxDraw, {
  DrawCreateEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';

import mapboxgl from 'mapbox-gl';
import {
  bbox,
  feature,
  featureCollection,
  MultiPolygon,
  Point,
  point,
} from '@turf/turf';
import { mapboxToken } from '@/config';

@Component({ components: { PageHeader, EmptyItem } })
export default class AdminPropertiesSingle extends Vue {
  /* Attributes */
  title: string;

  item: SurveySite | null = null;

  surveyProperties: SurveyProperty[] = [];

  notFound = false;

  loading = false;

  valid = false;

  map: mapboxgl.Map | null = null;

  draw: MapboxDraw | null = null;

  markers: mapboxgl.Marker[] = [];

  selectedSite: SurveySite | null = null;

  point: Point | null = null;

  lat = '';

  lng = '';

  mapLoaded = false;

  /* Form validations */
  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  optionalString = [
    (v: string) =>
      v8n()
        .optional(v8n().string(), true)
        .test(v),
  ];

  /* Getter & setter */
  get itemId() {
    return this.$route.params.itemId;
  }

  get isNewItem() {
    return this.itemId === undefined;
  }

  get currentProperty() {
    if (!this.item || !this.item.surveyProperty) {
      return null;
    }
    const propertyId = this.item.surveyProperty.id;
    return this.surveyProperties.find(p => p.id === propertyId) || null;
  }

  async getSurveyProperties() {
    try {
      this.surveyProperties =
        (
          await SurveyProperty.select(['id', 'name', 'geometry'])
            .per(1000)
            .all()
        ).data || null;
    } catch (e) {
      snackModule.setError({
        text: this.$t('surveySites.alerts.couldNotGetProperties') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  createMapElement(): Promise<null> {
    return new Promise(resolve => {
      this.$nextTick(() => {
        if (!this.$refs.mapEl || this.map) {
          resolve(null);
        }
        this.map = new mapboxgl.Map({
          accessToken: mapboxToken,
          container: this.$refs.mapEl as HTMLElement,
          style: 'mapbox://styles/mapbox/light-v10',
          center: [146.6, -41.7],
          zoom: 6,
        });
        (this.map as mapboxgl.Map).on('load', () => {
          this.mapLoaded = true;
          resolve(null);
        });
      });
    });
  }

  outlineMap() {
    if (!this.map || !this.mapLoaded || !this.currentProperty) return;

    // clear the map
    if (this.map.getLayer('fill')) {
      this.map.removeLayer('fill');
    }
    if (this.map.getLayer('outline')) {
      this.map.removeLayer('outline');
    }
    if (this.map.getSource('property')) {
      this.map.removeSource('property');
    }

    // Add a data source containing GeoJSON data.
    (this.map as mapboxgl.Map).addSource('property', {
      type: 'geojson',
      data: feature(this.currentProperty.geometry),
    });

    // Add a new layer to visualize the polygon.
    (this.map as mapboxgl.Map).addLayer({
      id: 'fill',
      type: 'fill',
      source: 'property',
      layout: {},
      paint: {
        'fill-color': '#000000',
        'fill-opacity': 0.1,
      },
    });

    // Add a black outline around the polygon.
    (this.map as mapboxgl.Map).addLayer({
      id: 'outline',
      type: 'line',
      source: 'property',
      layout: {},
      paint: {
        'line-color': '#000',
        'line-dasharray': [2, 2],
        'line-width': 1,
      },
    });
  }

  setupDraw() {
    if (!this.map) {
      return;
    }
    if (this.draw) {
      return;
    }
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: true,
        trash: true,
      },
      styles: [
        {
          id: 'highlight-active-points',
          type: 'circle',
          filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['==', 'active', 'true'],
          ],
          paint: {
            'circle-radius': 9,
            'circle-color': '#ffaa00',
          },
        },
        {
          id: 'points-are-blue',
          type: 'circle',
          filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['==', 'active', 'false'],
          ],
          paint: {
            'circle-radius': 9,
            'circle-color': '#00aaff',
          },
        },
      ],
    });
    this.map.addControl(this.draw);
    this.map.on('draw.create', this.onCreateFeature);
    this.map.on('draw.update', this.onUpdateFeature);
    this.map.on('draw.delete', this.onDeleteFeature);
  }

  onCreateFeature(e: DrawCreateEvent) {
    if (!this.draw) {
      return;
    }
    this.draw.deleteAll();
    this.draw.add(e.features[0]);

    const [lng, lat] = (e.features[0].geometry as Point).coordinates;
    this.point = e.features[0].geometry as Point;
    this.lat = lat.toString();
    this.lng = lng.toString();
  }

  onUpdateFeature(e: DrawUpdateEvent) {
    const [lng, lat] = (e.features[0].geometry as Point).coordinates;
    this.point = e.features[0].geometry as Point;
    this.lat = lat.toString();
    this.lng = lng.toString();
  }

  onDeleteFeature() {
    this.point = null;
    this.lat = '';
    this.lng = '';
  }

  manuallySetFeature() {
    if (!this.draw) {
      return;
    }
    this.point = null;
    this.draw.deleteAll();
    try {
      const f = point([parseFloat(this.lng), parseFloat(this.lat)]);
      this.draw.add(f);
      this.point = f.geometry;
      if (this.map) {
        this.map.panTo([parseFloat(this.lng), parseFloat(this.lat)]);
      }
    } catch (e) {
      console.log(e);
      snackModule.setError({
        text: e as string,
        errors: [],
      });
    }
  }

  setExistingSite() {
    if (!this.draw) {
      return;
    }
    if (!this.item) {
      return;
    }
    if (!this.item.location) {
      return;
    }
    this.point = null;
    this.draw.deleteAll();
    try {
      const f = point(this.item.location.coordinates);
      this.draw.add(f);
      this.point = f.geometry;
      this.lat = f.geometry.coordinates[1].toString();
      this.lng = f.geometry.coordinates[0].toString();
    } catch (e) {
      console.log(e);
      snackModule.setError({
        text: e as string,
        errors: [],
      });
    }
  }

  repositionMap() {
    const arr = [];
    if (this.point) {
      arr.push(feature(this.point));
    }
    if (this.currentProperty) {
      arr.push(feature(this.currentProperty.geometry));
    }
    const coll = featureCollection<Point | MultiPolygon>(arr);
    const box = bbox(coll);

    if (this.map && this.mapLoaded) {
      this.map.fitBounds(box as mapboxgl.LngLatBoundsLike, {
        padding: 20,
      });
    }
  }

  /* Functions */
  async save() {
    if (!this.item) return;
    if (!this.point) {
      snackModule.setError({
        text: 'Point required',
        errors: [],
      });
      return;
    }
    try {
      this.item.location = this.point;
      this.loading = true;
      await this.item.save({ with: ['surveyProperty.id'] });
      this.$emit('updated', this.item);
      snackModule.setSuccess(this.$t('common.alerts.success') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('surveySites.alerts.notSaved') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('itemId', { immediate: true })
  async itemIdChanged() {
    if (this.isNewItem) {
      this.title = 'New Site';
      this.item = new SurveySite();
    } else {
      this.title = 'Edit Site';
      try {
        this.item =
          (
            await SurveySite.includes([
              'surveyProperty',
              'vegetationStructureType',
              'recruitmentType',
              'habitatType',
              'zoneType',
            ]).find(this.itemId)
          ).data || null;
      } catch (e) {
        this.notFound = true;
      }
    }
  }

  @Watch('item')
  async setupMap() {
    await this.createMapElement();
    this.setupDraw();
    this.outlineMap();
    this.setExistingSite();
    this.repositionMap();
  }

  @Watch('currentProperty')
  async propertyChanged() {
    this.$nextTick(() => {
      this.outlineMap();
      this.repositionMap();
    });
  }

  async mounted() {
    this.getSurveyProperties();
  }
}
