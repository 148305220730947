import { Model, Attr, HasOne } from 'spraypaint';
import { Media } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class WikiMedia extends ApplicationRecord {
  static jsonapiType = 'wiki-media';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return WikiMedia.includes([]);
  }

  // send multipart to image

  @Attr() createdAt: string;

  @HasOne() media: Media;
}
