import { Model, Attr, BelongsTo, HasOne, HasMany } from 'spraypaint';
import { FaunaMediaTag, FaunaSurvey, Media } from '@/api';
import dayjs from 'dayjs';
import ApplicationRecord from '../ApplicationRecord';
import UserFavourite from './UserFavourite';

@Model()
export default class FaunaMedia extends ApplicationRecord {
  static jsonapiType = 'fauna-media';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Timestamp',
        value: 'timestamp',
      },
    ];
  }

  static scopeFactory() {
    return FaunaMedia.includes([]);
  }

  // send multipart to image

  @Attr() createdAt: string;

  @Attr() timestamp: string;

  @Attr() comment: string;

  @Attr() noFauna: boolean;

  @BelongsTo() faunaSurvey: FaunaSurvey;

  @HasMany() faunaMediaTags: FaunaMediaTag[];

  @HasMany() userFavourites: UserFavourite[];

  @HasOne() media: Media;

  adjustedCount?: number;

  get favouriteCount() {
    return this.adjustedCount !== undefined
      ? this.adjustedCount
      : this.userFavourites.length;
  }

  get formattedDate() {
    return dayjs(this.timestamp).format('MMM D, YYYY');
  }

  get formattedTime() {
    return dayjs(this.timestamp).format('h:mm:ss a');
  }

  get formattedTimestamp() {
    return dayjs(this.timestamp).format('MMM D, YYYY • h:mm:ss a');
  }
}
