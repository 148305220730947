









































import { Component, Vue, Prop } from 'vue-property-decorator';
import { numberFormat } from '@/util';

@Component({ components: {} })
export default class LineIndicator extends Vue {
  @Prop({ default: false }) readonly labels: boolean;

  @Prop({ default: -1 }) readonly index: number;

  @Prop({ default: '' }) readonly thumb: string;

  @Prop({ default: '' }) readonly title: string;

  @Prop({ required: true }) readonly value: number;

  @Prop({ required: true }) readonly max: number;

  @Prop({ default: 'black' }) readonly color: string;

  get lineStyle() {
    return `width: ${Math.min(
      100,
      (100 * this.value) / this.max,
    )}%; background-color: ${this.color};`;
  }

  get numberFormat() {
    return numberFormat;
  }
}
