




















import { Component, Vue } from 'vue-property-decorator';
import AbstractTable from '@/components/common/AbstractTable.vue';

import { SurveyProperty } from '@/api';

@Component({ components: { AbstractTable } })
export default class UserPropertiesList extends Vue {
  get itemId() {
    return this.$route.params.itemId;
  }

  get headers() {
    return SurveyProperty.listHeaders();
  }

  get scopeFactory() {
    return () => SurveyProperty.scopeFactory().where({ owner: this.itemId });
  }

  click(property: SurveyProperty) {
    this.$router.push({
      name: 'admin-user-properties-edit',
      params: { propertyId: property.id as string },
    });
  }
}
