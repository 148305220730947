import { Model, Attr, BelongsTo, HasOne } from 'spraypaint';
import { User, Media } from '@/api';
import dayjs from 'dayjs';

import ApplicationRecord from '../ApplicationRecord';
import SurveySite from './SurveySite';

export enum FaunaSurveyStatus {
  draft = 'draft',
  published = 'published',
  inProgress = 'in_progress',
  assessed = 'assessed',
  verified = 'verified',
  unknown = 'unknown',
}

export const faunaSurveyStatusItems = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Published',
    value: 'published',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Assessed',
    value: 'assessed',
  },
  {
    label: 'Verified',
    value: 'verified',
  },
];

@Model()
export default class FaunaSurvey extends ApplicationRecord {
  static jsonapiType = 'fauna-surveys';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Site',
        value: 'surveySiteName',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return FaunaSurvey.includes([]);
  }

  @Attr() createdAt: string;

  @Attr() startTimestamp: string;

  @Attr() endTimestamp: string;

  @Attr() status: FaunaSurveyStatus;

  @Attr() faunaMediaCount: number;

  // no longer a thing
  // @HasOne() verifiedBy: User;

  @HasOne() assessedBy: User;

  @HasOne() createdBy: User;

  @BelongsTo() surveySite: SurveySite;

  @HasOne() readonly coverMedia: Media;

  // @HasMany() faunaMedia: FaunaMedia[];

  @Attr() readonly surveySiteName: string;

  get formattedDate() {
    const start = dayjs(this.startTimestamp);
    const end = dayjs(this.endTimestamp);

    if (start.year() === end.year()) {
      return `${start.format('MMMM D')} – ${end.format('MMMM D, YYYY')}`;
    }
    return `${start.format('MMMM D, YYYY')} – ${end.format('MMMM D, YYYY')}`;
  }

  get formattedCreatedDate() {
    return dayjs(this.createdAt).fromNow();
  }

  get prettyStatus() {
    switch (this.status) {
      case FaunaSurveyStatus.draft:
        return 'Draft';
      case FaunaSurveyStatus.published:
        return 'Published';
      case FaunaSurveyStatus.inProgress:
        return 'In Progress';
      case FaunaSurveyStatus.assessed:
        return 'Complete';
      default:
        return 'Unknown';
    }
  }

  get createdText() {
    return `Created by ${this.createdBy.name || 'Unknown'}`;
  }

  get progressText() {
    switch (this.status) {
      case FaunaSurveyStatus.draft:
        return 'Draft';
      case FaunaSurveyStatus.published:
        return 'Published';
      case FaunaSurveyStatus.inProgress:
        return `Assessment in progress by ${this.assessedBy.name || 'Unknown'}`;
      case FaunaSurveyStatus.assessed:
      case FaunaSurveyStatus.verified:
        return `Assessed by ${this.assessedBy.name || 'Unknown'} (Complete)`;
      default:
        return 'Unknown';
    }
  }
}
