import './component-hooks';
import Vue from 'vue';

// spraypaint hack
import '@/hack';

// dayjs
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';

// gtag manager
import VueGtag from 'vue-gtag';
import { gTag } from './config';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/vue-i18n';

// css
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@/kml-import/kml-import.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import '@/scss/index.scss';

// dayjs config
import 'dayjs/locale/fr';

dayjs.extend(relativeTime);
dayjs.extend(dayOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(isBetween);

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: { id: gTag },
    enabled: true,
  },
  router,
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
