





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class GetStartedSite extends Vue {
  @Prop({ default: null }) readonly propertyId: string | null;

  get id() {
    return this.propertyId || this.$route.params.propertyId;
  }
}
