











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class LazyImg extends Vue {
  @Prop({ default: '' }) readonly src: string;

  @Prop({ default: '' }) readonly aspect: string;

  get aspectStyle() {
    return this.aspect ? `aspect-ratio: ${this.aspect};` : 'height: 100%;';
  }
}
