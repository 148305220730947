
























import { Component, Vue, Prop } from 'vue-property-decorator';
import i18n from '@/plugins/vue-i18n';

@Component({ components: {} })
export default class ConfirmDialog extends Vue {
  @Prop({ default: i18n.t('confirmDialog.defaultTitle') })
  readonly title: string;

  @Prop({ default: i18n.t('confirmDialog.defaultDescription') })
  readonly description: string;

  @Prop({ default: true })
  readonly dark: boolean;

  @Prop({ default: false })
  readonly block: boolean;

  @Prop({
    default: () => [
      {
        key: 'cancel',
        title: i18n.t('common.cancel'),
        color: 'grey',
        text: true,
      },
      {
        key: 'confirm',
        title: i18n.t('common.confirm'),
        color: 'primary',
        outlined: false,
      },
    ],
  })
  readonly buttons: ButtonInterface[];

  value = true;

  get show() {
    return this.value;
  }

  set show(v) {
    this.$emit('close');
    this.value = v;
  }

  onClick(button: ButtonInterface) {
    this.$emit('select', button.key);
    this.value = false;
  }
}
