import { Model, HasOne, Attr } from 'spraypaint';
import { User, FaunaMedia } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class UserFavourite extends ApplicationRecord {
  static jsonapiType = 'user-favourites';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
    ];
  }

  static scopeFactory() {
    return UserFavourite.includes([]);
  }

  @Attr() mediaUrl: string;

  @Attr() isShared: boolean;

  @HasOne() user: User;

  @HasOne() faunaMedia: FaunaMedia;
}
