



































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import snackModule from '@/store/Snack';
import LazyImg from '@/components/common/LazyImg.vue';
import SingleUpload from '@/components/common/SingleUpload.vue';

import { FloraSurvey } from '@/api';

@Component({
  components: {
    LazyImg,
    SingleUpload,
  },
})
export default class SiteDetail extends Vue {
  @Prop({ required: true }) readonly siteId: number;

  @Prop({ default: false }) readonly disableUpload: boolean;

  floraSurveys: FloraSurvey[] = [];

  index = 0;

  get floraSurvey() {
    return this.floraSurveys[this.index] || null;
  }

  get floraImage() {
    return this.floraSurvey && this.floraSurvey.media
      ? this.floraSurvey.media.thumbnail
      : '';
  }

  get dropzoneOptions() {
    return {
      maxFiles: 1,
      resizeHeight: 800,
      paramName: 'image',
      method: 'POST',
      url: FloraSurvey.url(),
      params: {
        survey_site: JSON.stringify({
          type: 'survey-sites',
          id: this.siteId,
        }),
      },
    };
  }

  setIndex(i: number) {
    this.index = Math.max(0, Math.min(i, this.floraSurveys.length - 1));
  }

  uploadSuccess() {
    this.getFloraSurveys();
  }

  uploadError({
    file,
    message,
    response,
  }: {
    file: unknown;
    message: string;
    response: unknown;
  }) {
    const errors = JSON.parse(message);
    snackModule.setError({
      text: 'Upload failed',
      errors: errors.errors,
    });
  }

  async getFloraSurveys() {
    try {
      this.floraSurveys = [];
      this.index = 0;
      this.floraSurveys = (
        await FloraSurvey.includes('media')
          .where({ surveySite: this.siteId })
          .order({ createdAt: 'desc' })
          .all()
      ).data;
    } catch (e) {
      snackModule.setError({
        text: 'Failed to get latest flora survey',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  @Watch('siteId', { immediate: true })
  siteIdChanged() {
    this.getFloraSurveys();
  }
}
