












import { Component, Prop, Vue } from 'vue-property-decorator';

import dayjs from 'dayjs';
import SiteThumb from '@/components/common/SiteThumb.vue';

import { SurveySite } from '@/api';

@Component({
  components: {
    SiteThumb,
  },
})
export default class SiteDetail extends Vue {
  @Prop({ required: true }) readonly site: SurveySite;

  get formattedCreatedAt() {
    return dayjs(this.site.createdAt).format('MMMM D, YYYY');
  }
}
