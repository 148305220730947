import { Model, Attr, HasMany, HasOne } from 'spraypaint';
import { SurveySite, User } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class SurveySiteImport extends ApplicationRecord {
  static jsonapiType = 'survey-site-imports';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return SurveySiteImport.includes([]);
  }

  // send to csv_file

  @Attr() createdAt: string;

  @Attr() success: boolean;

  @Attr() header: string[];

  @Attr() importErrors: CSVImportError[];

  @HasMany() createdSurveySites: SurveySite[];

  @HasOne() createdBy: User;
}
