







import { Component, Vue, Prop } from 'vue-property-decorator';
import { FaunaSurvey, FaunaSurveyStatus } from '@/api';

@Component({})
export default class FaunaSurveyChip extends Vue {
  @Prop({ required: true }) readonly survey: FaunaSurvey;

  get statusChip() {
    const status = this.survey ? this.survey.status : '';
    switch (status) {
      case FaunaSurveyStatus.draft:
        return {
          title: 'Draft',
          icon: 'mdi-clock-outline',
          color: 'grey',
        };
      case FaunaSurveyStatus.published:
        return {
          title: 'Needs Classification',
          icon: 'mdi-alert-circle-outline',
          color: 'blue-grey',
        };
      case FaunaSurveyStatus.inProgress:
        return {
          title: 'In Progress',
          icon: 'mdi-tag-multiple',
          color: 'blue',
        };
      case FaunaSurveyStatus.assessed:
      case FaunaSurveyStatus.verified:
        return {
          title: 'Complete',
          icon: 'mdi-check',
          color: 'green',
        };
      default:
        return {
          title: 'Unknown',
          icon: 'mdi-help-circle',
          color: 'secondary',
        };
    }
  }
}
