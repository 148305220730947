var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.refreshStats),expression:"refreshStats",modifiers:{"once":true}}],staticClass:"property-list-item"},[_c('div',{staticClass:"d-flex align-center justify-space-between form-horizontal-gap mb-4"},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.property.name))]),_c('div',{staticClass:"text--small secondary--text "},[_vm._v(" "+_vm._s(_vm.property.established)+" ")])]),(_vm.isAdmin)?_c('table-menu-button',{attrs:{"icon":"mdi-dots-horizontal","tooltip":"More Actions","menuDense":true}},[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('deleteItem')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"red--text"},[_vm._v("Delete")])])],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"property-list-item__grid"},[_c('router-link',{staticClass:"property-list-item__outline",attrs:{"to":{
        name: 'property-overview',
        params: { propertyId: _vm.property.id },
      }}},[_c('property-outline',{attrs:{"property":_vm.property}})],1),(!_vm.property.surveySites.length)?_c('get-started-site',{attrs:{"propertyId":_vm.property.id}}):_c('div',{staticClass:"property-list-item__stat-grid"},[_c('stat-tile',{attrs:{"title":"Sites","color":"brown","value":_vm.property.surveySites.length,"to":{
          name: 'property-sites',
          params: { propertyId: _vm.property.id },
        }}}),_c('stat-tile',{attrs:{"title":"Additional Users","color":"purple","value":_vm.property.propertyUsers.length,"to":{
          name: 'property-users',
          params: { propertyId: _vm.property.id },
        }}}),(_vm.surveyCount)?_c('stat-tile',{attrs:{"title":"Surveys","color":"blue","value":_vm.surveyCount,"to":{
          name: 'property-surveys',
          params: { propertyId: _vm.property.id },
        }}}):_vm._e(),(_vm.surveyCount)?_c('stat-tile',{attrs:{"title":"Favourites","color":"red","value":_vm.favouriteCount,"to":{
          name: 'property-favourites',
          params: { propertyId: _vm.property.id },
        }}}):_vm._e(),(_vm.surveyCount)?_c('stat-tile',{staticClass:"property-list-item__tags",attrs:{"title":"Tags","color":"teal","value":_vm.nativeCount,"max":_vm.totalFaunaCount,"showMax":true,"to":{
          name: 'property-tags',
          params: { propertyId: _vm.property.id },
        }}},[_vm._v(" "+_vm._s(_vm.nativeText)+" ")]):_vm._e(),(!_vm.surveyCount)?_c('get-started-survey',{attrs:{"propertyId":_vm.property.id}}):_vm._e()],1),_c('fauna-stats-lines',{attrs:{"property":_vm.property}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }