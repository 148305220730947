var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.ready)?_c('v-app',[_c('div',{staticClass:"loading-container"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"56"}})],1)]):_c('v-app',{staticClass:"app-container",class:{ 'app-container--full-width': _vm.showAppBar }},[(_vm.showNavBar)?_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","color":"grey lighten-4","mobile-breakpoint":"0","touchless":""}},[_c('router-view',{attrs:{"name":"nav"}})],1):_vm._e(),(_vm.showAppBar)?_c('v-app-bar',{attrs:{"app":"","color":"brown","clipped-left":"","flat":"","dark":""}},[_c('router-link',{staticClass:"app-bar-logo",attrs:{"to":{ name: 'home' },"title":"Wildtracker"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-no-text-white.svg"),"alt":"Wildtracker"}})]),_c('v-tabs',{staticClass:"pr-4",attrs:{"right":"","hide-slider":""}},_vm._l((_vm.filteredTabs),function(item,i){return _c('v-tab',{key:i,attrs:{"to":item.to,"exact-path":item.exact}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1),(_vm.isLoggedIn)?_c('div',[_c('v-menu',{attrs:{"offset-y":"","left":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-avatar',{attrs:{"size":"40","color":"primary"}},[_c('v-img',{attrs:{"src":_vm.userAvatar,"alt":""}})],1)],1)]}}],null,true)},[_c('span',[_vm._v("Logout")])])]}}],null,false,1236993577)},[_c('v-list',{staticClass:"user-menu",attrs:{"dense":"","min-width":"200px"}},[_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('common.logout'))+" ")])],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"left":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.version)+" ")])]}}],null,false,245083557)},[_vm._v(" "+_vm._s(_vm.hash)+" ")])],1)],1),_c('v-divider')],1)],1)],1):_vm._e()],1):_vm._e(),_c('v-main',{key:_vm.locale},[(_vm.canAccessRoute)?_c('router-view'):_c('div',[_c('h1',[_vm._v("This route is not for you")])]),_c('page-footer')],1),_c('my-snack-bar'),_c('terms-dialog'),_c('welcome-dialog',{model:{value:(_vm.showWelcomeDialog),callback:function ($$v) {_vm.showWelcomeDialog=$$v},expression:"showWelcomeDialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }