
























import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import EmptyItem from '@/components/common/EmptyItem.vue';

import { User } from '@/api';

@Component({ components: { PageHeader, EmptyItem } })
export default class AdminUsersSingle extends Vue {
  item: User | null = null;

  notFound = false;

  /* Getter & setter */
  get itemId() {
    return this.$route.params.itemId;
  }

  get isNewItem() {
    return this.itemId === undefined;
  }

  get title() {
    return this.isNewItem ? 'New User' : 'Edit User';
  }

  get infoRoute() {
    return this.isNewItem
      ? { name: 'admin-users-new' }
      : { name: 'admin-users-edit', params: { itemId: this.itemId } };
  }

  get backRoute() {
    switch (this.$route.name) {
      case 'admin-user-properties-new':
      case 'admin-user-properties-edit':
        return { name: 'admin-user-properties' };
      default:
        return { name: 'admin-users' };
    }
  }

  @Watch('itemId', { immediate: true })
  async itemIdChanged() {
    if (this.isNewItem) {
      this.item = new User();
    } else {
      try {
        this.item = (await User.find(this.itemId)).data;
      } catch (e) {
        this.notFound = true;
      }
    }
  }
}
