import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class FaunaLeaderboard extends ApplicationRecord {
  static jsonapiType = 'fauna-leaderboards';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
    ];
  }

  static scopeFactory() {
    return FaunaLeaderboard.includes([]);
  }

  @Attr() readonly faunaTag: number | null;

  @Attr() readonly faunaMediaTagCount: number;
}
