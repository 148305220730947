import { Model, Attr, BelongsTo } from 'spraypaint';
import { MultiPolygon } from '@turf/turf';
import { Project } from '@/api';

import dayjs from 'dayjs';

import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class FocalLandscape extends ApplicationRecord {
  static jsonapiType = 'focal-landscapes';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Created',
        value: 'formattedCreatedAt',
      },
    ];
  }

  static scopeFactory() {
    return FocalLandscape.select(['id', 'name', 'createdAt']);
  }

  @Attr() name: string;

  @Attr() code: string;

  @Attr() geometry: MultiPolygon;

  @Attr() createdAt: string;

  @Attr() description: string;

  @BelongsTo() project: Project;

  get formattedCreatedAt() {
    return `${dayjs(this.createdAt).format('MMMM D, YYYY')}`;
  }
}
