import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class FaunaStats extends ApplicationRecord {
  static jsonapiType = 'fauna-stats';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
    ];
  }

  static scopeFactory() {
    return FaunaStats.includes([]);
  }

  @Attr() readonly surveySite: number | null;

  @Attr() readonly faunaSurvey: number | null;

  @Attr() readonly faunaTag: number | null;

  @Attr() readonly faunaTagGroup: number | null;

  @Attr() readonly taggedBy: number | null;

  @Attr() readonly invasive: boolean | null;

  @Attr() readonly count: number;
}
