import { Model, Attr, BelongsTo, HasOne, HasMany } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import WikiMedia from './WikiMedia';

export enum CoverMediaSize {
  none = 'none',
  cover = 'cover',
  top = 'top',
  bottom = 'bottom',
}

export enum CoverMediaPlacement {
  none = 'none',
  left = 'left',
  right = 'right',
}

export enum SidebarPlacement {
  none = 'none',
  left = 'left',
  right = 'right',
}

@Model()
export default class WikiPage extends ApplicationRecord {
  static jsonapiType = 'wiki-pages';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return WikiPage.includes([]);
  }

  @Attr() createdAt: string;

  @Attr() slug: string;

  @Attr() overlineTitle: string;

  @Attr() title: string;

  @Attr() coverMediaCaptionTitle: string;

  @Attr() coverMediaCaption: string;

  @Attr() content: string;

  @Attr() sidebarContent: string;

  @Attr() columnCount: number;

  @Attr() coverMediaPlacement: CoverMediaPlacement;

  @Attr() coverMediaSize: CoverMediaSize;

  @Attr() sidebarPlacement: SidebarPlacement;

  @Attr() urlPath: string;

  @Attr() ordering: number;

  @BelongsTo() parent: WikiPage | null;

  @HasOne() coverMedia: WikiMedia;

  @HasMany() children: WikiPage[];
}
