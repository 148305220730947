




import { Component, Vue } from 'vue-property-decorator';
import NotFound from '@/components/common/NotFound.vue';

@Component({
  components: {
    NotFound,
  },
})
export default class NotFoundView extends Vue {}
