























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';
import LineIndicator from '@/components/common/LineIndicator.vue';

import { numberFormat } from '@/util';

@Component({
  components: {
    LineIndicator,
  },
})
export default class StatTile extends Vue {
  @Prop({ required: false, default: false }) readonly to: Location | false;

  @Prop({ required: true }) readonly title: string;

  @Prop({ required: true }) readonly value: number;

  @Prop({ default: '' }) readonly color: string;

  @Prop({ default: 0 }) readonly max: number;

  @Prop({ default: false }) readonly showMax: boolean;

  get colorClass() {
    return `${this.color}--text`;
  }

  get numberFormat() {
    return numberFormat;
  }
}
