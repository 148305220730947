var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lazy-img',_vm._g(_vm._b({staticClass:"fauna-media-thumb",attrs:{"src":_vm.faunaMedia.media.thumbnail}},'lazy-img',_vm.$attrs,false),_vm.$listeners),[[_c('div',{staticClass:"fauna-media-thumb__hover"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[(!_vm.isDraft)?_c('div',[_c('v-tooltip',{attrs:{"right":"","color":"lime"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","outlined":!_vm.userFavourite || !_vm.userFavourite.isShared,"color":_vm.userFavourite && _vm.userFavourite.isShared
                    ? 'blue white--text'
                    : 'white'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.share.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-share ")])],1)]}}],null,false,1439756541)},[_vm._v(" "+_vm._s(_vm.userFavourite && _vm.userFavourite.isShared ? 'Sharing enabled' : 'Share this photo')+" ")]),_c('v-tooltip',{attrs:{"right":"","color":"lime"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"fab":"","outlined":!_vm.faunaMedia.favouriteCount,"x-small":"","color":_vm.userFavourite ? 'red white--text' : 'white'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleFavourite.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-heart ")]),(_vm.faunaMedia.favouriteCount)?_c('v-badge',{attrs:{"color":_vm.userFavourite ? 'black' : 'red',"content":_vm.faunaMedia.favouriteCount}}):_vm._e()],1)]}}],null,false,190741596),model:{value:(_vm.favouriteTooltip),callback:function ($$v) {_vm.favouriteTooltip=$$v},expression:"favouriteTooltip"}},[(_vm.users.length)?_c('div',_vm._l((_vm.users),function(user){return _c('div',{key:user},[_vm._v(_vm._s(user))])}),0):_c('div',[_vm._v(" Add to Favourites ")])])],1):_vm._e(),_c('div',{staticClass:"d-flex align-center form-horizontal-gap-small mr-2"},[(_vm.faunaMedia.noFauna)?_c('div',[_c('v-tooltip',{attrs:{"right":"","color":"lime"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-paw-off ")])]}}],null,false,2799710595)},[_vm._v(" No Animal ")])],1):_vm._e(),(_vm.faunaMedia.faunaMediaTags.length)?_c('div',[_c('v-tooltip',{attrs:{"right":"","color":"lime"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-tag-multiple ")])]}}],null,false,1565158952)},_vm._l((_vm.faunaMedia.faunaMediaTags),function(tag){return _c('div',{key:tag.id},[_vm._v(" "+_vm._s(_vm.tagById(tag.faunaTag.id).name)+" ")])}),0)],1):_vm._e(),(_vm.faunaMedia.comment)?_c('div',[_c('v-tooltip',{attrs:{"right":"","color":"lime"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-comment ")])]}}],null,false,805371124)},[_c('div',[_vm._v(" "+_vm._s(_vm.faunaMedia.comment)+" ")])])],1):_vm._e()])]),_c('div',{staticClass:"text--small pt-4"},[_c('strong',[_vm._v(_vm._s(_vm.faunaMedia.faunaSurvey.surveySiteName))]),_c('div',[_vm._v(_vm._s(_vm.faunaMedia.formattedTime))]),_c('div',[_vm._v(_vm._s(_vm.faunaMedia.formattedDate))])])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }