var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-list-item"},[_c('div',{staticClass:"d-flex justify-space-between pt-8 pb-4"},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.survey.formattedDate))]),_c('div',{staticClass:"text--small secondary--text"},[_c('span',[_vm._v(_vm._s(_vm.survey.surveySiteName))]),_c('span',{staticClass:"ml-1 mr-1"},[_vm._v(" • ")]),_c('span',[_vm._v("Survey "+_vm._s(_vm.survey.id)+" ")]),_c('span',{staticClass:"ml-1 mr-1"},[_vm._v(" • ")]),_c('span',[_vm._v(_vm._s(_vm.survey.createdText)+" "+_vm._s(_vm.survey.formattedCreatedDate)+" ")])])]),_c('div',{staticClass:"d-flex align-center form-horizontal-gap-small"},[_c('fauna-survey-chip',{attrs:{"survey":_vm.survey}}),(_vm.draft || _vm.isAdmin)?_c('table-menu-button',{attrs:{"icon":"mdi-dots-horizontal","tooltip":"More Actions","menuDense":true}},[(_vm.draft)?_c('v-list-item',{attrs:{"to":{
            name: 'property-surveys-edit',
            params: { surveyId: _vm.survey.id },
          }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Continue editing ")])],1)],1):_vm._e(),(_vm.draft || _vm.isAdmin)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('deleteItem')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"red--text"},[_vm._v("Delete")])])],1)],1):_vm._e()],1):_vm._e()],1)]),_c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.getFaunaMedia),expression:"getFaunaMedia",modifiers:{"once":true}}],staticClass:"survey-list-item__gallery"},[_vm._l((_vm.listLength),function(i){return _c('router-link',{key:i,staticClass:"no-underline",attrs:{"to":{
        name: 'classifier',
        params: { faunaSurveyId: _vm.faunaMediaList[i - 1].faunaSurvey.id },
        query: { id: _vm.faunaMediaList[i - 1].id },
      }}},[_c('fauna-media-thumb',{attrs:{"faunaMedia":_vm.faunaMediaList[i - 1]}})],1)}),(_vm.faunaMediaListReady)?_c('router-link',{staticClass:"survey-list-item__gallery-more text--small",class:{ 'survey-list-item__gallery-more--zero': _vm.listLength === 0 },attrs:{"to":_vm.classifierLink}},[_vm._v(" "+_vm._s(_vm.linkLabel)+" ")]):_vm._e()],2),(_vm.draft)?_c('v-btn',{staticClass:"mt-4",attrs:{"large":"","block":"","depressed":"","color":"pink white--text","to":{
      name: 'property-surveys-edit',
      params: { surveyId: _vm.survey.id },
    }}},[_vm._v(" Continue editing ")]):_vm._e(),_c('fauna-stats-lines',{staticClass:"mt-6",attrs:{"survey":_vm.survey}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }