





















































import { Component, Vue } from 'vue-property-decorator';
import { FocalLandscape, FullFocalLandscapeExport } from '@/api';
import cacheModule from '@/store/Cache';
import snackModule from '@/store/Snack';

@Component({
  components: {},
})
export default class AdminCsvExportDialog extends Vue {
  value = true;

  get show() {
    return this.value;
  }

  set show(v) {
    this.$emit('close');
    this.value = v;
  }

  generated = false;

  loading = false;

  focalLandscape: FocalLandscape | null = null;

  get focalLandscapes() {
    return cacheModule.focalLandscapes;
  }

  async getExport() {
    try {
      this.loading = true;
      const csvExport = new FullFocalLandscapeExport({
        focalLandscape: this.focalLandscape,
      });

      await csvExport.save({ with: 'focalLandscape.id' });
      this.generated = true;
    } catch (e) {
      snackModule.setError({
        text: 'Error generating CSV Export',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }
}
