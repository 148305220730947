import { Vue } from 'vue-property-decorator';

import vuetify from '@/plugins/vuetify';

import InviteUserDialog from '@/components/common/InviteUserDialog.vue';
import { SurveyProperty, PropertyUser } from '@/api';

const InviteUserDialogClass = Vue.extend(InviteUserDialog);

export default function inviteUserDialog(
  property: SurveyProperty,
  propertyUser: PropertyUser | null,
): Promise<string | number | null> {
  return new Promise(resolve => {
    const dialog = new InviteUserDialogClass({
      vuetify,
      propsData: { property, propertyUser },
    });
    dialog.$on('close', () => {
      dialog.$destroy();
      resolve(null);
    });
    dialog.$mount();
  });
}
