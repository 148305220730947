




















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import StatTile from '@/components/common/StatTile.vue';
import GetStartedSite from '@/components/common/GetStartedSite.vue';
import GetStartedSurvey from '@/components/common/GetStartedSurvey.vue';
import SidebarTasks from '@/components/common/SidebarTasks.vue';
import SidebarTiles from '@/components/common/SidebarTiles.vue';
import { SurveyProperty } from '@/api';

import UserStatsLines from '@/components/property/UserStatsLines.vue';
import FaunaStatsLines from '@/components/property/FaunaStatsLines.vue';
import SiteStatsLines from '@/components/property/SiteStatsLines.vue';
import PropertyMap from '@/components/property/PropertyMap.vue';

import propertyModule from '@/store/Property';

import { numberFormat } from '@/util';

@Component({
  components: {
    StatTile,
    GetStartedSite,
    GetStartedSurvey,
    UserStatsLines,
    FaunaStatsLines,
    SiteStatsLines,
    PropertyMap,
    SidebarTasks,
    SidebarTiles,
  },
})
export default class PropertyOverview extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get surveyCount() {
    return propertyModule.propertySurveyCount(this.property.id as string);
  }

  get nativeCount() {
    return propertyModule.propertyNativeCount(this.property.id as string);
  }

  get totalFaunaCount() {
    return propertyModule.propertyFaunaCount(this.property.id as string);
  }

  get favouriteCount() {
    return propertyModule.propertyFavouriteCount(this.property.id as string);
  }

  get nativeText() {
    return `${numberFormat(this.nativeCount)} native`;
  }

  async mounted() {
    propertyModule.getPropertyInvasiveStats(this.property.id as string);
    propertyModule.getPropertySurveyStats(this.property.id as string);
    propertyModule.getPropertyFavourites(this.property.id as string);
  }
}
