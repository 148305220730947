import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import { MultiPolygon } from '@turf/turf';
import { User, SurveySite, FocalLandscape } from '@/api';
import dayjs from 'dayjs';
import PropertyUser from './PropertyUser';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class SurveyProperty extends ApplicationRecord {
  static jsonapiType = 'survey-properties';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Owner',
        value: 'owner.email',
      },
      {
        text: 'Sites',
        value: 'siteCount',
      },
    ];
  }

  static scopeFactory() {
    return SurveyProperty.includes(['owner']).select([
      'id',
      'name',
      'code',
      'owner',
      'focal_landscape',
      'survey_sites',
    ]);
  }

  get siteCount() {
    return this.surveySites.length;
  }

  get established() {
    return this.owner && this.owner.name
      ? `Owned by ${this.owner.name} • Established ${dayjs(
          this.createdAt,
        ).format('MMMM D, YYYY')}`
      : `Established ${dayjs(this.createdAt).format('MMMM D, YYYY')}`;
  }

  @Attr() name: string;

  @Attr() description: string;

  @Attr() geometry: MultiPolygon;

  @Attr() createdAt: string;

  @Attr() code: string;

  @BelongsTo() owner: User;

  @BelongsTo() focalLandscape: FocalLandscape;

  @HasMany() surveySites: SurveySite[]; // has many

  @HasMany() propertyUsers: PropertyUser[]; // has many
}
