import { Model, Attr, HasMany } from 'spraypaint';
import { FaunaTag } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

export enum EcondCalculationMethod {
  econd = 'econd',
  reverse = 'reverse',
  none = 'none',
}

@Model()
export default class FaunaTagGroup extends ApplicationRecord {
  static jsonapiType = 'fauna-tag-groups';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Tags',
        value: 'faunaTags.length',
      },
    ];
  }

  static scopeFactory() {
    return FaunaTagGroup.includes([]);
  }

  @Attr() name: string;

  @Attr() description: string;

  @Attr() code: string;

  @Attr() createdAt: string;

  @HasMany() faunaTags: FaunaTag[];
}
