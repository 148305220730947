



















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ImageResize extends Vue {
  @Prop({ required: true }) readonly src: string;

  parentW = 0;

  parentH = 0;

  natW = 0;

  natH = 0;

  actualW = 0;

  actualH = 0;

  actualX = 0;

  actualY = 0;

  layerX = 0;

  layerY = 0;

  zoom = false;

  disabled = true;

  observer: ResizeObserver;

  zoomFactor = 3;

  get style() {
    return this.natW / this.natH < this.parentW / this.parentH
      ? 'width:auto; height:100%;'
      : 'width:100%; height:auto;';
  }

  get zoomW() {
    return this.actualW / this.zoomFactor;
  }

  get zoomH() {
    return this.actualH / this.zoomFactor;
  }

  get zoomX() {
    return Math.max(
      this.actualX,
      Math.min(
        this.layerX - this.zoomW / 2,
        this.actualX + this.actualW - this.zoomW,
      ),
    );
  }

  get zoomY() {
    return Math.max(
      this.actualY,
      Math.min(
        this.layerY - this.zoomH / 2,
        this.actualY + this.actualH - this.zoomH,
      ),
    );
  }

  get zoomedStyle() {
    if (!this.zoom) {
      return '';
    }
    return `left: ${this.actualX}px; top: ${this.actualY}px; width: ${
      this.actualW
    }px; height: ${this.actualH}px; background-image: url(${
      this.src
    }); background-size: ${this.zoomFactor *
      100}%; background-position: ${(-this.zoomX + this.actualX) *
      this.zoomFactor}px ${(-this.zoomY + this.actualY) * this.zoomFactor}px;`;
  }

  get zoomStyle() {
    if (!this.zoom) {
      return '';
    }
    return `width: ${this.zoomW}px; height: ${this.zoomH}px; left: ${this.zoomX}px; top: ${this.zoomY}px;`;
  }

  mouseEnter() {
    this.zoom = !this.disabled;

    this.actualX = (this.$refs.img as HTMLImageElement).offsetLeft;
    this.actualY = (this.$refs.img as HTMLImageElement).offsetTop;
    this.actualW = (this.$refs.img as HTMLImageElement).offsetWidth;
    this.actualH = (this.$refs.img as HTMLImageElement).offsetHeight;
  }

  mouseMove(e: MouseEvent) {
    this.layerX = e.layerX;
    this.layerY = e.layerY;
  }

  mouseLeave() {
    this.zoom = false;
  }

  toggleZoom() {
    this.disabled = !this.disabled;
    this.mouseEnter();
  }

  onLoad() {
    this.natW = (this.$refs.img as HTMLImageElement).naturalWidth;
    this.natH = (this.$refs.img as HTMLImageElement).naturalHeight;
    this.parentW = (this.$el as HTMLElement).offsetWidth;
    this.parentH = (this.$el as HTMLElement).offsetHeight;
  }

  created() {
    this.observer = new window.ResizeObserver(() => {
      this.onLoad();
    });
  }

  mounted() {
    this.observer.observe(this.$el);
  }

  beforeDestroy() {
    this.observer.disconnect();
  }
}
