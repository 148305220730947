














































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: {} })
export default class ConfirmDialog extends Vue {
  @Prop({ required: true })
  readonly itemName: string;

  @Prop({ required: true })
  readonly itemType: string;

  @Prop({ required: true })
  readonly confirmCode: string;

  confirmText = '';

  value = true;

  get valid() {
    return this.confirmText === this.confirmCode;
  }

  get show() {
    return this.value;
  }

  set show(v) {
    this.$emit('close');
    this.value = v;
  }

  onClick(confirm: boolean) {
    this.$emit('confirm', confirm);
    this.value = false;
  }
}
