









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class EmptyItem extends Vue {
  @Prop({ default: 'No data available' })
  readonly title: string;
}
