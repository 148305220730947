import { Model, Attr, HasOne } from 'spraypaint';
import { FocalLandscape, User } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class FullFocalLandscapeExport extends ApplicationRecord {
  static jsonapiType = 'full-focal-landscape-exports';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return FullFocalLandscapeExport.includes([]);
  }

  // send to csv_file

  @Attr() status: 'pending' | 'generating' | 'complete' | 'failed'; // enum

  @Attr() finishedAt: string | null;

  @Attr() createdAt: string;

  // set this one
  @HasOne() focalLandscape: FocalLandscape;

  @HasOne() createdBy: User;
}
