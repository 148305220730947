import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import SurveyProperty from './SurveyProperty';

@Model()
export default class SurveyPropertyMetadataSurvey extends ApplicationRecord {
  static jsonapiType = 'survey-property-metadata-surveys';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return SurveyPropertyMetadataSurvey.includes([]);
  }

  @Attr() createdAt: string;

  @Attr() extent: number;

  @BelongsTo() surveyProperty: SurveyProperty;
}
