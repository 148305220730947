/* eslint-disable no-underscore-dangle */
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';

import { Scope } from 'spraypaint';
import { PublicUser, SurveyProperty, FaunaStats, FavouriteStats } from '@/api';

import store from '@/store';
import Vue from 'vue';

// I know I'm reinventing the wheel here, but it was the simplest way to
// implement a basic throttle for each request while retaining reactivity
const throttle = new Map();

function checkThrottle(key: string) {
  const ts = throttle.get(key);
  return !ts || ts < Date.now() - 30000;
}

function setThrottle(key: string) {
  throttle.set(key, Date.now());
}

@Module({ dynamic: true, namespaced: true, name: 'property', store })
class PropertyModule extends VuexModule {
  _mappedProperties: { [id: string]: SurveyProperty } = {};

  _mappedPropertyStats: { [id: string]: FaunaStats[] } = {};

  _mappedPropertyFavourites: { [id: string]: number } = {};

  _mappedSiteFavourites: { [id: string]: number } = {};

  _mappedSiteStats: { [id: string]: FaunaStats[] } = {};

  _mappedSurveyStats: { [id: string]: FaunaStats[] } = {};

  _mappedUsers: { [id: string]: PublicUser } = {};

  get publicUserById() {
    return (id: string) => this._mappedUsers[id];
  }

  get propertyUsers() {
    return (propertyId: string) => {
      const property = this._mappedProperties[propertyId];
      return property
        ? [property.owner, ...property.propertyUsers.map(pu => pu.user)]
        : [];
    };
  }

  get property() {
    return (propertyId: string) => {
      return propertyId in this._mappedProperties
        ? this._mappedProperties[propertyId]
        : null;
    };
  }

  get propertyFavouriteCount() {
    return (propertyId: string) => {
      return propertyId in this._mappedPropertyFavourites
        ? this._mappedPropertyFavourites[propertyId]
        : 0;
    };
  }

  get siteFavouriteCount() {
    return (siteId: string) => {
      return siteId in this._mappedSiteFavourites
        ? this._mappedSiteFavourites[siteId]
        : 0;
    };
  }

  get propertyStats() {
    return (propertyId: string, groupBy: string) => {
      const key = `${propertyId}:${groupBy}`;
      return key in this._mappedPropertyStats
        ? this._mappedPropertyStats[key]
        : null;
    };
  }

  get siteStats() {
    return (siteId: string, groupBy: string) => {
      const key = `${siteId}:${groupBy}`;
      return key in this._mappedSiteStats ? this._mappedSiteStats[key] : null;
    };
  }

  get surveyStats() {
    return (surveyId: string, groupBy: string) => {
      const key = `${surveyId}:${groupBy}`;
      return key in this._mappedSurveyStats
        ? this._mappedSurveyStats[key]
        : null;
    };
  }

  get propertyNativeCount() {
    return (propertyId: string) => {
      const stats = this.propertyStats(propertyId, 'invasive');
      if (!stats) {
        return 0;
      }
      const native = stats.find(stat => stat.invasive === false);
      return native ? native.count : 0;
    };
  }

  get propertySurveyCount() {
    return (propertyId: string) => {
      const stats = this.propertyStats(propertyId, 'fauna_survey');
      if (!stats) {
        return 0;
      }
      return stats.filter(stat => stat.faunaSurvey !== null).length;
    };
  }

  get siteSurveyCount() {
    return (siteId: string) => {
      const stats = this.siteStats(siteId, 'fauna_survey');
      if (!stats) {
        return 0;
      }
      return stats.filter(stat => stat.faunaSurvey !== null).length;
    };
  }

  get propertyFaunaCount() {
    return (propertyId: string) => {
      const stats = this.propertyStats(propertyId, 'fauna_tag');
      if (!stats) {
        return 0;
      }
      return stats.reduce((count, stat) => count + stat.count, 0);
    };
  }

  get siteNativeCount() {
    return (siteId: string) => {
      const stats = this.siteStats(siteId, 'invasive');
      if (!stats) {
        return 0;
      }
      const native = stats.find(stat => stat.invasive === false);
      return native ? native.count : 0;
    };
  }

  get siteFaunaCount() {
    return (siteId: string) => {
      const stats = this.siteStats(siteId, 'fauna_tag');
      if (!stats) {
        return 0;
      }
      return stats.reduce((count, stat) => count + stat.count, 0);
    };
  }

  // mutations
  @Mutation
  setProperties(properties: SurveyProperty[]) {
    properties.forEach(property => {
      Vue.set(this._mappedProperties, property.id as string, property);
    });
  }

  @Mutation
  setPropertyStats({
    propertyId,
    groupBy,
    stats,
  }: {
    propertyId: string;
    groupBy: string;
    stats: FaunaStats[];
  }) {
    Vue.set(this._mappedPropertyStats, `${propertyId}:${groupBy}`, stats);
  }

  @Mutation
  setPropertyFavourites({
    propertyId,
    count,
  }: {
    propertyId: string;
    count: number;
  }) {
    Vue.set(this._mappedPropertyFavourites, propertyId, count);
  }

  @Mutation
  setSiteFavourites({ siteId, count }: { siteId: string; count: number }) {
    Vue.set(this._mappedSiteFavourites, siteId, count);
  }

  @Mutation
  setSiteStats({
    siteId,
    groupBy,
    stats,
  }: {
    siteId: string;
    groupBy: string;
    stats: FaunaStats[];
  }) {
    Vue.set(this._mappedSiteStats, `${siteId}:${groupBy}`, stats);
  }

  @Mutation
  setSurveyStats({
    surveyId,
    groupBy,
    stats,
  }: {
    surveyId: string;
    groupBy: string;
    stats: FaunaStats[];
  }) {
    Vue.set(this._mappedSurveyStats, `${surveyId}:${groupBy}`, stats);
  }

  @Mutation
  setPublicUsers(publicUsers: PublicUser[]) {
    publicUsers.forEach(publicUser => {
      Vue.set(this._mappedUsers, publicUser.id as string, publicUser);
    });
  }

  // actions
  @Action({ rawError: true })
  async getPublicUsers(ids: string[]) {
    const publicUsers = (await PublicUser.where({ id__in: ids }).all()).data;
    this.setPublicUsers(publicUsers);
    return publicUsers;
  }

  @Action({ rawError: true })
  async getProperties(scope: Scope<SurveyProperty>) {
    const properties = await scope.all();
    this.setProperties(properties.data);
    return properties;
  }

  @Action({ rawError: true })
  async getProperty(propertyId: string) {
    const property = (
      await SurveyProperty.includes([
        'surveySites',
        'propertyUsers.user',
        'owner',
      ]).find(propertyId)
    ).data;
    this.setProperties([property]);
    this.getPropertySurveyStats(propertyId);
    this.getPropertyInvasiveStats(propertyId);
    this.getPropertyFavourites(propertyId);
    return property;
  }

  @Action({ rawError: true })
  async getPropertyInvasiveStats(propertyId: string) {
    const throttleKey = `getPropertyInvasiveStats:${propertyId}`;
    if (!checkThrottle(throttleKey)) {
      return;
    }
    setThrottle(throttleKey);

    const stats = (
      await FaunaStats.where({
        survey_property__in: propertyId,
        group_by: ['invasive'],
      }).all()
    ).data;
    this.setPropertyStats({ propertyId, groupBy: 'invasive', stats });
  }

  @Action({ rawError: true })
  async getPropertySurveyStats(propertyId: string) {
    const throttleKey = `getPropertySurveyStats:${propertyId}`;
    if (!checkThrottle(throttleKey)) {
      return;
    }
    setThrottle(throttleKey);

    const stats = (
      await FaunaStats.where({
        survey_property__in: propertyId,
        group_by: ['fauna_survey'],
      }).all()
    ).data;
    this.setPropertyStats({ propertyId, groupBy: 'fauna_survey', stats });
  }

  @Action({ rawError: true })
  async getPropertyTagStats(propertyId: string) {
    const throttleKey = `getPropertyTagStats:${propertyId}`;
    if (!checkThrottle(throttleKey)) {
      return;
    }
    setThrottle(throttleKey);

    const stats = (
      await FaunaStats.where({
        survey_property__in: propertyId,
        group_by: ['fauna_tag'],
      }).all()
    ).data;
    this.setPropertyStats({ propertyId, groupBy: 'fauna_tag', stats });
  }

  @Action({ rawError: true })
  async getSiteTagStats(siteId: string) {
    const throttleKey = `getSiteTagStats:${siteId}`;
    if (!checkThrottle(throttleKey)) {
      return;
    }
    setThrottle(throttleKey);

    const stats = (
      await FaunaStats.where({
        survey_site__in: siteId,
        group_by: ['survey_site', 'fauna_tag'],
      }).all()
    ).data;
    this.setSiteStats({ siteId, groupBy: 'fauna_tag', stats });
  }

  @Action({ rawError: true })
  async getSurveyTagStats(surveyId: string) {
    const throttleKey = `getSurveyTagStats:${surveyId}`;
    if (!checkThrottle(throttleKey)) {
      return;
    }
    setThrottle(throttleKey);

    const stats = (
      await FaunaStats.where({
        fauna_survey__in: surveyId,
        group_by: ['survey_site', 'fauna_tag'],
      }).all()
    ).data;
    this.setSurveyStats({ surveyId, groupBy: 'fauna_tag', stats });
  }

  @Action({ rawError: true })
  async getSiteInvasiveStats(siteId: string) {
    const throttleKey = `getSiteInvasiveStats:${siteId}`;
    if (!checkThrottle(throttleKey)) {
      return;
    }
    setThrottle(throttleKey);

    const stats = (
      await FaunaStats.where({
        survey_site__in: siteId,
        group_by: ['survey_site', 'invasive'],
      }).all()
    ).data;
    this.setSiteStats({ siteId, groupBy: 'invasive', stats });
  }

  @Action({ rawError: true })
  async getSiteSurveyStats(siteId: string) {
    const throttleKey = `getSiteSurveyStats:${siteId}`;
    if (!checkThrottle(throttleKey)) {
      return;
    }
    setThrottle(throttleKey);

    const stats = (
      await FaunaStats.where({
        survey_site__in: siteId,
        group_by: ['survey_site', 'fauna_survey'],
      }).all()
    ).data;
    this.setSiteStats({ siteId, groupBy: 'fauna_survey', stats });
  }

  @Action({ rawError: true })
  async getPropertyFavourites(propertyId: string) {
    const throttleKey = `getPropertyFavourites:${propertyId}`;
    if (!checkThrottle(throttleKey)) {
      return;
    }
    setThrottle(throttleKey);

    const stats = (
      await FavouriteStats.where({
        survey_property__in: propertyId,
        group_by: ['survey_site'],
      }).all()
    ).data;

    let totalCount = 0;
    stats.forEach(stat => {
      totalCount += stat.count;
      if (stat.surveySite !== null) {
        this.setSiteFavourites({
          siteId: stat.surveySite.toString(),
          count: stat.count,
        });
      }
    });

    this.setPropertyFavourites({ propertyId, count: totalCount });
  }
}

export default getModule(PropertyModule, store);
