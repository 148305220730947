








import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class GridResize extends Vue {
  resizing = false;

  initial = 0;

  startResize(e: MouseEvent) {
    this.initial = e.clientX;
    this.resizing = true;

    window.addEventListener('mousemove', this.mouseMove);
    window.addEventListener('mouseup', this.endResize);
  }

  endResize(e: MouseEvent) {
    const delta = e.clientX - this.initial;
    this.$emit('end', { left: e.clientX, delta });
    this.resizing = false;

    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.endResize);
  }

  mouseMove(e: MouseEvent) {
    const delta = e.clientX - this.initial;
    this.$emit('delta', { left: e.clientX, delta });
  }

  beforeDestroy() {
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.endResize);
  }
}
