





































import { Prop, Component, Vue } from 'vue-property-decorator';
import { SurveyProperty, SurveySite } from '@/api';

import SiteMapPreview from '@/components/property/SiteMapPreview.vue';
import SiteThumb from '@/components/common/SiteThumb.vue';

import SingleUpload from '@/components/common/SingleUpload.vue';

@Component({
  components: {
    SiteMapPreview,
    SiteThumb,
    SingleUpload,
  },
})
export default class SiteStep2 extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly site: SurveySite;
}
