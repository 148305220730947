





































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import EmptyItem from '@/components/common/EmptyItem.vue';
import OutlineEditor from '@/components/admin/OutlineEditor.vue';

import v8n from 'v8n';

import { FocalLandscape, Project } from '@/api/';
import snackModule from '@/store/Snack';
import cacheModule from '@/store/Cache';

@Component({ components: { PageHeader, EmptyItem, OutlineEditor } })
export default class AdminFocalLandscapesSingle extends Vue {
  /* Attributes */
  title: string;

  item: FocalLandscape | null = null;

  project: Project | null = null;

  notFound = false;

  loading = false;

  valid = false;

  /* Form validations */
  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  optionalString = [
    (v: string) =>
      v8n()
        .optional(v8n().string(), true)
        .test(v),
  ];

  /* Getter & setter */
  get itemId() {
    return this.$route.params.itemId;
  }

  get isNewItem() {
    return this.itemId === undefined;
  }

  get outlineEditor() {
    return this.$refs.outlineEditor as OutlineEditor;
  }

  /* Functions */
  async save() {
    if (!this.item) return;

    const multiPoly = this.outlineEditor.getMultiPoly();
    if (!multiPoly) {
      snackModule.setError({
        text: 'Must provide geometry',
        errors: [],
      });
      return;
    }
    this.item.geometry = multiPoly.geometry;

    try {
      this.loading = true;
      await this.item.save();
      this.$emit('updated', this.item);
      snackModule.setSuccess(this.$t('common.alerts.success') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('surveySites.alerts.notSaved') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('itemId', { immediate: true })
  async itemIdChanged() {
    if (this.isNewItem) {
      this.title = 'New Focal Landscape';
      this.item = new FocalLandscape({ project: cacheModule.project });
    } else {
      this.title = 'Edit Focal Landscape';
      try {
        this.item = (await FocalLandscape.find(this.itemId)).data || null;
      } catch (e) {
        this.notFound = true;
      }
    }
  }
}
