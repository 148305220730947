



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class FilterItem extends Vue {
  @Prop({ required: true }) readonly label: string;

  @Prop({ required: true }) readonly paramName: string;

  @Prop({ required: true }) readonly items: Array<{
    label: string;
    value: string;
  }>;

  hasQuery = false;

  get allItems() {
    return [{ label: this.$t('common.labels.all'), value: '' }, ...this.items];
  }

  get currentQuery() {
    return (this.$route.query[this.paramName] as string) || '';
  }

  set currentQuery(val: string) {
    if (val !== this.currentQuery) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          [this.paramName]: val || undefined,
          page: undefined,
        },
      });
    }
  }

  mounted() {
    this.hasQuery = !!this.currentQuery;
  }

  @Watch('hasQuery')
  filterChanged() {
    if (!this.hasQuery) {
      this.currentQuery = '';
    }
  }
}
