














































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/common/PageHeader.vue';
import GetStartedSite from '@/components/common/GetStartedSite.vue';
import GetStartedSurvey from '@/components/common/GetStartedSurvey.vue';
import StatTile from '@/components/common/StatTile.vue';
import PropertyOutline from '@/components/property/PropertyOutline.vue';
import FaunaStatsLines from '@/components/property/FaunaStatsLines.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';

import { SurveyProperty } from '@/api';
import authModule from '@/store/Auth';
import propertyModule from '@/store/Property';

import { numberFormat } from '@/util';

@Component({
  components: {
    PageHeader,
    PropertyOutline,
    GetStartedSite,
    GetStartedSurvey,
    StatTile,
    FaunaStatsLines,
    TableMenuButton,
  },
})
export default class PropertyListItem extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get surveyCount() {
    return propertyModule.propertySurveyCount(this.property.id as string);
  }

  get nativeCount() {
    return propertyModule.propertyNativeCount(this.property.id as string);
  }

  get totalFaunaCount() {
    return propertyModule.propertyFaunaCount(this.property.id as string);
  }

  get favouriteCount() {
    return propertyModule.propertyFavouriteCount(this.property.id as string);
  }

  get nativeText() {
    return `${numberFormat(this.nativeCount)} native`;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  refreshStats() {
    propertyModule.getPropertyInvasiveStats(this.property.id as string);
    propertyModule.getPropertySurveyStats(this.property.id as string);
    propertyModule.getPropertyFavourites(this.property.id as string);
  }
}
