import { Model, Attr, HasOne } from 'spraypaint';
import { User, SurveyProperty } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class SurveyImageExport extends ApplicationRecord {
  static jsonapiType = 'survey-image-exports';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return SurveyImageExport.includes([]);
  }

  // send to csv_file

  @Attr() status: 'pending' | 'generating' | 'complete' | 'failed'; // enum

  @Attr() finishedAt: string | null;

  @Attr() createdAt: string;

  // set this one
  @HasOne() surveyProperty: SurveyProperty;

  @HasOne() createdBy: User;
}
