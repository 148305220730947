











import { Component, Vue } from 'vue-property-decorator';

import LazyImg from '@/components/common/LazyImg.vue';

@Component({
  components: { LazyImg },
})
export default class SidebarInfo extends Vue {}
