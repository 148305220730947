























import { Component, Vue } from 'vue-property-decorator';
import { FaunaTagGroup } from '@/api';
import PageHeader from '@/components/common/PageHeader.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';

@Component({ components: { PageHeader, AbstractTable } })
export default class AdminFaunaTagGroupsList extends Vue {
  get headers() {
    return FaunaTagGroup.listHeaders();
  }

  get scopeFactory() {
    return FaunaTagGroup.scopeFactory;
  }

  click(item: FaunaTagGroup) {
    this.$router.push({
      name: 'admin-fauna-tag-groups-edit',
      params: { itemId: item.id as string },
    });
  }
}
