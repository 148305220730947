






import { Component, VModel, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Stepper extends Vue {
  @VModel() selected: string | number | boolean | symbol | undefined;

  get steps() {
    if (!this.$slots.default) {
      return [];
    }
    return this.$slots.default.filter(
      cmp =>
        cmp.componentOptions && cmp.componentOptions.tag === 'stepper-item',
    );
  }

  setStep(i: string | number | boolean | symbol | undefined) {
    this.selected = i;
  }
}
