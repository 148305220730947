






















































import { Prop, Component, Vue } from 'vue-property-decorator';
import { SurveyProperty, SurveySite, FaunaSurvey } from '@/api';
import DatePicker from '@/components/common/DatePicker.vue';
import SitePreview from '@/components/property/SitePreview.vue';

import propertyModule from '@/store/Property';
import snackModule from '@/store/Snack';

import mapboxgl from 'mapbox-gl';

import { bbox, center, feature } from '@turf/turf';
import { mapboxToken } from '@/config';
import dayjs from 'dayjs';

@Component({
  components: { DatePicker, SitePreview },
})
export default class SurveyStep1 extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly survey: FaunaSurvey;

  map: mapboxgl.Map | null = null;

  markers: mapboxgl.Marker[] = [];

  // the following are the editable things!
  selectedSite: SurveySite | null = null;

  startTimestamp: string | null = null;

  endTimestamp: string | null = null;

  get valid() {
    return !!this.selectedSite && !!this.startTimestamp && !!this.endTimestamp;
  }

  get unsavedChanges() {
    const selectedSiteId = this.selectedSite ? this.selectedSite.id : null;
    const surveySiteId = this.survey.surveySite
      ? this.survey.surveySite.id
      : null;

    return (
      selectedSiteId !== surveySiteId ||
      this.startTimestamp !== this.survey.startTimestamp ||
      this.endTimestamp !== this.survey.endTimestamp
    );
  }

  createMapElement() {
    const propertyCenter = center(this.property.geometry);
    const propertyBbox = bbox(this.property.geometry);
    this.map = new mapboxgl.Map({
      accessToken: mapboxToken,
      container: this.$refs.mapEl as HTMLElement,
      style: 'mapbox://styles/mapbox/light-v10',
      center: propertyCenter.geometry.coordinates as mapboxgl.LngLatLike,
      zoom: 15,
    });
    this.map.fitBounds(propertyBbox as mapboxgl.LngLatBoundsLike, {
      padding: 20,
    });
    this.map.on('click', () => {
      this.selectedSite = null;
      this.setActiveMarker();
    });
  }

  outlineMap() {
    if (!this.property || !this.map) return;
    (this.map as mapboxgl.Map).on('load', () => {
      // Add a data source containing GeoJSON data.
      (this.map as mapboxgl.Map).addSource('property', {
        type: 'geojson',
        data: feature(this.property.geometry),
      });

      // Add a new layer to visualize the polygon.
      (this.map as mapboxgl.Map).addLayer({
        id: 'fill',
        type: 'fill',
        source: 'property',
        layout: {},
        paint: {
          'fill-color': '#000000',
          'fill-opacity': 0.1,
        },
      });

      // Add a black outline around the polygon.
      (this.map as mapboxgl.Map).addLayer({
        id: 'outline',
        type: 'line',
        source: 'property',
        layout: {},
        paint: {
          'line-color': '#000',
          'line-dasharray': [2, 2],
          'line-width': 1,
        },
      });
    });
  }

  setSites() {
    if (!this.property || !this.map) return;
    const { map } = this;
    this.property.surveySites.forEach(site => {
      // Marker El
      const markerEl = document.createElement('div');
      markerEl.className = 'marker';

      // Marker
      const marker = new mapboxgl.Marker(markerEl)
        .setLngLat(site.location.coordinates as mapboxgl.LngLatLike)
        .addTo(map);

      // MarkerEl click
      markerEl.addEventListener('click', e => {
        e.stopPropagation();
        this.selectedSite = site;
        this.setActiveMarker(markerEl);
      });
      this.markers.push(marker);

      // set the initial site
      if (this.survey.surveySite && this.survey.surveySite.id === site.id) {
        this.selectedSite = site;
        this.setActiveMarker(markerEl);
      }
    });
  }

  setActiveMarker(markerEl?: HTMLElement) {
    this.markers.forEach(marker => {
      const el = marker.getElement();
      if (el === markerEl) {
        if (this.map) {
          this.map.panTo(marker.getLngLat(), { offset: [0, 100] });

          new mapboxgl.Popup({
            closeButton: false,
            anchor: 'bottom',
            maxWidth: '300px',
          })
            .setLngLat(marker.getLngLat())
            .setDOMContent(this.$refs.popup as HTMLElement)
            .addTo(this.map);
        }
        el.classList.add('marker--selected');
      } else {
        el.classList.remove('marker--selected');
      }
    });
  }

  async next() {
    try {
      // all the things are goood – lets save
      if (this.selectedSite && this.startTimestamp && this.endTimestamp) {
        this.survey.surveySite = new SurveySite({ id: this.selectedSite.id });
        this.survey.startTimestamp = this.startTimestamp;
        this.survey.endTimestamp = this.endTimestamp;
        await this.survey.save({ with: ['surveySite.id'] });
        await propertyModule.getProperty(this.property.id as string);
        await propertyModule.getPropertySurveyStats(this.property.id as string);
        snackModule.setSuccess('Survey saved');
        this.$emit('next');
      } else {
        // should not save
        snackModule.setError({
          text: 'Could not save, information is incomplete',
          errors: [],
        });
      }
    } catch (e) {
      snackModule.setError({
        text: this.$t('faunaTagGroups.alerts.notSaved') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  mounted() {
    this.createMapElement();
    this.outlineMap();
    this.setSites();

    // set the default bits
    this.startTimestamp = this.survey.startTimestamp;
    this.endTimestamp = this.survey.endTimestamp;
  }

  beforeDestroy() {
    if (this.map) this.map.remove();
  }
}
