
























































import { Component, Vue } from 'vue-property-decorator';
import SidebarInfo from '@/components/common/SidebarInfo.vue';

@Component({
  components: {
    SidebarInfo,
  },
})
export default class SidebarTiles extends Vue {}
