import { Vue } from 'vue-property-decorator';

import vuetify from '@/plugins/vuetify';

import AdminCsvExportDialog from '@/components/admin/AdminCsvExportDialog.vue';

const AdminCsvExportDialogClass = Vue.extend(AdminCsvExportDialog);

export default function csvExportDialog(): Promise<string | null> {
  return new Promise(resolve => {
    const dialog = new AdminCsvExportDialogClass({
      vuetify,
      propsData: {},
    });
    dialog.$on('close', () => {
      dialog.$destroy();
      resolve(null);
    });
    dialog.$mount();
  });
}
