






























































































import { Component, Vue, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/common/PageHeader.vue';
import NotFound from '@/components/common/NotFound.vue';

import propertyModule from '@/store/Property';

import { numberFormat } from '@/util';

@Component({
  components: { PageHeader, NotFound },
})
export default class PropertySingleView extends Vue {
  get propertyId() {
    return this.$route.params.propertyId;
  }

  get property() {
    return propertyModule.property(this.propertyId);
  }

  get propertySurveyCount() {
    return propertyModule.propertySurveyCount(this.propertyId);
  }

  get propertyFaunaCount() {
    return propertyModule.propertyFaunaCount(this.propertyId);
  }

  get propertyFavouriteCount() {
    return propertyModule.propertyFavouriteCount(this.propertyId);
  }

  get numberFormat() {
    return numberFormat;
  }

  isNotFound = false;

  refreshStats() {
    propertyModule.getPropertyTagStats(this.propertyId);
    propertyModule.getPropertySurveyStats(this.propertyId);
    propertyModule.getPropertyInvasiveStats(this.propertyId);
    propertyModule.getPropertyFavourites(this.propertyId);
  }

  @Watch('propertyId')
  routeChanged() {
    if (!this.isNotFound) {
      // this.refreshStats();
    }
  }

  @Watch('propertyId', { immediate: true })
  async propertyIdChanged() {
    try {
      await propertyModule.getProperty(this.propertyId);
      this.refreshStats();
    } catch (e) {
      this.isNotFound = true;
    }
  }
}
