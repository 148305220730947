














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: {} })
export default class ProgressDialog extends Vue {
  @Prop({ required: true })
  readonly title: string;

  @Prop({ required: true })
  readonly description: string;

  @Prop({ required: true })
  readonly value: number;
}
