











































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import PropertyListItem from '@/components/property/PropertyListItem.vue';
import { SurveyProperty } from '@/api';

import propertyModule from '@/store/Property';
import snackModule from '@/store/Snack';
import { debounce } from 'lodash';
import deleteDialog from '@/delete-dialog';

@Component({ components: { PageHeader, PropertyListItem } })
export default class PropertyList extends Vue {
  loading = false;

  total = 0;

  properties: SurveyProperty[] = [];

  itemsPerPage = 5;

  get search() {
    return (this.$route.query.search as string) || '';
  }

  set search(s: string) {
    this.$router.replace({
      query: {
        ...this.$route.query,
        search: s,
        page: undefined,
      },
    });
  }

  get page() {
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, page: p.toString() },
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  async doUpdate() {
    this.loading = true;
    try {
      const result = await propertyModule.getProperties(
        SurveyProperty.includes(['owner', 'surveySites', 'propertyUsers.user'])
          .where({ search: this.search })
          .order({ createdAt: 'desc' })
          .page(this.page)
          .per(this.itemsPerPage),
      );
      this.properties = result.data;
      this.total = result.meta.pagination.count;
    } catch (e) {
      this.properties = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(property: SurveyProperty) {
    const confirm = await deleteDialog(
      property.name,
      'property',
      property.name,
    );
    if (!confirm) {
      return;
    }
    try {
      const item = property.dup();
      await item.destroy();
      snackModule.setSuccess(`${item.name} deleted`);
      this.update();
    } catch (e) {
      snackModule.setError({
        text: 'Could not delete',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  mounted() {
    this.update();
  }

  @Watch('page')
  @Watch('search')
  optionsChanged() {
    this.update();
  }
}
