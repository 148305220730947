












































































import { PropertyUser, SurveyProperty } from '@/api';
import { Component, Vue, Prop } from 'vue-property-decorator';

import snackModule from '@/store/Snack';
import propertyModule from '@/store/Property';

import v8n from 'v8n';

@Component({ components: {} })
export default class InviteUserDialog extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ default: null }) readonly propertyUser: PropertyUser | null;

  value = true;

  loading = false;

  valid = false;

  item: PropertyUser;

  get show() {
    return this.value;
  }

  set show(v) {
    this.$emit('close');
    this.value = v;
  }

  get email() {
    return this.propertyUser ? this.item.user.email : this.item.email;
  }

  set email(s: string) {
    if (!this.propertyUser) {
      this.item.email = s;
    }
  }

  get title() {
    return this.propertyUser ? 'Manage user' : 'Invite a user';
  }

  emailRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || 'Email address required',

    (v: string) =>
      v8n()
        .pattern(/\S+@\S+\.\S+/)
        .test(v) || 'Email address must be valid',
  ];

  async removePropertyUser() {
    if (this.loading || !this.valid) {
      return;
    }

    try {
      this.loading = true;
      await this.item.destroy();
      await propertyModule.getProperty(this.property.id as string);
      snackModule.setSuccess('User removed successfully');
      this.show = false;
    } catch (e) {
      snackModule.setError({
        text: 'Could not remove user',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async saveChanges() {
    if (this.loading || !this.valid) {
      return;
    }
    const errorText = this.propertyUser
      ? 'Could not save changes'
      : 'Error sending invite';
    const successText = this.propertyUser
      ? 'Settings saved successfully'
      : `${this.item.email} has been sent an invite`;

    try {
      this.loading = true;
      await this.item.save({ with: 'surveyProperty.id' });
      await propertyModule.getProperty(this.property.id as string);
      snackModule.setSuccess(successText);
      this.show = false;
    } catch (e) {
      snackModule.setError({
        text: errorText,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  created() {
    if (this.propertyUser) {
      this.item = this.propertyUser.dup();
    } else {
      this.item = new PropertyUser({
        surveyProperty: this.property,
        canCreateSurvey: true,
        canAssessSurvey: true,
      });
    }
  }
}
