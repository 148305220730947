























































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/common/PageHeader.vue';
import EmptyItem from '@/components/common/EmptyItem.vue';
import AvatarUpload from '@/components/common/AvatarUpload.vue';
import TiptapEditor from '@/components/tiptap/TiptapEditor.vue';

import v8n from 'v8n';

import { FaunaTag } from '@/api/';

import snackModule from '@/store/Snack';
import cacheModule from '@/store/Cache';

@Component({
  components: { PageHeader, EmptyItem, AvatarUpload, TiptapEditor },
})
export default class AdminFaunaTagsSingle extends Vue {
  /* Attributes */
  title: string;

  item: FaunaTag | null = null;

  notFound = false;

  loading = false;

  valid = false;

  /* Form validations */
  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  optionalString = [
    (v: string) =>
      v8n()
        .optional(v8n().string(), true)
        .test(v),
  ];

  /* Getter & setter */
  get itemId() {
    return this.$route.params.itemId;
  }

  get isNewItem() {
    return this.itemId === undefined;
  }

  get faunaTagGroups() {
    return cacheModule.faunaTagGroups;
  }

  get contentEditor() {
    return this.$refs.contentEditor as TiptapEditor;
  }

  /* Functions */
  async save() {
    if (!this.item) return;
    try {
      this.loading = true;
      this.item.description = this.contentEditor.getHTML();
      await this.item.save({ with: ['faunaTagGroup.id'] });
      this.$emit('updated', this.item);
      snackModule.setSuccess(this.$t('common.alerts.success') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('faunaTags.alerts.notSaved') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('itemId', { immediate: true })
  async itemIdChanged() {
    if (this.isNewItem) {
      this.title = 'New Fauna Tag';
      this.item = new FaunaTag({ diseaseStatusRequired: false });
    } else {
      this.title = 'Edit Fauna Tag';
      try {
        this.item =
          (await FaunaTag.includes(['faunaTagGroup']).find(this.itemId)).data ||
          null;
      } catch (e) {
        this.notFound = true;
      }
    }
  }

  mounted() {
    cacheModule.getFaunaTagsAndGroups();
  }
}
