























import { Component, Vue, VModel, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';

@Component({})
export default class DatePicker extends Vue {
  @VModel() date: string | null;

  @Prop({ default: false }) endOfDay: boolean;

  menu = false;

  get formattedDate() {
    if (!this.date) {
      return null;
    }
    return dayjs(this.date).format('DD/MM/YYYY');
  }

  get vDate() {
    if (!this.date) {
      return null;
    }
    return dayjs(this.date).format('YYYY-MM-DD');
  }

  set vDate(s: string | null) {
    this.menu = false;
    if (s !== this.vDate) {
      this.date = this.endOfDay
        ? `${
            dayjs(s)
              .endOf('day')
              .toISOString()
              .split('.')[0]
          }Z`
        : `${
            dayjs(s)
              .startOf('day')
              .toISOString()
              .split('.')[0]
          }Z`;
    }
  }
}
