























import { Component, Prop, Vue } from 'vue-property-decorator';

import Cookies from 'js-cookie';
import VueDropzone from 'vue2-dropzone';
import { JsonapiError } from 'spraypaint';

const previewTemplate = `
  <div class="dz-preview dz-file-preview">
    <div class="dz-thumbnail">
      <img data-dz-thumbnail />
    </div>
    <div class="dz-preview-inner">
      <div class="dz-remove" data-dz-remove>&times;</div>
      <div class="dz-size" data-dz-size></div>
      <div class="dz-filename">
        <strong data-dz-name></strong>
      </div>
      <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
      <div class="dz-error" data-dz-errormessage></div>
    </div>
  </div>
`;

interface DropzoneInterface {
  addFile: (file: unknown) => void;
  removeFile: (file: unknown) => void;
  processQueue: () => void;
  getQueuedFiles: () => unknown[];
}

interface VueDropzoneInterface {
  dropzone: DropzoneInterface;
  setOption: (option: string, value: unknown) => void;
}

@Component({
  components: {
    VueDropzone,
  },
})
export default class extends Vue {
  @Prop({ required: true }) readonly dropzoneOptions: object;

  get options() {
    return {
      ...this.dropzoneOptions,
      previewTemplate,
      headers: {
        'Accept': 'application/vnd.api+json',
        'X-CSRFTOKEN': Cookies.get('csrftoken'),
      },
    };
  }

  get dz(): VueDropzoneInterface {
    return this.$refs.dz as never;
  }

  get dropzone(): DropzoneInterface {
    return this.dz.dropzone;
  }

  cancelRemaining() {
    const failedItems: Array<{ file: any; errors: JsonapiError[] }> = [];
    const queued = this.dropzone.getQueuedFiles();
    queued.forEach(item => {
      this.dropzone.removeFile(item);
      failedItems.push({
        file: item,
        errors: [{ detail: 'Cancelled by user', meta: {} }],
      });
    });
    return failedItems;
  }

  errorFiles: object[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileAdded(...args: any[]) {
    this.$emit('fileAdded', { ...args });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filesAdded(...args: any[]) {
    this.$emit('filesAdded', { ...args });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileSending(...args: any[]) {
    this.$emit('fileSending', { ...args });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileUploaded(...args: any[]) {
    this.$emit('fileUploaded', { ...args });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileError(...args: any[]) {
    this.$emit('fileError', { ...args });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileRemoved(...args: any[]) {
    this.$emit('fileRemoved', { ...args });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queueComplete(...args: any[]) {
    this.$emit('queueComplete', { ...args });
  }
}
