




import { Component, Vue, Prop } from 'vue-property-decorator';
import PropertyMedia from '@/components/property/PropertyMedia.vue';
import { SurveyProperty } from '@/api';
import { NavigationGuardNext, RawLocation, Route } from 'vue-router';

@Component({ components: { PropertyMedia } })
export default class PropertyTags extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    if (to.query['has-tags'] === undefined) {
      next({
        ...to,
        query: { ...to.query, 'has-tags': 'true' },
      } as RawLocation);
    } else {
      next();
    }
  }
}
