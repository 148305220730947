



























































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { SurveyProperty, FaunaSurvey, FaunaMedia } from '@/api';

import MediaDropzone from '@/components/media/MediaDropzone.vue';
import MediaBrowser from '@/components/media/MediaBrowser.vue';

import { throttle } from 'lodash';
import dayjs from 'dayjs';
import confirmDialog from '@/confirm-dialog';

import authModule from '@/store/Auth';
import { JsonapiError } from 'spraypaint';

import imageCompression from 'browser-image-compression';

interface FailedItem {
  file: any;
  errors: JsonapiError[];
}

@Component({
  components: {
    MediaDropzone,
    MediaBrowser,
  },
})
export default class SurveyStep2 extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly survey: FaunaSurvey;

  fakeExif = false;

  skipTimestampValidation = false;

  timestampErrors: FailedItem[] = [];

  failedItems: FailedItem[] = [];

  get isAdmin() {
    return authModule.isAdmin;
  }

  get valid() {
    return this.survey.faunaMediaCount > 0;
  }

  get params() {
    return () => {
      const faunaSurvey = JSON.stringify({
        type: 'fauna-surveys',
        id: this.survey.id,
      });
      const result: {
        fauna_survey: string;
        timestamp?: string;
        skip_validate_timestamp?: boolean;
      } = {
        fauna_survey: faunaSurvey,
      };
      if (this.fakeExif) {
        result.timestamp = dayjs(this.survey.startTimestamp).toISOString();
      }
      if (this.skipTimestampValidation) {
        result.skip_validate_timestamp = true;
      }
      return result;
    };
  }

  get mediaDropzone() {
    return this.$refs.dz as MediaDropzone;
  }

  get dropzoneOptions() {
    return {
      url: FaunaMedia.url(),
      addRemoveLinks: false,
      createImageThumbnails: false,
      resizeHeight: 2000,
      paramName: 'image',
      autoProcessQueue: true,
      timeout: 60000,
      params: this.params,
      transformFile: (file: File, done: any): void => {
        if (!file.type.match(/image.*/)) {
          done(file);
        }
        imageCompression(file, {
          maxWidthOrHeight: 2000,
          preserveExif: true,
        }).then(resizedFile => done(resizedFile));
      },
    };
  }

  get scopeFactory() {
    return () =>
      FaunaMedia.includes('media')
        .where({ faunaSurvey: this.survey.id })
        .order({ timestamp: 'desc' });
  }

  get getItemsThrottle() {
    return throttle(async () => {
      (this.$refs.mediaBrowser as MediaBrowser).getItems();
    }, 5000);
  }

  get hasTimestampErrors() {
    return this.timestampErrors.length > 0;
  }

  fileUploaded(args: unknown[]) {
    this.mediaDropzone.dropzone.removeFile(args[0]);
    this.getItemsThrottle();
  }

  fileError(args: unknown[]) {
    try {
      this.mediaDropzone.dropzone.removeFile(args[0]);
      const error = JSON.parse(args[1] as string) as { errors: JsonapiError[] };

      if (
        error.errors.some(e => e.code === 'timestamp') &&
        this.skipTimestampValidation === false
      ) {
        this.timestampErrors.push({
          file: args[0],
          errors: error.errors,
        });
      } else {
        this.failedItems.push({
          file: args[0],
          errors: error.errors,
        });
      }
    } catch (e) {
      // not a JSON error
      console.log(args);
      this.failedItems.push({
        file: args[0],
        errors: [
          {
            code: 'unknown',
            detail: `Unknown error: ${(args[0] as any).xhr.status} – ${
              (args[0] as any).xhr.statusText
            }`,
            meta: {},
          },
        ],
      });
    }
  }

  clearFailed(item: FailedItem) {
    const i = this.failedItems.indexOf(item);
    if (i !== -1) {
      this.failedItems.splice(i, 1);
    }
  }

  retryFailed(item: FailedItem) {
    this.clearFailed(item);
    this.mediaDropzone.dropzone.addFile(item.file);
  }

  clearAllFailed() {
    this.failedItems = [];
  }

  retryAllFailed() {
    this.failedItems.forEach(item =>
      this.mediaDropzone.dropzone.addFile(item.file),
    );
    this.clearAllFailed();
  }

  async queueComplete() {
    //
  }

  updateCount(count: number) {
    this.survey.faunaMediaCount = count;
  }

  back() {
    this.$emit('back');
  }

  next() {
    this.$emit('next');
  }

  @Watch('hasTimestampErrors')
  async hasTimestampErrorsChanged(newVal: boolean, oldVal: boolean) {
    if (newVal && !oldVal) {
      this.mediaDropzone.dz.setOption('autoProcessQueue', false);

      const confirm = await confirmDialog({
        title: 'Timestamp out of range',
        description: `We've detected photos with a timestamp outside the range of the current survey. Are sure you would like to continue?`,
        buttons: [
          {
            key: 'confirm',
            title: 'Upload anyway',
            color: 'primary',
            outlined: true,
          },
          {
            key: 'cancel',
            title: 'Stop uploading',
            color: 'primary',
            outlined: false,
          },
        ],
      });
      if (confirm === 'confirm') {
        this.skipTimestampValidation = true;

        this.timestampErrors.forEach(errorFile => {
          this.mediaDropzone.dropzone.removeFile(errorFile.file);
          this.mediaDropzone.dropzone.addFile(errorFile.file);
        });
        this.timestampErrors = [];

        this.mediaDropzone.dz.setOption('autoProcessQueue', true);
        this.mediaDropzone.dropzone.processQueue();
      } else {
        const cancelled = this.mediaDropzone.cancelRemaining();
        this.failedItems.push(...this.timestampErrors);
        this.failedItems.push(...cancelled);
        this.timestampErrors = [];
        this.mediaDropzone.dz.setOption('autoProcessQueue', true);
      }
    }
  }
}
