






















































import { Component, Vue, Watch } from 'vue-property-decorator';
import authModule from '@/store/Auth';

import SidebarInfo from '@/components/common/SidebarInfo.vue';

@Component({
  components: {
    SidebarInfo,
  },
})
export default class LoginForm extends Vue {
  email = '';

  password = '';

  loading = false;

  valid = false;

  error = '';

  get isLoggedIn(): boolean {
    return authModule.isLoggedIn;
  }

  get nextQuery(): string {
    return typeof this.$route.query.next === 'string'
      ? this.$route.query.next
      : '';
  }

  async login() {
    this.loading = true;
    try {
      await authModule.login({ email: this.email, password: this.password });
    } catch (e) {
      this.error = e.response.errors[0].detail;
    } finally {
      this.loading = false;
    }
  }

  forgotPassword() {
    this.$router.push({ name: 'forgot-password' });
  }

  @Watch('isLoggedIn', { immediate: true })
  isLoggedInChange() {
    if (this.isLoggedIn) {
      const location = this.nextQuery || { name: 'dashboard' };
      this.$router.replace(location).catch(() => {
        // catching in case next query is overriden in global hook
      });
    }
  }
}
