

















































































import { Component, Prop, Vue } from 'vue-property-decorator';

import dayjs from 'dayjs';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import StatTile from '@/components/common/StatTile.vue';
import GetStartedSurvey from '@/components/common/GetStartedSurvey.vue';
import LazyImg from '@/components/common/LazyImg.vue';

import { SurveySite } from '@/api';
import SiteThumb from '@/components/common/SiteThumb.vue';
import FaunaStatsLines from '@/components/property/FaunaStatsLines.vue';

import authModule from '@/store/Auth';
import propertyModule from '@/store/Property';
import { numberFormat } from '@/util';

@Component({
  components: {
    TableMenuButton,
    SiteThumb,
    GetStartedSurvey,
    FaunaStatsLines,
    StatTile,
    LazyImg,
  },
})
export default class SiteListItem extends Vue {
  @Prop({ required: true }) readonly site: SurveySite;

  @Prop({ default: false }) readonly showOptions: boolean;

  native = 0;

  get doShowOptions() {
    return this.showOptions && authModule.isAdmin;
  }

  get siteNativeCount() {
    return propertyModule.siteNativeCount(this.site.id as string);
  }

  get siteSurveyCount() {
    return propertyModule.siteSurveyCount(this.site.id as string);
  }

  get siteFaunaCount() {
    return propertyModule.siteFaunaCount(this.site.id as string);
  }

  get siteFavouriteCount() {
    return propertyModule.siteFavouriteCount(this.site.id as string);
  }

  get formattedCreatedAt() {
    return dayjs(this.site.createdAt).format('MMMM D, YYYY');
  }

  get siteNativeText() {
    return `${numberFormat(this.siteNativeCount)} native`;
  }

  refreshStats() {
    propertyModule.getSiteInvasiveStats(this.site.id as string);
    propertyModule.getSiteSurveyStats(this.site.id as string);
    propertyModule.getPropertyFavourites(this.site.surveyProperty.id as string);
  }
}
