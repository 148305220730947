














































import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import { Scope } from 'spraypaint';
import { ApplicationRecord } from '@/api';
import { debounce } from 'lodash';

import snackModule from '@/store/Snack';

@Component({
  components: {},
})
export default class AbstractTable extends Vue {
  // eg [{ value: 'id', text: 'ID' }]
  @Prop({ required: true }) readonly headers: object[];

  @Prop({ default: false }) readonly singleSelect: boolean;

  @Prop({ default: true }) readonly showSelect: boolean;

  // eg () => User.includes(['someFields']).where({ project: 1 });
  @Prop({ required: true }) readonly scopeFactory: () => Scope<
    ApplicationRecord
  >;

  @VModel() selected: ApplicationRecord[];

  loading = false;

  total = 0;

  items: ApplicationRecord[] = [];

  itemsPerPage = 20;

  get page() {
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router
      .replace({
        query: { ...this.$route.query, page: p.toString() },
      })
      .catch(
        // already at route
        () => false,
      );
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  // debounced version of update fn
  get update() {
    return debounce(this.doUpdate, 300);
  }

  itemClicked(item: ApplicationRecord) {
    this.$emit('item-clicked', item);
  }

  async doUpdate() {
    this.loading = true;
    try {
      // initial scope + page + pageSize
      const scope = this.scopeFactory()
        .page(this.page)
        .per(this.itemsPerPage);

      // get result
      const result = await scope.all();
      this.items = result.data;
      this.total = result.meta.pagination.count;
    } catch (e) {
      snackModule.setError({
        text: 'Error occured',
        errors: e.response.errors,
      });
      this.items = [];
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.update();
  }

  // only watch things that this component changes
  @Watch('page')
  pageChanged() {
    this.update();
  }
}
