












































import { PropertyUser, SurveyProperty } from '@/api';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import AbstractTable from '@/components/common/AbstractTable.vue';
import v8n from 'v8n';
import snackModule from '@/store/Snack';
import authModule from '@/store/Auth';
import { isEqual } from 'lodash';
import inviteUserDialog from '@/invite-user-dialog';

@Component({ components: { AbstractTable } })
export default class PropertyUsers extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  item: PropertyUser | null = null;

  dialog = false;

  isNewItem = false;

  valid = false;

  loading = false;

  get canManageUsers() {
    return (
      (authModule.user && authModule.user.id === this.property.owner.id) ||
      authModule.isAdmin
    );
  }

  get search() {
    return (this.$route.query.search as string) || '';
  }

  set search(s: string) {
    this.$router.replace({
      query: {
        ...this.$route.query,
        search: s,
        page: undefined,
      },
    });
  }

  /* Form validations */
  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || 'This field is required.',
  ];

  get headers() {
    return PropertyUser.listHeaders();
  }

  get surveyPropertyId() {
    return this.$route.params.propertyId;
  }

  get dialogTitle() {
    return this.isNewItem ? 'New User' : 'Edit User';
  }

  get scopeFactory() {
    return () =>
      PropertyUser.includes(['user'])
        .where({
          surveyProperty: this.surveyPropertyId,
        })
        .where({ search: this.search });
  }

  async save() {
    if (!this.item) return;
    try {
      this.loading = true;
      await this.item.save({ with: ['surveyProperty.id'] });
      this.$emit('updated', this.item);
      snackModule.setSuccess('Changes Saved');
    } catch (e) {
      snackModule.setError({
        text: 'Failed to save changes',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
      this.dialog = false;
      (this.$refs.table as AbstractTable).update();
    }
  }

  async deleteItem() {
    if (!this.item) return;
    try {
      this.loading = true;
      await this.item.destroy();
      (this.$refs.table as AbstractTable).update();
      snackModule.setSuccess('User removed from property');
    } catch (e) {
      snackModule.setError({
        text: 'Failed to remove user from property',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
      this.dialog = false;
      (this.$refs.table as AbstractTable).update();
    }
  }

  async editUser(item: PropertyUser) {
    await inviteUserDialog(this.property, item);
    (this.$refs.table as AbstractTable).update();
  }

  async newUser() {
    await inviteUserDialog(this.property, null);
    (this.$refs.table as AbstractTable).update();
  }

  @Watch('search')
  searchChanged(current: object, prev: object) {
    if (!isEqual(current, prev)) {
      (this.$refs.table as AbstractTable).update();
    }
  }
}
