
















import { Component, Vue, Prop } from 'vue-property-decorator';
import LineIndicator from '@/components/common/LineIndicator.vue';
import Stepper from '@/components/common/Stepper.vue';

@Component({
  components: {
    LineIndicator,
  },
})
export default class StatTile extends Vue {
  @Prop({ required: true }) readonly title: string;

  @Prop({ required: false }) readonly subtitle: string;

  @Prop({ default: false }) readonly disabled: boolean;

  get stepper() {
    if (
      !this.$parent.$vnode.componentOptions ||
      this.$parent.$vnode.componentOptions.tag !== 'stepper'
    ) {
      throw new Error('Expected Stepper parent for StepperItem');
    }
    return this.$parent as Stepper;
  }

  get selected() {
    return this.stepper.selected;
  }

  activate() {
    if (!this.disabled) {
      this.stepper.setStep(this.$vnode.key);
    }
  }
}
