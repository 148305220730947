



































import { Component, Vue, Prop } from 'vue-property-decorator';
import LineIndicator from '@/components/common/LineIndicator.vue';

import { FaunaSurvey, FaunaStats, SurveyProperty, SurveySite } from '@/api';
import propertyModule from '@/store/Property';

import { numberFormat } from '@/util';

@Component({ components: { LineIndicator } })
export default class UserStatsLines extends Vue {
  @Prop({ default: null }) readonly property: SurveyProperty | null;

  @Prop({ default: null }) readonly site: SurveySite | null;

  @Prop({ default: null }) readonly survey: FaunaSurvey | null;

  faunaStats: FaunaStats[] = [];

  fullList = false;

  get title() {
    if (this.property) {
      return 'Top taggers for this property';
    }
    if (this.site) {
      return 'Top taggers for this site';
    }
    if (this.survey) {
      return 'Tog taggers for this survey';
    }
    return 'Top taggers';
  }

  get numberFormat() {
    return numberFormat;
  }

  get tags() {
    return this.faunaStats
      .filter(fs => fs.taggedBy)
      .map(fs => {
        const id = (fs.taggedBy as number).toString();
        const user = this.propertyUsers.find(u => u.id === id);
        return user
          ? {
              id: user.id,
              name: user.name,
              picture: user.avatarUrl,
              value: fs.count,
            }
          : {
              name: 'Unknown',
              picture: '',
              value: fs.count,
            };
      });
  }

  get shownTags() {
    return this.fullList ? this.tags : this.tags.slice(0, 3);
  }

  get max() {
    return this.tags.reduce((sum, item) => sum + item.value, 0);
  }

  get propertyUsers() {
    const propertyId = this.property
      ? this.property.id
      : this.$route.params.propertyId;

    return propertyId ? propertyModule.propertyUsers(propertyId) : [];
  }

  async mounted() {
    let query: object;
    if (this.survey) {
      query = {
        fauna_survey__in: this.survey.id,
        group_by: ['fauna_survey', 'tagged_by'],
      };
    } else if (this.site) {
      query = {
        survey_site__in: this.site.id,
        group_by: ['survey_site', 'tagged_by'],
      };
    } else if (this.property) {
      query = {
        survey_property__in: this.property.id,
        group_by: ['tagged_by'],
      };
    } else {
      query = {
        group_by: ['tagged_by'],
      };
    }
    this.faunaStats = (await FaunaStats.where(query).all()).data;
  }
}
