




import { Prop, Component, Vue } from 'vue-property-decorator';
import { SurveyProperty } from '@/api';

import {
  select as d3Select,
  geoPath as d3GeoPath,
  geoMercator as d3GeoMercator,
} from 'd3';

import { rewind } from '@turf/turf';
import { getPropertyCollection } from '@/util';

@Component({ components: {} })
export default class PropertyOutline extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  mounted() {
    const w = 300;
    const h = 300;
    const padding = 32;

    const propertyFeatures = rewind(getPropertyCollection(this.property), {
      reverse: true,
    });
    const propertyOutline = rewind(this.property.geometry, { reverse: true });
    const surveySites = this.property.surveySites.map(
      site => site.location.coordinates as [number, number],
    );

    const projection = d3GeoMercator().fitExtent(
      [
        [padding, padding],
        [w - padding, h - padding],
      ],
      propertyFeatures as never,
    );
    const geopath = d3GeoPath().projection(projection);
    const svg = d3Select(this.$el)
      .append('svg')
      .attr('viewBox', `0 0 ${w} ${h}`);

    svg
      .append('path')
      .attr('class', 'outline')
      .datum(propertyOutline)
      .attr('d', geopath);

    svg
      .selectAll('circle')
      .data(surveySites)
      .enter()
      .append('circle')
      .attr('cx', d => (projection(d) as [number, number])[0])
      .attr('cy', d => (projection(d) as [number, number])[1])
      .attr('r', '4px')
      .attr('class', 'point');
  }
}
