



















































































































import { Component, Vue } from 'vue-property-decorator';
import HeroBanner from '@/components/common/HeroBanner.vue';
import SidebarInfo from '@/components/common/SidebarInfo.vue';
import EoiForm from '@/components/auth/EoiForm.vue';

@Component({
  components: {
    HeroBanner,
    SidebarInfo,
    EoiForm,
  },
})
export default class Questions extends Vue {
  get imageUrl() {
    return '/getinvolved.jpg';
  }
}
