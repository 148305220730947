import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import { mapboxToken } from '@/config';
import { bbox, Feature, feature, featureCollection } from '@turf/turf';

import { SurveyProperty } from '@/api';

export function numberFormat(num: number) {
  return new Intl.NumberFormat('en-AU').format(num);
}

export function createMapElement(container: HTMLElement) {
  return new mapboxgl.Map({
    accessToken: mapboxToken,
    container,
    style: 'mapbox://styles/mapbox/light-v10',
    center: [146.6, -41.7],
    zoom: 6,
  });
}

export function getPropertyCollection(property: SurveyProperty) {
  const sites = property.surveySites.map(site => feature(site.location));
  return featureCollection([feature(property.geometry) as Feature, ...sites]);
}

export function getPropertyBounds(property: SurveyProperty) {
  const sites = property.surveySites.map(site => feature(site.location));
  const collection = featureCollection([
    feature(property.geometry) as Feature,
    ...sites,
  ]);
  const bounds = bbox(collection);
  return bounds as LngLatBoundsLike;
}

export function outlineProperty(map: mapboxgl.Map, property: SurveyProperty) {
  if (map.getLayer('fill')) {
    map.removeLayer('fill');
  }
  if (map.getLayer('outline')) {
    map.removeLayer('outline');
  }
  if (map.getSource('property')) {
    map.removeSource('property');
  }

  map.addSource('property', {
    type: 'geojson',
    data: feature(property.geometry),
  });

  // Add a new layer to visualize the polygon.
  map.addLayer({
    id: 'fill',
    type: 'fill',
    source: 'property',
    layout: {},
    paint: {
      'fill-color': '#000000',
      'fill-opacity': 0.1,
    },
  });

  // Add a black outline around the polygon.
  map.addLayer({
    id: 'outline',
    type: 'line',
    source: 'property',
    layout: {},
    paint: {
      'line-color': '#000',
      'line-dasharray': [2, 2],
      'line-width': 1,
    },
  });
}
