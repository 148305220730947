
























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import EmptyItem from '@/components/common/EmptyItem.vue';
import OutlineEditor from '@/components/admin/OutlineEditor.vue';
import OwnerAutocomplete from '@/components/admin/OwnerAutocomplete.vue';

import v8n from 'v8n';
import { SurveyProperty, User } from '@/api/';

import snackModule from '@/store/Snack';
import cacheModule from '@/store/Cache';

@Component({
  components: { PageHeader, EmptyItem, OutlineEditor, OwnerAutocomplete },
})
export default class AdminPropertiesSingle extends Vue {
  /* Attributes */
  title: string;

  item: SurveyProperty | null = null;

  users: User[] = [];

  notFound = false;

  loading = false;

  valid = false;

  /* Form validations */
  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  optionalString = [
    (v: string) =>
      v8n()
        .optional(v8n().string(), true)
        .test(v),
  ];

  /* Getter & setter */
  get itemId() {
    return this.$route.params.itemId;
  }

  get isNewItem() {
    return this.itemId === undefined;
  }

  get focalLandscapes() {
    return cacheModule.focalLandscapes;
  }

  get outlineEditor() {
    return this.$refs.outlineEditor as OutlineEditor;
  }

  async getUsers() {
    try {
      this.users = (await User.select(['id', 'email']).all()).data || [];
    } catch (e) {
      snackModule.setError({
        text: this.$t('surveyProperties.alerts.couldNotGetUsers') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  /* Functions */
  async save() {
    if (!this.item) return;

    const multiPoly = this.outlineEditor.getMultiPoly();
    if (!multiPoly) {
      snackModule.setError({
        text: 'Must provide geometry',
        errors: [],
      });
      return;
    }
    this.item.geometry = multiPoly.geometry;

    try {
      this.loading = true;
      if (this.item.owner && !this.item.owner.isPersisted) {
        await this.item.owner.save();
      }
      await this.item.save({ with: ['focalLandscape.id', 'owner.id'] });
      this.$emit('updated', this.item);
      snackModule.setSuccess(this.$t('common.alerts.success') as string);
      this.$router
        .replace({
          name: 'admin-properties-edit',
          params: { itemId: this.item.id as string },
        })
        .catch(() => {
          // already there
        });
    } catch (e) {
      snackModule.setError({
        text: this.$t('surveyProperties.alerts.notSaved') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('itemId', { immediate: true })
  async itemIdChanged() {
    if (this.isNewItem) {
      this.title = 'New Property';
      this.item = new SurveyProperty();
    } else {
      this.title = 'Edit Property';
      try {
        this.item =
          (
            await SurveyProperty.includes(['owner', 'focal_landscape']).find(
              this.itemId,
            )
          ).data || null;
      } catch (e) {
        this.notFound = true;
      }
    }
  }

  mounted() {
    this.getUsers();
    cacheModule.getFocalLandscapes();
  }
}
