





















import { Watch, Component, Prop, Vue } from 'vue-property-decorator';

import Cookies from 'js-cookie';
import VueDropzone from 'vue2-dropzone';

import imageCompression from 'browser-image-compression';

const previewTemplate = `
  <div class="dz-preview">
    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  </div>
`;

@Component({
  components: {
    VueDropzone,
  },
})
export default class extends Vue {
  @Prop({ required: true }) readonly dropzoneOptions: any;

  @Prop({ default: false }) readonly disabled: boolean;

  get options() {
    const headers =
      process.env.VUE_APP_USE_COOKIES === 'true'
        ? {
            'Accept': 'application/vnd.api+json',
            'X-CSRFTOKEN': Cookies.get('csrftoken'),
          }
        : {
            'Accept': 'application/vnd.api+json',
            'Authorization': `Token ${Cookies.get('token')}`,
            'X-CSRFTOKEN': Cookies.get('csrftoken'),
          };

    return {
      ...this.dropzoneOptions,
      previewTemplate,
      headers,
      transformFile: (file: File, done: any): void => {
        if (!file.type.match(/image.*/)) {
          done(file);
        }
        imageCompression(file, {
          maxWidthOrHeight: 800,
          preserveExif: true,
        }).then(resizedFile => done(resizedFile));
      },
    };
  }

  get dropzone() {
    return (this.$refs.dropzone as unknown) as {
      removeFile: (file: unknown) => void;
      disable: () => void;
      enable: () => void;
    };
  }

  fileAdded() {
    this.$emit('fileAdded');
  }

  filesAdded() {
    this.$emit('filesAdded');
  }

  fileSending() {
    this.$emit('fileSending');
  }

  fileError(file: unknown, message: string, response: unknown) {
    this.$emit('fileError', { file, message, response });
    this.dropzone.removeFile(file);
  }

  fileUploaded(file: unknown, response: unknown) {
    this.$emit('fileUploaded', { file, response });
    this.dropzone.removeFile(file);
  }

  fileRemoved() {
    this.$emit('fileRemoved');
  }

  queueComplete() {
    this.$emit('queueComplete');
  }

  setDisabled() {
    if (this.disabled) {
      this.dropzone.disable();
    } else {
      this.dropzone.enable();
    }
  }

  mounted() {
    this.setDisabled();
  }

  @Watch('disabled')
  disabledChanged() {
    this.setDisabled();
  }
}
