import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#1E4536', // olive
        secondary: '#C23C15', // red
        pink: '#F99887',
        brown: '#655B51',
        lime: '#61988E',
      },
    },
  },
});
