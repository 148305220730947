var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-thumb"},[_c('lazy-img',{attrs:{"aspect":"1.2","src":_vm.floraImage}}),_c('single-upload',{attrs:{"dropzoneOptions":_vm.dropzoneOptions,"disabled":_vm.disableUpload},on:{"fileError":_vm.uploadError,"fileUploaded":_vm.uploadSuccess}},[(!_vm.floraSurveys.length)?_c('div',{staticClass:"site-thumb__no-images"},[_c('div',{staticClass:"grey--text text--small"},[_c('div',[_vm._v(" No Images ")]),(!_vm.disableUpload)?_c('div',[_vm._v(" Click to upload ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"site-thumb__controls form-horizontal-gap-small"},[_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.setIndex(_vm.index - 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1)]}}])},[_vm._v(" Newer Image ")]),(!_vm.disableUpload)?_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,false,1009738931)},[_vm._v(" Upload ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.setIndex(_vm.index + 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-arrow-right ")])],1)]}}])},[_vm._v(" Older Image ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }