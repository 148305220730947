







































import { SurveyProperty } from '@/api';
import { Component, Vue, Prop } from 'vue-property-decorator';

import authModule from '@/store/Auth';

import inviteUserDialog from '@/invite-user-dialog';

@Component({
  components: {},
})
export default class SidebarTasks extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get canManageUsers() {
    return (
      (authModule.user && authModule.user.id === this.property.owner.id) ||
      authModule.isAdmin
    );
  }

  async inviteUser() {
    await inviteUserDialog(this.property, null);
  }
}
