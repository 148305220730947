























import { Component, Vue } from 'vue-property-decorator';
import { Program } from '@/api';
import PageHeader from '@/components/common/PageHeader.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';

@Component({ components: { PageHeader, AbstractTable } })
export default class AdminProgramsList extends Vue {
  get headers() {
    return Program.listHeaders();
  }

  get scopeFactory() {
    return Program.scopeFactory;
  }

  click(item: Program) {
    this.$router.push({
      name: 'admin-programs-edit',
      params: { itemId: item.id as string },
    });
  }
}
