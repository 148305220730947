

















































































































































































import v8n from 'v8n';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class EoiForm extends Vue {
  hasCamera = false;

  hasPhone = false;

  prevExperience = false;

  newsletterSubscribe = false;

  valid = false;

  error = '';

  get returnUrl() {
    const url = new URL('/eoi-complete', window.location.href);
    return url.href;
  }

  emailRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.emailRequired'),

    (v: string) =>
      v8n()
        .pattern(/\S+@\S+\.\S+/)
        .test(v) || this.$t('users.validation.emailValid'),
  ];

  passwordRules = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('users.validation.passwordRequired'),
    (v: string) =>
      v8n()
        .string()
        .minLength(8)
        .test(v) || this.$t('users.validation.passwordLength'),
    (v: string) =>
      v8n()
        .not.numeric()
        .minLength(8)
        .test(v) || this.$t('users.validation.passwordNonNumeric'),
  ];

  requiredBoolean = [
    (v: boolean) =>
      v8n()
        .boolean()
        .exact(true)
        .test(v) || this.$t('register.validation.generalRequired'),
  ];

  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];
}
