























import { Component, Vue } from 'vue-property-decorator';
import { FocalLandscape } from '@/api';
import PageHeader from '@/components/common/PageHeader.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';

@Component({ components: { PageHeader, AbstractTable } })
export default class AdminFocalLandscapesList extends Vue {
  get headers() {
    return FocalLandscape.listHeaders();
  }

  get scopeFactory() {
    return FocalLandscape.scopeFactory;
  }

  click(item: FocalLandscape) {
    this.$router.push({
      name: 'admin-focal-landscapes-edit',
      params: { itemId: item.id as string },
    });
  }
}
