import { Model, Attr, BelongsTo, HasOne } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import User from '../auth/User';
import FaunaMedia from './FaunaMedia';
import FaunaTag from './FaunaTag';

export enum DiseaseStatus {
  healthy = 'healthy',
  diseased = 'diseased',
  unsure = 'unsure',
}

export enum ConfidenceLevel {
  // eslint-disable-next-line @typescript-eslint/camelcase
  very_low = 0,
  low = 1,
  medium = 2,
  high = 3,
  // eslint-disable-next-line @typescript-eslint/camelcase
  very_high = 4,
}

@Model()
export default class FaunaMediaTag extends ApplicationRecord {
  static jsonapiType = 'fauna-media-tags';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
    ];
  }

  static scopeFactory() {
    return FaunaMediaTag.includes([]);
  }

  @Attr() createdAt: string;

  @Attr() diseaseStatus: DiseaseStatus; // do something

  @Attr() confidenceLevel: ConfidenceLevel;

  @BelongsTo() createdBy: User;

  @BelongsTo() faunaMedia: FaunaMedia;

  @HasOne() faunaTag: FaunaTag;

  get diseaseStatusText() {
    switch (this.diseaseStatus) {
      case DiseaseStatus.healthy:
        return '(Healthy)';
      case DiseaseStatus.diseased:
        return '(Diseased)';
      case DiseaseStatus.unsure:
        return '(Disease unsure)';
      default:
        return '';
    }
  }

  get confidenceText() {
    switch (this.confidenceLevel) {
      case ConfidenceLevel.very_low:
        return 'Very Low';
      case ConfidenceLevel.low:
        return 'Low';
      case ConfidenceLevel.medium:
        return 'Medium';
      case ConfidenceLevel.high:
        return 'High';
      case ConfidenceLevel.very_high:
        return 'Very High';
      default:
        return 'Unknown';
    }
  }

  get confidenceIcon() {
    switch (this.confidenceLevel) {
      case ConfidenceLevel.very_low:
        return 'mdi-circle-outline';
      case ConfidenceLevel.low:
        return 'mdi-circle-slice-2';
      case ConfidenceLevel.medium:
        return 'mdi-circle-slice-4';
      case ConfidenceLevel.high:
        return 'mdi-circle-slice-6';
      case ConfidenceLevel.very_high:
        return 'mdi-circle-slice-8';
      default:
        return '';
    }
  }
}
