


































import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import authModule from '@/store/Auth';

import TermsOfUse from '@/components/common/TermsOfUse.vue';

@Component({
  components: {
    TermsOfUse,
  },
})
export default class TermsDialog extends Vue {
  agreed = false;

  get isLoggedIn() {
    return authModule.isLoggedIn;
  }

  get termsAgreed() {
    return authModule.termsAgreed;
  }

  logout() {
    authModule.logout();
  }

  async agreeToTerms() {
    await authModule.setTerms();
  }
}
