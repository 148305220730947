import { Model, Attr, HasOne, BelongsTo } from 'spraypaint';
import { FloraSurveyData, Media, User } from '@/api';
import ApplicationRecord from '../ApplicationRecord';
import SurveySite from './SurveySite';

@Model()
export default class FloraSurvey extends ApplicationRecord {
  static jsonapiType = 'flora-surveys';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Site',
        value: 'surveySite.name',
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return FloraSurvey.includes(['surveySite']);
  }

  // send multipart to "image"

  @Attr() createdAt: string;

  @Attr() timestamp: string;

  @Attr() readonly surveySiteName: string;

  @BelongsTo() surveySite: SurveySite;

  @HasOne() createdBy: User;

  @HasOne() verifiedBy: User;

  @HasOne() media: Media;

  @HasOne() floraSurveyData: FloraSurveyData;
}
