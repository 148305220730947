







































































import { Component, Vue } from 'vue-property-decorator';
import { FaunaLeaderboard } from '@/api';

import LineIndicator from '@/components/common/LineIndicator.vue';
import FaunaTagPreview from '@/components/common/FaunaTagPreview.vue';

import cacheModule from '@/store/Cache';
import snackModule from '@/store/Snack';

import faunaTagDialog from '@/fauna-tag-dialog';

import { numberFormat } from '@/util';

@Component({ components: { LineIndicator, FaunaTagPreview } })
export default class FaunaLeaderboardStatsLines extends Vue {
  fullList = false;

  limit = 5;

  dbItems: FaunaLeaderboard[] = [];

  get items() {
    return this.dbItems
      .filter(fs => fs.faunaTag && fs.faunaMediaTagCount)
      .map(fs => {
        const id = (fs.faunaTag as number).toString();
        const tag = cacheModule.faunaTagsById(id);
        return tag
          ? {
              name: tag.name,
              picture: tag.avatarUrl,
              value: fs.faunaMediaTagCount,
              tag,
            }
          : {
              name: 'Unknown',
              picture: '',
              value: fs.faunaMediaTagCount,
            };
      });
  }

  get title() {
    return 'Most tagged species';
  }

  get numberFormat() {
    return numberFormat;
  }

  get shownItems() {
    return this.fullList ? this.items : this.items.slice(0, this.limit);
  }

  get max() {
    return this.items.reduce((sum, item) => sum + item.value, 0);
  }

  showDialog(faunaTagId?: string) {
    faunaTagDialog(faunaTagId);
  }

  async mounted() {
    try {
      this.dbItems = (
        await FaunaLeaderboard.order({ faunaMediaTagCount: 'desc' }).all()
      ).data;
    } catch (e) {
      snackModule.setError({
        text: 'Failed to fetch public users',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }
}
