import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class Media extends ApplicationRecord {
  static jsonapiType = 'media';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'URL',
        value: 'url',
      },
    ];
  }

  static scopeFactory() {
    return Media.includes([]);
  }

  @Attr() url: string;

  @Attr() renditions: {
    thumbnail?: string; // 400 x 400
  };

  @Attr() createdAt: string;

  get thumbnail() {
    return this.renditions.thumbnail || this.url;
  }
}
