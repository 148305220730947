

















import { Component, Vue } from 'vue-property-decorator';
import HeroBanner from '@/components/common/HeroBanner.vue';
import EmptyItem from '@/components/common/EmptyItem.vue';

@Component({
  components: {
    HeroBanner,
    EmptyItem,
  },
})
export default class NotFound extends Vue {}
