import { Vue } from 'vue-property-decorator';

import vuetify from '@/plugins/vuetify';

import DeleteDialog from '@/components/common/DeleteDialog.vue';

const DeleteDialogClass = Vue.extend(DeleteDialog);

export default function deleteDialog(
  itemName: string,
  itemType: string,
  confirmCode: string,
): Promise<boolean> {
  return new Promise(resolve => {
    const dialog = new DeleteDialogClass({
      vuetify,
      propsData: {
        itemName,
        itemType,
        confirmCode,
      },
    });
    dialog.$on('close', () => {
      dialog.$destroy();
      resolve(false);
    });
    dialog.$on('confirm', (confirm: boolean) => {
      dialog.$destroy();
      resolve(confirm);
    });
    dialog.$mount();
  });
}
