




















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import TableMenuButton from '@/components/common/TableMenuButton.vue';
import GetStartedSite from '@/components/common/GetStartedSite.vue';
import FilterItem from '@/components/common/FilterItem.vue';
import SiteListItem from '@/components/property/SiteListItem.vue';
import FilterButton from '@/components/common/FilterButton.vue';
import SidebarTasks from '@/components/common/SidebarTasks.vue';
import SidebarTiles from '@/components/common/SidebarTiles.vue';

import { SurveyProperty, SurveySite } from '@/api';
import { debounce } from 'lodash';

import snackModule from '@/store/Snack';
import cacheModule from '@/store/Cache';
import propertyModule from '@/store/Property';
import deleteDialog from '@/delete-dialog';

@Component({
  components: {
    TableMenuButton,
    FilterItem,
    GetStartedSite,
    SiteListItem,
    FilterButton,
    SidebarTasks,
    SidebarTiles,
  },
})
export default class PropertySites extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  loading = false;

  firstLoad = true;

  total = 0;

  sites: SurveySite[] = [];

  itemsPerPage = 10;

  get showGetStarted() {
    return (
      !this.firstLoad &&
      !this.sites.length &&
      !Object.keys(this.$route.query).length
    );
  }

  get page() {
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, page: p.toString() },
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  get faunaTagItems() {
    return cacheModule.faunaTags.map(ft => ({
      label: ft.name,
      value: ft.id,
    }));
  }

  get filterItems() {
    return [
      {
        label: 'Includes',
        relationship: 'fauna_tag__in',
        queryParam: 'fauna-tag-in',
        items: this.faunaTagItems,
        multiple: true,
      },
      {
        label: 'Excludes',
        relationship: 'fauna_tag__exclude',
        queryParam: 'fauna-tag-exclude',
        items: this.faunaTagItems,
        multiple: true,
      },
    ];
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems.forEach(filterItem => {
      clause[filterItem.relationship] =
        this.$route.query[filterItem.queryParam] || undefined;
    });
    return clause;
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  async doUpdate() {
    this.loading = true;
    try {
      const result = await SurveySite.where({
        survey_property: this.property.id,
      })
        .where(this.whereClause)
        .page(this.page)
        .per(this.itemsPerPage)
        .order({ createdAt: 'desc' })
        .all();
      this.sites = result.data;
      this.total = result.meta.pagination.count;
    } catch (e) {
      this.sites = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.firstLoad = false;
      this.loading = false;
    }
  }

  async deleteItem(site: SurveySite) {
    const confirm = await deleteDialog(site.name, 'site', site.name);
    if (!confirm) {
      return;
    }
    try {
      const item = site.dup();
      await item.destroy();
      snackModule.setSuccess(`${item.name} deleted`);
      this.update();
      propertyModule.getProperty(this.property.id as string);
    } catch (e) {
      snackModule.setError({
        text: 'Could not delete',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  async mounted() {
    this.update();
  }

  @Watch('page')
  @Watch('whereClause')
  optionsChanged() {
    this.update();
  }
}
