






























import { Component, Vue, Prop } from 'vue-property-decorator';
import LineIndicator from '@/components/common/LineIndicator.vue';
import { FaunaStats, SurveyProperty } from '@/api';

import { numberFormat } from '@/util';

@Component({ components: { LineIndicator } })
export default class SiteStatsLines extends Vue {
  @Prop({ default: null }) readonly property: SurveyProperty;

  faunaStats: FaunaStats[] = [];

  fullList = false;

  siteName(id: string) {
    const surveySite = this.property.surveySites.find(site => site.id === id);
    return surveySite ? surveySite.name : '';
  }

  get sites() {
    return this.faunaStats
      .filter(fs => fs.count)
      .map(fs => ({
        name: this.siteName((fs.surveySite as number).toString()),
        value: fs.count,
      }));
  }

  get numberFormat() {
    return numberFormat;
  }

  get shownSites() {
    return this.fullList ? this.sites : this.sites.slice(0, 3);
  }

  get max() {
    return this.sites.reduce((sum, site) => sum + site.value, 0);
  }

  async mounted() {
    this.faunaStats = (
      await FaunaStats.where({
        survey_property__in: this.property.id,
        group_by: ['survey_site'],
      }).all()
    ).data;
  }
}
