










































import { Component, Vue, Prop } from 'vue-property-decorator';

import { SurveyProperty, User } from '@/api';

import cacheModule from '@/store/Cache';
import snackModule from '@/store/Snack';

import OutlineEditor from '@/components/admin/OutlineEditor.vue';

@Component({ components: { OutlineEditor } })
export default class UserPropertiesSingle extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ default: null }) readonly user: User | null;

  get focalLandscapes() {
    return cacheModule.focalLandscapes;
  }

  get faunaTags() {
    return cacheModule.faunaTags;
  }

  get outlineEditor() {
    return this.$refs.outlineEditor as OutlineEditor;
  }

  async save() {
    const multiPoly = this.outlineEditor.getMultiPoly();
    if (!multiPoly) {
      snackModule.setError({
        text: 'Must provide geometry',
        errors: [],
      });
      return;
    }
    this.property.geometry = multiPoly.geometry;

    if (!this.user) {
      return;
    }

    try {
      this.property.geometry = multiPoly.geometry;
      this.property.owner = this.user;
      await this.property.save({ with: ['focalLandscape.id', 'owner.id'] });
      snackModule.setSuccess('Item saved successfully');
    } catch (e) {
      snackModule.setError({
        text: 'Could not save item',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }
}
