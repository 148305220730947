

































import { Component, Vue, Watch } from 'vue-property-decorator';
import { SurveySite, SurveyProperty } from '@/api';
import PageHeader from '@/components/common/PageHeader.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import FilterItem from '@/components/common/FilterItem.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';
import snackModule from '@/store/Snack';
import { isEqual } from 'lodash';

@Component({
  components: { PageHeader, AbstractTable, TableMenuButton, FilterItem },
})
export default class AdminSitesList extends Vue {
  surveyProperties: Array<{ label: string; value: string }> = [];

  get headers() {
    return SurveySite.listHeaders();
  }

  get scopeFactory() {
    return () => SurveySite.scopeFactory().where(this.whereClause);
  }

  get filterItems() {
    return [
      {
        label: 'Survey Property',
        relationship: 'surveyProperty',
        queryParam: 'survey-property',
        items: this.surveyPropertyItems,
      },
    ];
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems.forEach(filterItem => {
      clause[filterItem.relationship] =
        this.$route.query[filterItem.queryParam] || undefined;
    });
    return clause;
  }

  get surveyPropertyItems() {
    return this.surveyProperties;
  }

  click(item: SurveySite) {
    this.$router.push({
      name: 'admin-sites-edit',
      params: { itemId: item.id as string },
    });
  }

  async getSurveyPropertyItems() {
    try {
      const data =
        (await SurveyProperty.select(['id', 'name']).all()).data || [];
      this.surveyProperties = data.map(sp => ({
        label: sp.name,
        value: sp.id,
      }));
    } catch (e) {
      snackModule.setError({
        text: 'Could not get survey properties',
        errors: e.resposne.errors,
      });
    }
  }

  @Watch('whereClause')
  whereClauseChanged(current: object, prev: object) {
    if (!isEqual(current, prev)) {
      (this.$refs.table as AbstractTable).update();
    }
  }

  mounted() {
    this.getSurveyPropertyItems();
  }
}
