var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.refreshStats),expression:"refreshStats",modifiers:{"once":true}}],staticClass:"site-list-item"},[_c('div',{staticClass:"site-detail"},[_c('div',{staticClass:"d-flex justify-space-between pb-4"},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.site.name))]),_c('div',{staticClass:"text--small secondary--text"},[_vm._v(" Survey Site • Established "+_vm._s(_vm.formattedCreatedAt)+" ")])]),(_vm.doShowOptions)?_c('table-menu-button',{attrs:{"icon":"mdi-dots-horizontal","tooltip":"More Actions","menuDense":true}},[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('deleteItem')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"red--text"},[_vm._v("Delete")])])],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"site-detail__grid"},[_c('site-thumb',{attrs:{"siteId":_vm.site.id,"disableUpload":!_vm.showOptions}}),(!_vm.siteSurveyCount)?_c('get-started-survey'):_vm._e(),(_vm.siteSurveyCount)?_c('stat-tile',{attrs:{"title":"Surveys","color":"blue","value":_vm.siteSurveyCount,"to":{
          name: 'property-surveys',
          params: { propertyId: _vm.site.surveyProperty.id },
          query: { 'survey-site': _vm.site.id },
        }}}):_vm._e(),(_vm.siteSurveyCount)?_c('stat-tile',{attrs:{"title":"Favourites","color":"red","value":_vm.siteFavouriteCount,"to":{
          name: 'property-favourites',
          params: { propertyId: _vm.site.surveyProperty.id },
          query: { 'survey-site': _vm.site.id },
        }}}):_vm._e(),(_vm.siteSurveyCount)?_c('stat-tile',{staticClass:"site-detail__tags",attrs:{"title":"Tags","color":"teal","value":_vm.siteNativeCount,"max":_vm.siteFaunaCount,"showMax":true,"to":{
          name: 'property-tags',
          params: { propertyId: _vm.site.surveyProperty.id },
          query: { 'survey-site': _vm.site.id },
        }}},[_vm._v(" "+_vm._s(_vm.siteNativeText)+" ")]):_vm._e()],1)]),_c('fauna-stats-lines',{staticClass:"mt-6",attrs:{"site":_vm.site}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }