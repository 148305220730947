import { Vue } from 'vue-property-decorator';

import vuetify from '@/plugins/vuetify';

import ShareDialog from '@/components/common/ShareDialog.vue';
import { FaunaMedia, UserFavourite } from '@/api';

const ShareDialogClass = Vue.extend(ShareDialog);

export default function shareDialog(
  faunaMedia: FaunaMedia,
  userFavourite: UserFavourite | null,
): Promise<void> {
  return new Promise(resolve => {
    const dialog = new ShareDialogClass({
      vuetify,
      propsData: {
        faunaMedia,
        userFavourite,
      },
    });
    dialog.$on('close', () => {
      dialog.$destroy();
      resolve();
    });
    dialog.$mount();
  });
}
