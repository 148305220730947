






























































import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { SurveyProperty, SurveySite } from '@/api';

import Stepper from '@/components/common/Stepper.vue';
import StepperItem from '@/components/common/StepperItem.vue';
import SitePreview from '@/components/property/SitePreview.vue';

import MapboxDraw, {
  DrawCreateEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';

import mapboxgl from 'mapbox-gl';
import { bbox, center, feature, Point, point } from '@turf/turf';
import { mapboxToken } from '@/config';

import snackModule from '@/store/Snack';

@Component({
  components: {
    Stepper,
    StepperItem,
    SitePreview,
  },
})
export default class SiteStep1 extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  map: mapboxgl.Map | null = null;

  draw: MapboxDraw | null = null;

  markers: mapboxgl.Marker[] = [];

  selectedSite: SurveySite | null = null;

  point: Point | null = null;

  lat = '';

  lng = '';

  siteName = '';

  unsavedChanges = false;

  get valid() {
    return this.point && this.siteName;
  }

  createMapElement() {
    const propertyCenter = center(this.property.geometry);
    const propertyBbox = bbox(this.property.geometry);
    this.map = new mapboxgl.Map({
      accessToken: mapboxToken,
      container: this.$refs.mapEl as HTMLElement,
      style: 'mapbox://styles/mapbox/light-v10',
      center: propertyCenter.geometry.coordinates as mapboxgl.LngLatLike,
      zoom: 15,
    });
    this.map.fitBounds(propertyBbox as mapboxgl.LngLatBoundsLike, {
      padding: 20,
    });
    this.map.on('click', () => {
      this.selectedSite = null;
      this.setActiveMarker();
    });
  }

  outlineMap() {
    if (!this.property || !this.map) return;
    (this.map as mapboxgl.Map).on('load', () => {
      // Add a data source containing GeoJSON data.
      (this.map as mapboxgl.Map).addSource('property', {
        type: 'geojson',
        data: feature(this.property.geometry),
      });

      // Add a new layer to visualize the polygon.
      (this.map as mapboxgl.Map).addLayer({
        id: 'fill',
        type: 'fill',
        source: 'property',
        layout: {},
        paint: {
          'fill-color': '#000000',
          'fill-opacity': 0.1,
        },
      });

      // Add a black outline around the polygon.
      (this.map as mapboxgl.Map).addLayer({
        id: 'outline',
        type: 'line',
        source: 'property',
        layout: {},
        paint: {
          'line-color': '#000',
          'line-dasharray': [2, 2],
          'line-width': 1,
        },
      });
    });
  }

  setSites() {
    if (!this.property || !this.map) return;
    const { map } = this;
    this.property.surveySites.forEach(site => {
      // Marker El
      const markerEl = document.createElement('div');
      markerEl.className = 'marker';

      // Marker
      const marker = new mapboxgl.Marker(markerEl)
        .setLngLat(site.location.coordinates as mapboxgl.LngLatLike)
        .addTo(map);

      // MarkerEl click
      markerEl.addEventListener('click', e => {
        e.stopPropagation();
        this.selectedSite = site;
        this.setActiveMarker(markerEl);
      });

      this.markers.push(marker);
    });
  }

  setupDraw() {
    if (!this.map) {
      return;
    }
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: true,
        trash: true,
      },
      styles: [
        {
          id: 'highlight-active-points',
          type: 'circle',
          filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['==', 'active', 'true'],
          ],
          paint: {
            'circle-radius': 9,
            'circle-color': '#ffaa00',
          },
        },
        {
          id: 'points-are-blue',
          type: 'circle',
          filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['==', 'active', 'false'],
          ],
          paint: {
            'circle-radius': 9,
            'circle-color': '#00aaff',
          },
        },
      ],
    });
    this.map.addControl(this.draw);
    this.map.on('draw.create', this.onCreateFeature);
    this.map.on('draw.update', this.onUpdateFeature);
    this.map.on('draw.delete', this.onDeleteFeature);
  }

  setActiveMarker(markerEl?: HTMLElement) {
    this.markers.forEach(marker => {
      const el = marker.getElement();
      if (el === markerEl) {
        if (this.map) {
          this.map.panTo(marker.getLngLat(), { offset: [0, 100] });

          new mapboxgl.Popup({
            closeButton: false,
            anchor: 'bottom',
            maxWidth: '300px',
          })
            .setLngLat(marker.getLngLat())
            .setDOMContent(this.$refs.popup as HTMLElement)
            .addTo(this.map);
        }
        el.classList.add('marker--selected');
      } else {
        el.classList.remove('marker--selected');
      }
    });
  }

  onCreateFeature(e: DrawCreateEvent) {
    if (!this.draw) {
      return;
    }
    this.draw.deleteAll();
    this.draw.add(e.features[0]);

    const [lng, lat] = (e.features[0].geometry as Point).coordinates;
    this.point = e.features[0].geometry as Point;
    this.lat = lat.toString();
    this.lng = lng.toString();
  }

  onUpdateFeature(e: DrawUpdateEvent) {
    const [lng, lat] = (e.features[0].geometry as Point).coordinates;
    this.point = e.features[0].geometry as Point;
    this.lat = lat.toString();
    this.lng = lng.toString();
  }

  onDeleteFeature() {
    this.point = null;
    this.lat = '';
    this.lng = '';
  }

  manuallySetFeature() {
    if (!this.draw) {
      return;
    }
    this.point = null;
    this.draw.deleteAll();
    try {
      const f = point([parseFloat(this.lng), parseFloat(this.lat)]);
      this.draw.add(f);
      this.point = f.geometry;
      if (this.map) {
        this.map.panTo([parseFloat(this.lng), parseFloat(this.lat)]);
      }
    } catch (e) {
      console.log(e);
      snackModule.setError({
        text: e as string,
        errors: [],
      });
    }
  }

  async createSite() {
    if (!this.valid) {
      return;
    }
    try {
      const site = new SurveySite({
        name: this.siteName,
        surveyProperty: this.property,
        location: this.point,
      });
      await site.save({ with: 'surveyProperty.id' });
      this.unsavedChanges = false;
      this.$emit('onCreate', site);
    } catch (e) {
      console.log(e);
      snackModule.setError({
        text: 'Could not create site',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  async mounted() {
    this.createMapElement();
    this.outlineMap();
    this.setSites();
    this.setupDraw();
  }

  beforeDestroy() {
    if (this.map) this.map.remove();
  }

  @Watch('siteName')
  @Watch('lat')
  @Watch('lng')
  @Watch('point')
  fieldsChanged() {
    this.unsavedChanges = true;
  }
}
