
































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import EmptyItem from '@/components/common/EmptyItem.vue';
import DatePicker from '@/components/common/DatePicker.vue';
import { FaunaSurvey, SurveySite, User } from '@/api';
import snackModule from '@/store/Snack';
import v8n from 'v8n';

@Component({ components: { PageHeader, EmptyItem, DatePicker } })
export default class AdminSurveysSingle extends Vue {
  /* Attributes */
  title: string;

  item: FaunaSurvey | null = null;

  faunaSurveyStatus = ['draft', 'published', 'in_progress', 'assessed'];

  surveySites: SurveySite[] = [];

  users: User[] = [];

  formattedStartDate: string | null;

  notFound = false;

  loading = false;

  valid = false;

  /* Form validations */
  requiredString = [
    (v: string) =>
      v8n()
        .string()
        .not.empty()
        .test(v) || this.$t('common.validation.generalRequired'),
  ];

  /*  Getter & setter */
  get itemId() {
    return this.$route.params.itemId;
  }

  get isNewItem() {
    return this.itemId === undefined;
  }

  async getSurveySites() {
    try {
      this.surveySites =
        (await SurveySite.select(['id', 'name']).all()).data || [];
    } catch (e) {
      snackModule.setError({
        text: this.$t('faunaSurveys.alerts.couldNotGetSites') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  async getUsers() {
    try {
      this.users = (await User.select(['id', 'email']).all()).data || [];
    } catch (e) {
      snackModule.setError({
        text: this.$t('propertyUsers.alerts.couldNotGetUsers') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  async save() {
    if (!this.item) return;
    try {
      this.loading = true;
      await this.item.save({
        with: ['surveySite.id', 'createdBy.id', 'assessedBy.id'],
      });
      this.$emit('updated', this.item);
      snackModule.setSuccess(this.$t('common.alerts.success') as string);
    } catch (e) {
      snackModule.setError({
        text: this.$t('faunaSurveys.alerts.notSaved') as string,
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('itemId', { immediate: true })
  async itemIdChanged() {
    if (this.isNewItem) {
      this.title = 'New Survey';
      this.item = new FaunaSurvey();
    } else {
      this.title = 'Edit Survey';
      try {
        this.item = (await FaunaSurvey.find(this.itemId)).data || null;
      } catch (e) {
        this.notFound = true;
      }
    }
  }

  mounted() {
    this.getSurveySites();
    this.getUsers();
  }
}
