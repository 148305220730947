

































import { Component, Prop, Vue } from 'vue-property-decorator';

import Cookies from 'js-cookie';

import VueDropzone from 'vue2-dropzone';
import { ApplicationRecord } from '@/api';

const previewTemplate = `
  <div class="dz-preview">
    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  </div>
`;

@Component({
  components: {
    VueDropzone,
  },
})
export default class extends Vue {
  @Prop({ required: true }) readonly item: ApplicationRecord;

  @Prop({ default: 'image' }) readonly uploadParam: string;

  @Prop({ default: 'avatar' }) readonly imageParam: string;

  @Prop({ default: 'avatarUrl' }) readonly previewParam: string;

  @Prop({ default: '128px' }) readonly size: string;

  @Prop() readonly label: string;

  get url() {
    return `${this.item.klass.url(this.item.id)}`;
  }

  get options() {
    const headers =
      process.env.VUE_APP_USE_COOKIES === 'true'
        ? {
            'Accept': 'application/vnd.api+json',
            'X-CSRFTOKEN': Cookies.get('csrftoken'),
          }
        : {
            'Accept': 'application/vnd.api+json',
            'Authorization': `Token ${Cookies.get('token')}`,
            'X-CSRFTOKEN': Cookies.get('csrftoken'),
          };

    return {
      url: this.url,
      previewTemplate,
      maxFiles: 1,
      resizeWidth: 400,
      resizeHeight: 400,
      resizeMethod: 'crop',
      paramName: ApplicationRecord.serializeKey(this.uploadParam),
      method: 'PATCH',
      headers,
    };
  }

  get style() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const url = (this.item as { [key: string]: any })[this.previewParam];
    return `background-image: url(${url}); width: ${this.size}; height: ${this.size}`;
  }

  get dropzone() {
    return (this.$refs.dropzone as unknown) as {
      removeFile: (file: unknown) => void;
    };
  }

  fileAdded() {
    this.$emit('fileAdded');
  }

  filesAdded() {
    this.$emit('filesAdded');
  }

  fileSending() {
    this.$emit('fileSending');
  }

  fileUploaded(file: unknown, response: string) {
    this.$emit('fileUploaded', { file, response });
    this.dropzone.removeFile(file);

    const { data } = JSON.parse(response as string);
    const newUrl =
      data.attributes[ApplicationRecord.serializeKey(this.imageParam)];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.item as { [key: string]: any })[this.imageParam] = newUrl;
  }

  fileRemoved() {
    this.$emit('fileRemoved');
  }

  queueComplete() {
    this.$emit('queueComplete');
  }
}
