



































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({ components: {} })
export default class FilterButton extends Vue {
  @Prop({ required: true }) readonly label: string;

  @Prop({ default: false }) readonly dark: string;

  @Prop({ required: true }) readonly paramName: string;

  @Prop({ required: true }) readonly items: Array<{
    label: string;
    value: string;
  }>;

  @Prop({ default: '' }) readonly tooltip: string;

  @Prop({ default: false }) readonly multiple: boolean;

  show = false;

  query: string[] | string = [];

  get count() {
    if (this.multiple) {
      return this.query.length;
    }
    return this.query ? 1 : 0;
  }

  get buttonColor() {
    if (this.dark) {
      return this.count
        ? 'grey darken-4 white--text'
        : 'grey darken-4 grey--text';
    }
    return this.count ? 'white primary--text' : 'white grey--text';
  }

  setRoute() {
    this.$router.replace({
      query: {
        ...this.$route.query,
        [this.paramName]: this.query || undefined,
        page: undefined,
      },
    });
  }

  setQuery() {
    const query = this.$route.query[this.paramName];

    if (!this.multiple) {
      if (Array.isArray(query)) {
        this.query = query[0] || '';
      } else {
        this.query = query || '';
      }
      return;
    }

    if (Array.isArray(query)) {
      this.query = query as string[];
    } else if (query) {
      this.query = [query];
    } else {
      this.query = [];
    }
  }

  clearQuery() {
    if (this.multiple) {
      this.query = [];
    } else {
      this.query = '';
    }
    this.setRoute();
  }

  @Watch('show', { immediate: true })
  showChanged() {
    this.setQuery();
  }
}
