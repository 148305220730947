import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class FavouriteStats extends ApplicationRecord {
  static jsonapiType = 'favourite-stats';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
    ];
  }

  static scopeFactory() {
    return FavouriteStats.includes([]);
  }

  @Attr() readonly surveySite: number | null;

  @Attr() readonly count: number;
}
