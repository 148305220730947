




















































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  SurveyProperty,
  FaunaMedia,
  FaunaMediaTag,
  FaunaTag,
  DiseaseStatus,
  FaunaSurveyStatus,
  FaunaSurvey,
  UserFavourite,
} from '@/api';

import FaunaMediaThumb from '@/components/common/FaunaMediaThumb.vue';
import FilterButton from '@/components/common/FilterButton.vue';
import GridResize from '@/components/classifier/GridResize.vue';
import ImageZoom from '@/components/classifier/ImageZoom.vue';

import authModule from '@/store/Auth';
import propertyModule from '@/store/Property';
import cacheModule from '@/store/Cache';
import snackModule from '@/store/Snack';

import confirmDialog from '@/confirm-dialog';
import { debounce, isEqual } from 'lodash';
import { ConfidenceLevel } from '@/api/models/FaunaMediaTag';

import { numberFormat } from '@/util';
import Cookies from 'js-cookie';

const debounceDelay = 300;

@Component({
  components: { FaunaMediaThumb, FilterButton, GridResize, ImageZoom },
})
export default class Classifier extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get tagById() {
    return cacheModule.faunaTagsById;
  }

  faunaMedia: FaunaMedia[] = [];

  faunaSurvey: FaunaSurvey | null = null;

  loading = false;

  total = 0;

  itemsPerPage = 30;

  selected = -1;

  activeItem: FaunaMedia | null = null;

  comment = '';

  inputFocused = false;

  windowWidth = 0;

  gridWidth = 0;

  gridVW: number | null = null;

  showFilters = false;

  smallThumbs = true;

  adminMode = false;

  get gridSize() {
    return this.smallThumbs ? 160 : 240;
  }

  get gridCols() {
    return Math.floor(this.gridWidth / this.gridSize);
  }

  get gridColStyle() {
    return `grid-template-columns: repeat(${this.gridCols}, 1fr);`;
  }

  get gridVWStyle() {
    return this.gridVW === null ? '' : `width:${this.gridVW}vw;`;
  }

  get userFavourite() {
    return (
      this.activeItem &&
      authModule.userFavouriteById(this.activeItem.id as string)
    );
  }

  get favouriteColor() {
    return this.userFavourite ? 'red white--text' : 'white';
  }

  get canEdit() {
    return (
      (this.adminMode && this.isAdmin) ||
      (this.faunaSurveyStatus === FaunaSurveyStatus.inProgress &&
        (this.isSurveyAssessor || this.isPropertyOwner))
    );
  }

  get faunaSurveyStatus() {
    return this.faunaSurvey
      ? this.faunaSurvey.status
      : FaunaSurveyStatus.unknown;
  }

  get faunaSurveyPrettyStatus() {
    return this.faunaSurvey ? this.faunaSurvey.prettyStatus : 'Unknown';
  }

  get isDraft() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.draft;
  }

  get isComplete() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.assessed;
  }

  get isPropertyOwner() {
    return authModule.user && authModule.user.id === this.property.owner.id;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get isSurveyCreator() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.createdBy &&
      authModule.user &&
      authModule.user.id === this.faunaSurvey.createdBy.id
    );
  }

  get isSurveyAssessor() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.assessedBy &&
      authModule.user &&
      authModule.user.id === this.faunaSurvey.assessedBy.id
    );
  }

  get canPublish() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.status === FaunaSurveyStatus.draft &&
      this.total > 0 &&
      (this.isSurveyCreator || this.isPropertyOwner || this.isAdmin)
    );
  }

  get canStartTagging() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.status === FaunaSurveyStatus.published &&
      authModule.user &&
      authModule.canAssessSurveys(this.property)
    );
  }

  get canComplete() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.status === FaunaSurveyStatus.inProgress &&
      (this.isPropertyOwner || this.isSurveyAssessor || this.isAdmin)
    );
  }

  get title() {
    if (!this.faunaSurvey) {
      return `${this.property.name}`;
    }
    return `${this.property.name} • ${this.faunaSurvey.surveySiteName} • ${this.faunaSurvey.formattedDate}`;
  }

  get selectedItem() {
    return this.faunaMedia[this.selected];
  }

  get faunaSurveyId() {
    return this.$route.params.faunaSurveyId;
  }

  get thumbs() {
    return this.$refs.thumbs as Vue[];
  }

  get containsId() {
    return (this.$route.query.id as string) || undefined;
  }

  set containsId(id: string | undefined) {
    this.$router.replace({
      query: { ...this.$route.query, id },
    });
  }

  get page() {
    if (this.containsId) {
      return -1;
    }
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, id: undefined, page: p.toString() },
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${numberFormat(
      1 + (this.page - 1) * this.itemsPerPage,
    )} - ${numberFormat(
      Math.min(this.page * this.itemsPerPage, this.total),
    )} of ${this.total}`;
  }

  get setActiveItem() {
    return debounce(this.doSetActiveItem, debounceDelay);
  }

  get update() {
    return debounce(this.doUpdate, debounceDelay);
  }

  get mediaFactory() {
    return () =>
      FaunaMedia.includes([
        'media',
        'faunaSurvey',
        'faunaMediaTags',
        'userFavourites',
      ]);
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems
      .filter(filterItem => !!filterItem.relationship)
      .forEach(filterItem => {
        clause[filterItem.relationship] =
          this.$route.query[filterItem.queryParam] || undefined;
      });

    return {
      fauna_survey: this.faunaSurveyId || undefined,
      survey_property: this.faunaSurveyId ? undefined : this.property.id,
      ...clause,
    };
  }

  get hasFilters() {
    return !!this.filterItems.filter(
      filterItem =>
        !!filterItem.relationship &&
        this.$route.query[filterItem.queryParam] !== undefined,
    ).length;
  }

  get surveySiteItems() {
    return this.property.surveySites.map(site => ({
      label: site.name,
      value: site.id,
    }));
  }

  get filterItems() {
    const siteFilter = {
      label: 'Site',
      relationship: 'surveySite',
      queryParam: 'survey-site',
      items: this.surveySiteItems,
      multiple: false,
    };

    const remainingFilters = [
      {
        label: 'Has Tags',
        relationship: 'has_tags',
        queryParam: 'has-tags',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Tagged by',
        relationship: 'tagged_by__in',
        queryParam: 'tagged-by',
        items: propertyModule
          .propertyUsers(this.property.id as string)
          .map(user => ({ label: user.name, value: user.id })),
        multiple: true,
      },
      {
        label: 'Has Favourites',
        relationship: 'has_favourites',
        queryParam: 'has-favourites',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Favourited by',
        relationship: 'favourited_by__in',
        queryParam: 'favourited-by',
        items: propertyModule
          .propertyUsers(this.property.id as string)
          .map(user => ({ label: user.name, value: user.id })),
        multiple: true,
      },
      {
        label: 'Has Comment',
        relationship: 'has_comment',
        queryParam: 'has-comment',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Includes',
        relationship: 'fauna_tag__in',
        queryParam: 'fauna-tag',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'Excludes',
        relationship: 'fauna_tag__exclude_in',
        queryParam: 'fauna-tag-exclude',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'No Animal',
        relationship: 'no_fauna',
        queryParam: 'no-animal',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
    ];

    return this.faunaSurveyId
      ? remainingFilters
      : [siteFilter, ...remainingFilters];
  }

  get faunaTagGroups() {
    return cacheModule.faunaTagGroups.map((group, i) => ({
      active: i === 0,
      group,
    }));
  }

  get faunaTagsByGroupId() {
    return cacheModule.faunaTagsByGroupId;
  }

  get adminModeTitle() {
    return this.adminMode ? 'Done' : 'Toggle Admin Mode';
  }

  get adminModeTooltip() {
    return this.adminMode
      ? "Hit done when you're finished making changes"
      : 'Toggle Admin Mode to make changes to a survey out of step';
  }

  toggleAdminMode() {
    this.adminMode = !this.adminMode;
  }

  scrollIntoView() {
    const thumb = this.thumbs[this.selected];
    thumb.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  doSetActiveItem() {
    this.activeItem = this.selectedItem;
    if (this.activeItem) {
      this.comment = this.activeItem.comment;
      this.scrollIntoView();
    }
  }

  async saveComment() {
    if (
      this.loading ||
      this.activeItem === null ||
      this.activeItem !== this.selectedItem
    ) {
      return;
    }

    try {
      this.loading = true;
      const { activeItem, comment } = this;
      activeItem.comment = comment;
      await activeItem.save();
      snackModule.setSuccess('Comment saved');
    } finally {
      this.loading = false;
    }
  }

  async doUpdate() {
    this.loading = true;
    this.selected = -1;
    this.faunaMedia = [];
    try {
      const result = await this.mediaFactory()
        .where(this.whereClause)
        .extraParams({
          page: {
            contains_id: this.containsId ? this.containsId : undefined,
            number: this.containsId ? undefined : this.page,
            size: this.itemsPerPage,
          },
        })
        .per(this.itemsPerPage)
        .order({ timestamp: 'asc' })
        .all();

      this.faunaMedia = result.data;
      this.total = result.meta.pagination.count;

      // select the fauna media with the correct id
      let index = -1;
      if (this.containsId) {
        index = this.faunaMedia.findIndex(fm => fm.id === this.containsId);
      }
      this.selected = index === -1 ? 0 : index;

      // set the correct page according to returned query
      if (this.page !== result.meta.pagination.page) {
        this.page = result.meta.pagination.page;
      }
    } catch (e) {
      this.faunaMedia = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async getFaunaSurvey() {
    if (this.faunaSurveyId) {
      this.faunaSurvey = (
        await FaunaSurvey.includes(['createdBy', 'assessedBy']).find(
          this.faunaSurveyId,
        )
      ).data;
    }
  }

  async confidenceLevelDialog() {
    return confirmDialog({
      title: 'Confidence Level',
      description: 'On a scale of 1 - 5, how confident are you?',
      block: true,
      buttons: [
        {
          key: ConfidenceLevel.very_low,
          title: '1',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.low,
          title: '2',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.medium,
          title: '3',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.high,
          title: '4',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.very_high,
          title: '5',
          color: 'grey',
          outlined: true,
        },
      ],
    });
  }

  async diseaseStatusDialog() {
    return confirmDialog({
      title: 'Disease Status',
      description:
        'Please indicate whether or not you can identify disease present for this tag',
      block: true,
      buttons: [
        {
          key: DiseaseStatus.diseased,
          title: 'Diseased',
          color: 'red white--text',
          outlined: false,
        },
        {
          key: DiseaseStatus.healthy,
          title: 'Healthy',
          color: 'green white--text',
          outlined: false,
        },
        {
          key: DiseaseStatus.unsure,
          title: 'Unsure',
          color: 'grey',
          outlined: true,
        },
      ],
    });
  }

  async addTag(tag: FaunaTag) {
    if (
      this.loading ||
      this.activeItem === null ||
      this.activeItem !== this.selectedItem
    ) {
      return;
    }

    if (this.activeItem.noFauna) {
      snackModule.setError({ text: 'No Animal already set', errors: [] });
      return;
    }

    const fmTag = new FaunaMediaTag({
      faunaMedia: this.activeItem,
      faunaTag: tag,
    });

    // ask for disease status if necessary
    if (tag.diseaseStatusRequired) {
      const diseaseStatus = await this.diseaseStatusDialog();
      if (diseaseStatus === null) {
        return;
      }
      fmTag.diseaseStatus = diseaseStatus as DiseaseStatus;
    }

    this.doAddTag(fmTag);
  }

  async doAddTag(fmTag: FaunaMediaTag) {
    try {
      const id = fmTag.faunaMedia.id as string;
      this.loading = true;
      await fmTag.save({ with: ['faunaMedia.id', 'faunaTag.id'] });
      await this.refreshFaunaMedia(id);
      snackModule.setSuccess(`${fmTag.faunaTag.name} added`);
    } finally {
      this.loading = false;
    }
  }

  async deleteTag(fmTag: FaunaMediaTag) {
    if (
      this.loading ||
      this.activeItem === null ||
      this.activeItem !== this.selectedItem
    ) {
      return;
    }

    try {
      const id = fmTag.faunaMedia.id as string;
      const tag = this.tagById(fmTag.faunaTag.id as string).name;
      this.loading = true;
      await fmTag.destroy();
      await this.refreshFaunaMedia(id);
      snackModule.setSuccess(`${tag} removed`);
    } finally {
      this.loading = false;
    }
  }

  async publish() {
    if (this.loading || !this.faunaSurvey || !authModule.user) {
      return;
    }
    const selection = await confirmDialog({
      title: 'Publish survey?',
      description:
        "Once published, the survey will become available for tagging. Please make sure you've uploaded all of your photos before continuing.",
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: 'Publish',
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      this.faunaSurvey.status = FaunaSurveyStatus.published;
      await this.faunaSurvey.save();
    } finally {
      this.loading = false;
    }
  }

  async startTagging() {
    if (this.loading || !this.faunaSurvey || !authModule.user) {
      return;
    }
    const selection = await confirmDialog({
      title: 'Start Tagging',
      description:
        'You will be able to stop and return to tagging images from this survey at your own leisure. Just be sure not to mark the survey as complete until all images are tagged!',
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: "Let's start",
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      this.faunaSurvey.status = FaunaSurveyStatus.inProgress;
      this.faunaSurvey.assessedBy = authModule.user;
      await this.faunaSurvey.save({ with: 'assessedBy.id' });
    } finally {
      this.loading = false;
    }
  }

  async setConfidenceLevel(fmTag: FaunaMediaTag) {
    const confidenceLevel = await this.confidenceLevelDialog();
    if (confidenceLevel === null) {
      return;
    }

    fmTag.confidenceLevel = confidenceLevel as ConfidenceLevel;
    try {
      this.loading = true;
      await fmTag.save();
      this.refreshFaunaMedia(fmTag.faunaMedia.id as string);
      snackModule.setSuccess('Confidence level updated');
    } catch (e) {
      snackModule.setError({
        text: 'Could not set confidence level',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async markAsComplete() {
    if (this.loading || !this.faunaSurvey) {
      return;
    }
    const selection = await confirmDialog({
      title: 'All done?',
      description:
        'Are you sure? By clicking confirm you will no longer be able to add or edit any tags in this survey.',
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: "I'm all done",
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      this.faunaSurvey.status = FaunaSurveyStatus.assessed;
      await this.faunaSurvey.save();
    } finally {
      this.loading = false;
    }
  }

  async refreshFaunaMedia(faunaMediaId: string) {
    try {
      const faunaMedia = (await this.mediaFactory().find(faunaMediaId)).data;
      const inx = this.faunaMedia.findIndex(item => item.id === faunaMediaId);
      if (inx !== -1) {
        this.faunaMedia.splice(inx, 1, faunaMedia);
      }
    } catch (e) {
      // not found
    }
  }

  handleGridResize({ left }: { left: number; delta: number }) {
    this.gridVW = 100 * (left / window.innerWidth);
    this.resize();
  }

  handleGridResizeEnd() {
    if (this.gridVW === null) {
      Cookies.remove('grid-vw');
    } else {
      Cookies.set('grid-vw', this.gridVW.toString());
    }
  }

  toggleSmallThumbs() {
    this.smallThumbs = !this.smallThumbs;
    Cookies.set('grid-small-thumbs', this.smallThumbs.toString());
  }

  resize() {
    this.windowWidth = window.innerWidth;
    this.gridWidth = (this.$refs.grid as HTMLElement).offsetWidth;
  }

  keydown(e: KeyboardEvent) {
    if (this.inputFocused) {
      return;
    }
    if (this.loading) {
      return;
    }

    // key down
    if (e.key === 'ArrowDown' || e.keyCode === 40) {
      e.preventDefault();
      this.selected = Math.min(
        this.selected + this.gridCols,
        this.faunaMedia.length - 1,
      );
      return;
    }

    // key up
    if (e.key === 'ArrowUp' || e.keyCode === 38) {
      e.preventDefault();
      this.selected = Math.max(this.selected - this.gridCols, 0);
      return;
    }

    // key down
    if (e.key === 'ArrowLeft' || e.keyCode === 37) {
      e.preventDefault();
      this.selected = Math.max(this.selected - 1, 0);
      return;
    }

    // key up
    if (e.key === 'ArrowRight' || e.keyCode === 39) {
      e.preventDefault();
      this.selected = Math.min(this.selected + 1, this.faunaMedia.length - 1);
      return;
    }

    if (!this.canEdit) {
      return;
    }

    if (e.key === 'n') {
      e.preventDefault();
      this.toggleNoAnimal();
      return;
    }

    const tag = cacheModule.faunaTags.find(item => item.keycode === e.key);
    if (tag) {
      e.preventDefault();
      this.addTag(tag);
    }

    // escape
    // if (e.key === 'Escape' || e.keyCode === 27) {
    //   e.preventDefault();
    //   this.close();
    // }
  }

  async toggleNoAnimal() {
    if (
      this.loading ||
      this.activeItem === null ||
      this.activeItem !== this.selectedItem
    ) {
      return;
    }

    try {
      this.loading = true;

      if (!this.activeItem.noFauna && this.activeItem.faunaMediaTags.length) {
        const selection = await confirmDialog({
          title: 'Remove existing tags',
          description:
            'This photo has already been tagged. Would you like them removed?',
          buttons: [
            {
              key: 'cancel',
              title: 'Cancel',
              color: 'grey',
              text: true,
            },
            {
              key: 'keep',
              title: 'Keep existing tags',
              color: 'primary',
              outlined: true,
            },
            {
              key: 'remove',
              title: 'Remove existing tags',
              color: 'primary',
              outlined: false,
            },
          ],
        });
        if (!selection || selection === 'cancel') {
          return;
        }
        if (selection === 'remove') {
          await Promise.all(
            this.activeItem.faunaMediaTags.map(fmTag => fmTag.destroy()),
          );
        }
      }

      this.activeItem.noFauna = !this.activeItem.noFauna;
      await this.activeItem.save();
      await this.refreshFaunaMedia(this.activeItem.id as string);
      snackModule.setSuccess(`No animal set to ${this.activeItem.noFauna}`);
    } finally {
      this.loading = false;
    }
  }

  async toggleFavourite() {
    if (!this.activeItem) {
      return;
    }
    if (this.userFavourite) {
      await this.userFavourite.destroy();
    } else {
      const newFavourite = new UserFavourite({
        user: authModule.user,
        faunaMedia: this.activeItem,
      });
      await newFavourite.save({ with: ['user.id', 'faunaMedia.id'] });
    }
    await authModule.getUserFavourites();
    this.refreshFaunaMedia(this.activeItem.id as string);
  }

  async mounted() {
    const vwCookie = Cookies.get('grid-vw');
    const smallThumbsCookie = Cookies.get('grid-small-thumbs');
    if (vwCookie) {
      this.gridVW = parseInt(vwCookie, 10);
    }
    this.smallThumbs = smallThumbsCookie === 'true';

    this.$nextTick(this.resize);
    await this.getFaunaSurvey();
    await this.update();
    this.showFilters = this.hasFilters;
  }

  created() {
    window.addEventListener('keydown', this.keydown);
    window.addEventListener('resize', this.resize);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.keydown);
    window.removeEventListener('resize', this.resize);
  }

  @Watch('page')
  pageChanged(newVal: number, oldVal: number) {
    if (oldVal !== -1) {
      this.update();
    }
  }

  @Watch('whereClause')
  whereChanged(
    newVal: { [key: string]: unknown },
    oldVal: { [key: string]: unknown },
  ) {
    if (!isEqual(newVal, oldVal)) {
      this.update();
    }
  }

  @Watch('selectedItem')
  selectedItemChanged() {
    this.setActiveItem();
  }
}
