import { Model, HasOne, Attr } from 'spraypaint';
import { User, SurveyProperty } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class PropertyUser extends ApplicationRecord {
  static jsonapiType = 'property-users';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'User',
        value: 'user.email',
      },
      {
        text: 'Can Assess',
        value: 'canAssessSurvey',
        align: 'center',
      },
      {
        text: 'Can Create',
        value: 'canCreateSurvey',
        align: 'center',
      },
    ];
  }

  static scopeFactory() {
    return PropertyUser.includes(['user', 'survey_property']);
  }

  // write to this
  @Attr() email: string;

  @Attr() canCreateSurvey: boolean;

  @Attr() canAssessSurvey: boolean;

  @HasOne() user: User;

  @HasOne() surveyProperty: SurveyProperty;
}
