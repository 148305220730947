




































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/common/PageHeader.vue';
import PropertyOutline from '@/components/property/PropertyOutline.vue';
import snackModule from '@/store/Snack';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import FaunaStatsLines from '@/components/property/FaunaStatsLines.vue';
import FaunaMediaThumb from '@/components/common/FaunaMediaThumb.vue';
import FaunaSurveyChip from '@/components/common/FaunaSurveyChip.vue';

import { FaunaSurvey, FaunaMedia, FaunaSurveyStatus } from '@/api';
import authModule from '@/store/Auth';

@Component({
  components: {
    PageHeader,
    PropertyOutline,
    TableMenuButton,
    FaunaStatsLines,
    FaunaMediaThumb,
    FaunaSurveyChip,
  },
})
export default class SurveyListItem extends Vue {
  @Prop({ required: true }) readonly survey: FaunaSurvey;

  faunaMediaList: FaunaMedia[] = [];

  count = 0;

  faunaMediaListReady = false;

  get linkLabel() {
    if (!this.faunaMediaListReady) {
      return '...';
    }

    return this.count > 0
      ? `${this.count} photos`
      : "You haven't uploaded any photos yet";
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get draft() {
    return this.survey.status === FaunaSurveyStatus.draft;
  }

  get listLength() {
    return Math.min(this.faunaMediaList.length, 3);
  }

  get classifierLink() {
    return this.draft
      ? {
          name: 'property-surveys-edit',
          params: { surveyId: this.survey.id },
        }
      : { name: 'classifier', params: { faunaSurveyId: this.survey.id } };
  }

  async getFaunaMedia() {
    try {
      const result = await FaunaMedia.includes([
        'media',
        'faunaMediaTags',
        'faunaSurvey',
      ])
        .per(3)
        .where({ faunaSurvey: this.survey.id })
        .order({ timestamp: 'asc' })
        .all();

      this.faunaMediaList = result.data;
      this.count = result.meta.pagination.count;
      this.faunaMediaListReady = true;
    } catch (e) {
      snackModule.setError({
        text: 'Failed to fetch fauna media',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }
}
