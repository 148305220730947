import { Model, Attr } from 'spraypaint';
import i18n from '@/plugins/vue-i18n';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class PublicUser extends ApplicationRecord {
  static jsonapiType = 'public-users';

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.id'),
        value: 'id',
        width: 70,
      },
      {
        text: i18n.t('common.labels.email'),
        value: 'email',
      },
      {
        text: i18n.t('users.labels.fullName'),
        value: 'name',
      },
    ];
  }

  static scopeFactory() {
    return PublicUser.includes([]);
  }

  @Attr() name: string;

  @Attr() isPublic: boolean;

  @Attr() avatar: string;

  @Attr() faunaMediaTagCount: number;

  get avatarUrl() {
    return this.avatar
      ? this.avatar
      : `https://api.dicebear.com/7.x/pixel-art/svg?seed=user${this.id}&scale=70`;
  }
}
