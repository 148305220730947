






















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { EditorContent, EditorMenuBar } from 'tiptap';

import TiptapBase from '@/components/tiptap/TiptapBase';

@Component({
  components: {
    EditorContent,
    EditorMenuBar,
  },
})
export default class TiptapEditor extends Vue {
  @Prop({ default: '' }) content: string;

  editor = TiptapBase();

  getHTML() {
    return this.editor.getHTML();
  }

  getJSON() {
    return this.editor.getJSON();
  }

  beforeDestroy() {
    this.editor.destroy();
  }

  @Watch('content', { immediate: true })
  contentChanged() {
    this.editor.setContent(this.content);
  }
}
