import { Vue } from 'vue-property-decorator';

import vuetify from '@/plugins/vuetify';

import FaunaTagDialog from '@/components/common/FaunaTagDialog.vue';

const FaunaTagDialogClass = Vue.extend(FaunaTagDialog);

export default function faunaTagDialog(
  faunaTagId?: string,
): Promise<string | null> {
  return new Promise(resolve => {
    const dialog = new FaunaTagDialogClass({
      vuetify,
      propsData: {
        faunaTagId,
      },
    });
    dialog.$on('close', () => {
      dialog.$destroy();
      resolve(null);
    });
    dialog.$mount();
  });
}
