













































import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserFavourite } from '@/api';

import PageHeader from '@/components/common/PageHeader.vue';
import NotFound from '@/components/common/NotFound.vue';

import cacheModule from '@/store/Cache';

@Component({
  components: { PageHeader, NotFound },
})
export default class ShareView extends Vue {
  userFavourite: UserFavourite | null = null;

  notFound = false;

  get uuid() {
    return this.$route.params.uuid;
  }

  get tagNameById() {
    return (id: string) => {
      const tag = cacheModule.faunaTagsById(id);
      return tag ? tag.name : '';
    };
  }

  setMeta() {
    const meta = [
      // essential
      {
        property: 'og:title',
        content: 'Wildtracker',
      },
      {
        property: 'og:type',
        content: 'article',
      },
      {
        property: 'og:image',
        content: this.userFavourite ? this.userFavourite.mediaUrl : '',
      },
      {
        property: 'og:url',
        content: '',
      },
      {
        name: 'twitter:card',
        content: '',
      },
      // non essential
      {
        property: 'og:description',
        content: '',
      },
      {
        property: 'og:site_name',
        content: '',
      },
      {
        name: 'twitter:image:alt',
        content: '',
      },
    ];
    meta.forEach(item => {
      const el = document.createElement('meta');
      Object.entries(item).forEach(entry => {
        el.setAttribute(entry[0], entry[1] as string);
      });
      document.head.appendChild(el);
    });
  }

  @Watch('userFavouriteId', { immediate: true })
  async userFavouriteIdChanged() {
    try {
      this.userFavourite = (
        await UserFavourite.includes([
          'user',
          'faunaMedia.faunaMediaTags',
        ]).find(this.uuid)
      ).data;
      this.setMeta();
    } catch (e) {
      this.notFound = true;
    }
  }
}
